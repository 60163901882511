import React, { useState, useReducer } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import Dashboard from '../../../../layouts/Dashboard';

import CustomBackdrop from '../../../../components/CustomBackdrop';
import { organisationReducer } from '../Common';
import Steps from '../Common/components/Steps';

import { createOrganisation } from './mutations';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

const CreateOrganisation = ({ classes, history }: Props): React.ReactElement => {
  const [organisation, dispatchOrganisation] = useReducer(organisationReducer, {
    name: '',
    postcode: '',
    first_assistance_label: '',
    second_assistance_label: '',
    trigger_emergency_label: '',
  });

  const [saving, setSaving] = useState<boolean>(false);

  const handleSave = async () => {
    setSaving(true);

    const insertOrganisationVariables = {
      object: {
        ...organisation,
        first_assistance_label: organisation.first_assistance_label ? organisation.first_assistance_label : 'Assistance Label 1',
        second_assistance_label: organisation.second_assistance_label ? organisation.second_assistance_label : 'Assistance Label 2',
        trigger_emergency_label: organisation.trigger_emergency_label ? organisation.trigger_emergency_label : 'Trigger Emergency',
      },
    };
    const organisation_id: string | undefined = await createOrganisation(insertOrganisationVariables);
    if (organisation_id) {
      setSaving(false);
      handleFinish(organisation_id);
    }
  };

  const handleFinish = (id: string) => {
    history.push(`/admin/trusts/${id}`);
  };

  return (
    <>
      <Dashboard breadcrumbs={[{ label: 'Trusts', link: '/admin/trusts' }, { label: 'Create' }]}>
        <Steps completeLabel="Create" organisation={organisation} dispatch={dispatchOrganisation} handleSave={handleSave} />
      </Dashboard>
      {saving && <CustomBackdrop label="Creating Organisation" />}
    </>
  );
};

export default withRouter(withStyles(styles)(CreateOrganisation));
