import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Dashboard from '../../../../../layouts/Dashboard';
import EditEmergency from '../../../../../components/Global/Emergencies/Edit';

import { editEmergencyInitQuery, Emergency } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  organisation_id: string;
  emergency_id: string;
}

const EditEmergencyAdmin = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id, emergency_id } = useParams<UrlParams>();

  const [emergency, setEmergency] = useState<Emergency>({
    name: '',
    organisation: {
      name: '',
    },
  });

  const { data: editEmergencyInitData } = useQuery(editEmergencyInitQuery(emergency_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editEmergencyInitData) {
      setEmergency(editEmergencyInitData.emergencies_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [editEmergencyInitData]);

  const handleFinish = () => {
    history.push(`/admin/trusts/${organisation_id}/emergencies/${emergency_id}`);
  };

  return (
    <>
      <Dashboard
        breadcrumbs={[
          { label: 'Trusts', link: '/admin/trusts' },
          { label: emergency.organisation.name, link: `/admin/trusts/${organisation_id}` },
          { label: 'Emergency', link: `/admin/trusts/${organisation_id}/emergencies` },
          { label: emergency.name },
          { label: 'Edit' },
        ]}>
        <EditEmergency organisation_id={organisation_id} emergency_id={emergency_id} handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(withStyles(styles)(EditEmergencyAdmin));
