import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    title: {
      fontSize: 24,
      fontWeight: 'bold',
      color: theme.palette.text.secondary,
    },
    completingTextContainer: {
      background: 'white',
      padding: '5px',
      borderRadius: '7px',
    },
  });

export default styles;
