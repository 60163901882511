/* eslint-disable camelcase */
import React, { useContext, useEffect } from 'react';
import { WithStyles, ClassNameMap } from '@mui/styles';
import { EmptyObject } from '../../react-app-env';
import { Sidebar, Topbar } from './components';
import { Breadcrum } from './components/Topbar';

import { Paper } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import {
  HomeOutlined as HomeIcon,
  Home as HomeActiveIcon,
  HomeWorkOutlined as OrganisationIcon,
  HomeWork as OrganisationActiveIcon,
  LocalHospitalOutlined as EmergencyIcon,
  LocalHospital as EmergencyActiveIcon,
  ViewCompactOutlined as AreaIcon,
  ViewCompact as AreaActiveIcon,
  AccountCircleOutlined as RoleIcon,
  AccountCircle as RoleActiveIcon,
  CategoryOutlined as CategoryIcon,
  Category as CategoryActiveIcon,
  GroupAddOutlined as UsersIcon,
  GroupAdd as UsersActiveIcon,
  SpaOutlined as EraCareIcon,
  Spa as EraCareActiveIcon,
} from '@mui/icons-material';

import { AuthContext } from '../../components/AuthProvider';
import { ListItemRoles } from './components/Sidebar/components/SidebarItemsList';

import styles from './styles';
import IdleTimer from './components/idle-tracker';
import { RouteComponentProps, withRouter } from 'react-router';

interface Props extends WithStyles<typeof styles>, React.PropsWithChildren<EmptyObject>, RouteComponentProps {
  classes: ClassNameMap<string>;
  title?: string;
  breadcrumbs?: Breadcrum[];
}

const listItems: ListItemRoles = {
  admin: {
    key: 'admin',
    title: 'Admin',
    items: [
      { label: 'Home', icon: <HomeIcon />, iconActive: <HomeActiveIcon />, to: '/admin/home' },
      { label: 'Trusts', icon: <OrganisationIcon />, iconActive: <OrganisationActiveIcon />, to: '/admin/trusts' },
      { label: 'Users', icon: <UsersIcon />, iconActive: <UsersActiveIcon />, to: '/admin/users' },
    ],
  },
  org: {
    key: 'org',
    title: 'Trust',
    items: [
      { label: 'Home', icon: <HomeIcon />, iconActive: <HomeActiveIcon />, to: '/trust/home' },
      { label: 'Departments', icon: <AreaIcon />, iconActive: <AreaActiveIcon />, to: '/trust/departments' },
      { label: 'Emergencies', icon: <EmergencyIcon />, iconActive: <EmergencyActiveIcon />, to: '/trust/emergencies' },
      { label: 'Roles', icon: <RoleIcon />, iconActive: <RoleActiveIcon />, to: '/trust/emergency_roles' },
      { label: 'Grouping', icon: <CategoryIcon />, iconActive: <CategoryActiveIcon />, to: '/trust/grouping' },
      { label: 'Users', icon: <UsersIcon />, iconActive: <UsersActiveIcon />, to: '/trust/users' },
      { label: 'ERA Care', icon: <EraCareIcon />, iconActive: <EraCareActiveIcon />, to: '/trust/care-questionnaires' },
    ],
  },
  area: {
    key: 'department',
    title: 'Department',
    items: [
      { label: 'Home', icon: <HomeIcon />, iconActive: <HomeActiveIcon />, to: '/department/home' },
      { label: 'Wards', icon: <AreaIcon />, iconActive: <AreaActiveIcon />, to: '/department/wards' },
      { label: 'Reports', icon: <EmergencyIcon />, iconActive: <EmergencyActiveIcon />, to: '/department/reports' },
      { label: 'ERA Care', icon: <EraCareIcon />, iconActive: <EraCareActiveIcon />, to: '/department/care-events' },
    ],
  },
};

const Dashboard = ({ classes, title, breadcrumbs, children, history }: Props): React.ReactElement => {
  const { user, userRole, signOut, loggedInUser } = useContext(AuthContext)!;

  // useEffect(() => {
  //   const forceSignOut = async () => {
  //     await signOut();
  //     history.replace('/sign-in');
  //   };

  //   let timer: IdleTimer | null = null;
  //   if (loggedInUser && userRole) {
  //     timer = new IdleTimer({
  //       timeout: 60 * 5,
  //       onTimeout: forceSignOut,
  //       onExpired: forceSignOut,
  //     });
  //   }

  //   timer?.tracker();

  //   return () => {
  //     timer?.cleanUp();
  //   };
  // }, [loggedInUser, signOut, userRole, history]);

  return (
    <>
      <div className={classes.root}>
        <Sidebar userRole={userRole as string} signOut={signOut} listItemRoles={listItems} />
        <Paper elevation={0} className={classes.main}>
          <Topbar title={title} user={{ ...user }} breadcrumbs={breadcrumbs} />
          <main className={classes.content}>
            <div className={classes.contentContainer}>{children}</div>
          </main>
        </Paper>
      </div>
    </>
  );
};

export default withRouter(withStyles(styles)(Dashboard));
