import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';

import ReadOnlyBlock from '../../../../../../components/ReadOnlyBlock';
import { Category } from '../..';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  category: Category;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const StepReview = ({ classes, category, setStep }: Props): React.ReactElement => {
  return (
    <>
      <ReadOnlyBlock title={category.name} items={[{ label: 'Description', value: category.description || 'No phone number' }]} handleEdit={() => setStep(0)} />
      <ReadOnlyBlock items={[{ label: 'Assigned departments', list: category.areas.map((a) => a.name) }]} handleEdit={() => setStep(1)} />
      <ReadOnlyBlock items={[{ label: 'Assigned emergencies', list: category.emergencies.map((a) => a.name) }]} handleEdit={() => setStep(2)} />
    </>
  );
};

export default withStyles(styles)(StepReview);
