import React, { useCallback } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import {
  TextField,
} from '@mui/material';

import { Category, ValidationErrors, CategoryReducerAction, validation } from '../..';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  category: Category,
  errors: ValidationErrors,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
  dispatch: React.Dispatch<{ type: CategoryReducerAction, value: any }>,
}

const StepDetails = ({ classes, category, errors, setErrors, dispatch }: Props): React.ReactElement => {
  const validate = useCallback((field: string) => {
    const errorItems: UnknownObject = {}
    if (field === 'name') {
      errorItems.name = validation.name(category.name);
    }
    if (field === 'description') {
      errorItems.description = validation.description(category.description);
    }
    setErrors({...errors, ...errorItems});
  }, [category, errors, setErrors]);

  return (
    <>
      <div className={classes.root}>
        <TextField
          className={classes.textField}
          data-qa="name-textfield"
          required
          label="Name"
          variant="outlined"
          error={errors.name as boolean}
          helperText={errors.name}
          value={category.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: CategoryReducerAction.NAME, value: event.target.value })}
          onKeyUp={() => validate('name')}
          onBlur={() => validate('name')}/>

        <TextField
          className={classes.textField}
          data-qa="description-textfield"
          required
          label="Description"
          variant="outlined"
          error={errors.description as boolean}
          helperText={errors.description}
          value={category.description}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: CategoryReducerAction.DESCRIPTION, value: event.target.value })}
          onKeyUp={() => validate('description')}
          onBlur={() => validate('description')}/>
          
      </div>
    </>
  );
};

export default withStyles(styles)(StepDetails);
