import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Dashboard from '../../../../layouts/Dashboard';
import EditSection from '../../../../components/Global/Areas/Sections/Edit';

import { editSectionInitQuery, Station } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  section_id: string;
}

const EditSectionArea = ({ classes, history }: Props): React.ReactElement => {
  const { section_id } = useParams<UrlParams>();

  const [section, setSection] = useState<Station>({
    name: '',
  });

  const { data: editSectionInitData } = useQuery(editSectionInitQuery(section_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editSectionInitData) {
      setSection(editSectionInitData.area_sections_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [editSectionInitData]);

  const handleFinish = () => {
    history.push(`/department/wards/${section_id}`);
  };

  return (
    <>
      <Dashboard breadcrumbs={[{ label: 'Wards', link: `/department/wards` }, { label: section.name, link: `/department/wards/${section_id}` }, { label: 'Edit' }]}>
        <EditSection section_id={section_id} handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(withStyles(styles)(EditSectionArea));
