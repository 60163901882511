import React from 'react';
import { EmptyObject } from '../../react-app-env';

import { WithStyles, withStyles } from '@mui/styles';

import { Breakpoint, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';

import styles from './styles';

export interface CustomDialogAction {
  label: string;
  onClick: () => void;
}

interface Props extends React.PropsWithChildren<EmptyObject>, WithStyles<typeof styles> {
  open: boolean;
  title: string;
  subtitle?: string;
  message?: string;
  component?: React.ReactElement;
  maxWidth?: Breakpoint;
  actions: CustomDialogAction[];
  handleClose: () => void;
  fullWidth?: boolean;
}

const CustomDialog = ({ classes, open, fullWidth = false, title, subtitle, message, component, maxWidth = 'md', actions, handleClose }: Props): React.ReactElement => (
  <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
    <DialogTitle id="alert-dialog-title" style={{ paddingBottom: subtitle ? 0 : undefined }}>
      {title}
    </DialogTitle>
    {subtitle && (
      <DialogContent id="alert-dialog-subtitle">
        <Typography variant="subtitle1">{subtitle}</Typography>
      </DialogContent>
    )}
    <DialogContent>
      {message && <DialogContentText>{message}</DialogContentText>}
      {component}
    </DialogContent>
    <DialogActions className={classes.actions}>
      {actions?.map((action: CustomDialogAction, index: number) => (
        <Button key={`dialog-action-${index}`} onClick={action.onClick} color="primary">
          {action.label}
        </Button>
      ))}
    </DialogActions>
  </Dialog>
);

export default withStyles(styles)(CustomDialog);
