
import { query, params, types, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../react-app-env';

const _sectionInitQuery = (id: string) => ({
  areas_by_pk: params(
    {
      id: rawString(id),
    },
    {
      name: types.string,
    },
  )
});

type X = Unpacked<typeof _sectionInitQuery>;
export type Area = X['areas_by_pk'];

export const sectionIitQuery = (id: string) => gql`${query(_sectionInitQuery(id))}`;
