import React, { useReducer } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import styles from './styles';
import { useMutation, useQuery } from '@apollo/client';
import { GetQuestionnaireResponse, getQuestionnaire } from './queries';
import Steps from '../Common/components/Steps';
import { QuestionnaireReducerAction, questionnaireReducer } from '../Common';
import CustomBackdrop from '../../../../components/CustomBackdrop';
import { updateQuestionnaireMutation } from './mutations';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  questionnaireId: string;
  handleFinish: () => void;
}

const EditEraCareQuestionnaireComponent = ({ questionnaireId, handleFinish, classes, history }: Props): React.ReactElement => {
  const [questionnaire, dispatchQuestionnaire] = useReducer(questionnaireReducer, {
    name: '',
    categories: [],
  });

  const [updateQuestionnaire, { loading: savingChanges }] = useMutation(updateQuestionnaireMutation, { onCompleted: handleFinish });

  const { loading: questionnaireLoading } = useQuery<GetQuestionnaireResponse>(getQuestionnaire, {
    variables: { id: questionnaireId },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      dispatchQuestionnaire({ type: QuestionnaireReducerAction.INIT, value: data.questionnaires_by_pk });
    },
  });

  const handleSave = async () => {
    await updateQuestionnaire({
      variables: {
        id: questionnaireId,
        set: {
          ...questionnaire,
        },
      },
    });
  };

  return (
    <>
      <Steps completeLabel="Save Changes" questionnaire={questionnaire} dispatch={dispatchQuestionnaire} handleSave={handleSave} />
      {(questionnaireLoading || savingChanges) && <CustomBackdrop label="Loading" />}
    </>
  );
};

export default withRouter(withStyles(styles)(EditEraCareQuestionnaireComponent));
