import React, { useCallback, useState } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import CustomStepper from '../../../../../../components/CustomStepper';
import { Category, validationErrors, ValidationErrors, CategoryReducerAction, validation, Area, Emergency } from '../..';
import StepDetails from '../StepDetails';
import StepAreas from '../StepAreas';
import StepEmergencies from '../StepEmergencies';
import StepReview from '../StepReview';

import styles from './styles';
import { CustomTransferListItem } from '../../../../../../components/CustomTransferList';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  completeLabel: string;
  category: Category;
  areas: Area[];
  emergencies: Emergency[];
  dispatch: React.Dispatch<{ type: CategoryReducerAction; value: any }>;
  handleSave: () => void;
}

const CategorySteps = ({ classes, completeLabel, category, areas, emergencies, dispatch, handleSave }: Props): React.ReactElement => {
  const [step, setStep] = useState<number>(0);

  const [errors, setErrors] = useState<ValidationErrors>({
    name: false,
    description: false,
  });

  const validate = useCallback(
    (_step: number) => {
      const errorItems: UnknownObject = {};
      if (_step === 0) {
        errorItems.name = validation.name(category.name);
        errorItems.description = validation.description(category.description);
      }
      setErrors({ ...errors, ...errorItems });
      return (Object.keys(errorItems) as Array<keyof typeof validationErrors>).some((i) => errorItems[i]);
    },
    [category, errors]
  );

  const handleAreaAssignment = (_: unknown, assigned: CustomTransferListItem[]) => {
    const assignedIds = assigned.map((i) => i.id);
    const a = areas.filter((i: Area) => assignedIds.includes(i.id));
    dispatch({ type: CategoryReducerAction.UPDATE_AREAS, value: a });
  };

  const handleEmergencyAssignment = (_: unknown, assigned: CustomTransferListItem[]) => {
    const assignedIds = assigned.map((i) => i.id);
    const a = emergencies.filter((i: Emergency) => assignedIds.includes(i.id));
    dispatch({ type: CategoryReducerAction.UPDATE_EMERGENCIES, value: a });
  };

  return (
    <>
      <CustomStepper
        step={step}
        isNextDisabled={(Object.keys(errors) as Array<keyof typeof errors>).some((i) => errors[i])}
        completeLabel={completeLabel}
        setStep={setStep}
        validate={validate}
        handleComplete={handleSave}>
        {[
          {
            label: 'Details',
            component: <StepDetails category={category} errors={errors} setErrors={setErrors} dispatch={dispatch} />,
          },
          {
            label: 'Department(s) assignment',
            gridSize: 8,
            component: (
              <StepAreas
                items={areas.map((area: Area) => ({ id: area.id, label: area.name }))}
                assigned={category.areas.map((area: Area) => ({ id: area.id, label: area.name }))}
                handleAssignment={handleAreaAssignment}
              />
            ),
          },
          {
            label: 'Emergency(s) assignment',
            gridSize: 8,
            component: (
              <StepEmergencies
                items={emergencies.map((c: Emergency) => ({ id: c.id, label: c.name }))}
                assigned={category.emergencies.map((c: Emergency) => ({ id: c.id, label: c.name }))}
                handleAssignment={handleEmergencyAssignment}
              />
            ),
          },
          {
            label: 'Summary',
            component: <StepReview category={category} setStep={setStep} />,
          },
        ]}
      </CustomStepper>
    </>
  );
};

export default withStyles(styles)(CategorySteps);
