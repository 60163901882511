import React from 'react';
import { WithStyles, ClassNameMap } from '@mui/styles';
import { stringFromArray } from '../../../../../../utils/stringUtils';

import withStyles from '@mui/styles/withStyles';

import ReadOnlyBlock from '../../../../../../components/ReadOnlyBlock';
import { AdminUser } from '../..';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  user: AdminUser,
  setStep: React.Dispatch<React.SetStateAction<number>>,
}

const StepReview = ({ classes, user, setStep }: Props): React.ReactElement => {
  return (
    <>
      <ReadOnlyBlock
        items={[
          { label: 'Name', value: stringFromArray([user.first_name, user.last_name]) },
          { label: 'Email', value: user.email },
        ]}
        handleEdit={() => setStep(0)} />
  </>
  );
};

export default withStyles(styles)(StepReview);
