import React, { FC } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { EmptyObject } from '../../../../../../react-app-env';

import styles from './styles';
import { WithStyles, withStyles } from '@mui/styles';

interface Props extends WithStyles<typeof styles>, React.PropsWithChildren<EmptyObject> {
  id: string;
}

const SortableItem: FC<Props> = ({ id, children }) => {
  const { listeners, attributes, setNodeRef, transform, transition, isDragging } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    with: '100%',
    transition,
    zIndex: isDragging ? '100' : '0',
    opacity: isDragging ? 0.3 : 1,
  };

  return (
    <div ref={setNodeRef} {...listeners} {...attributes} style={style}>
      {children}
    </div>
  );
};

export default withStyles(styles)(SortableItem);
