import React from 'react';
import { WithStyles, ClassNameMap } from '@mui/styles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { Typography, Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Divider, Toolbar, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { ExpandMoreOutlined as ExpandMoreIcon } from '@mui/icons-material';

import withStyles from '@mui/styles/withStyles';

import styles from './styles';
import { useQuery } from '@apollo/client';
import { GetEraCareResponseQueryResponse, GetQuestionnaireQueryResponse, getEraCareResponseQuery, getQuestionnaireQuery } from './queries';
import CustomBackdrop from '../../../../components/CustomBackdrop';
import { NeedHelpComponent, calculatePatientCategoryScore, rememberLowThreshHoldText, rememberMessageText } from './Common';
import anchorme from 'anchorme';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  organisation_id: string;
  era_care_event_id: string;
}

const EraCareResults = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id, era_care_event_id } = useParams<UrlParams>();

  const { data: questionnairesData, loading: questionnaireLoading } = useQuery<GetQuestionnaireQueryResponse>(getQuestionnaireQuery, {
    variables: { organisation_id },
  });

  const { data: responseData, loading: responseDataLoading } = useQuery<GetEraCareResponseQueryResponse>(getEraCareResponseQuery, {
    variables: { era_care_event_id: era_care_event_id },
    fetchPolicy: 'no-cache',
  });

  const questionnaire = questionnairesData?.questionnaires.length ? questionnairesData.questionnaires[0] : undefined;
  const patientResponse = responseData?.era_care_responses.length ? responseData.era_care_responses[0] : undefined;

  const patientCategoryMessages =
    questionnaire && patientResponse ? calculatePatientCategoryScore(patientResponse.response, questionnaire.categories).filter((item) => item.message) : [];

  return (
    <Grid container spacing={2} justifyContent="center" sx={{ padding: '8px' }}>
      <Grid item md={8} xs={12}>
        <Toolbar>
          <Typography className={classes.title} variant="h2" sx={{ textAlign: 'center', width: '100%' }}>
            {'Self-check in'}
          </Typography>
        </Toolbar>
      </Grid>
      <Grid item md={8} xs={12}>
        <Divider />
      </Grid>
      {questionnaire ? (
        <>
          <Grid item md={8} xs={12}>
            <Typography variant="subtitle1">Thank you for completing your self-check in.</Typography>
          </Grid>

          <Grid item md={8} xs={12}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="questionnaire-content" id="questionnaire-header">
                <Typography>View your responses</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item md={8} xs={12}>
                  <Typography textAlign="justify" variant="h6">
                    {questionnaire.name}
                  </Typography>
                </Grid>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={2}>
                    {questionnaire.categories.map((category) => (
                      <>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography textAlign="justify" variant="subtitle1">
                                {category.name}
                              </Typography>
                            </Grid>
                            {category.questions.map((question, questionIndex) => {
                              const answer = patientResponse?.response.categories.find((x) => x.name === category.name)?.questions.find((x) => x.name === question.name)?.answer;
                              const answerIndex = question.answers.findIndex((i) => i.name === answer?.name && i.score === answer.score);

                              return (
                                <Grid item xs={12}>
                                  <FormControl>
                                    <FormLabel id={`radio-buttons-group-label-${questionIndex}`}>{question.name}</FormLabel>
                                    <RadioGroup
                                      value={answerIndex}
                                      row
                                      aria-labelledby={`radio-buttons-group-label-${questionIndex}`}
                                      name={`radio-buttons-group-${questionIndex}`}>
                                      {question.answers.map((answer, answerIndex) => (
                                        <FormControlLabel disabled value={answerIndex} control={<Radio />} label={answer.name} />
                                      ))}
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      </>
                    ))}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>

          {patientCategoryMessages.length ? (
            <>
              <Grid item md={8} xs={12}>
                <Typography variant="subtitle1">Your responses suggest the following:</Typography>
              </Grid>
              {patientCategoryMessages.map((i) => (
                <>
                  <Grid item md={8} xs={12}>
                    <Typography variant="h6">{i.name}</Typography>
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <Typography variant="subtitle1">
                      <p dangerouslySetInnerHTML={{ __html: anchorme(i.message ?? '') }}></p>
                    </Typography>
                  </Grid>
                </>
              ))}
              {/* <Grid item md={8} xs={12}>
                <Divider />
              </Grid> */}
              <Grid item md={8} xs={12}>
                <Typography variant="subtitle1" align="justify" className={classes.completingTextContainer}>
                  We hope completing this self-check in has been useful. {rememberMessageText}
                  <br />
                </Typography>
              </Grid>
              <Grid item md={8} xs={12}>
                <NeedHelpComponent />
              </Grid>
            </>
          ) : (
            <Typography variant="subtitle1" align="justify">
              {rememberLowThreshHoldText} <NeedHelpComponent />
            </Typography>
          )}
        </>
      ) : (
        <Grid item md={8} xs={12}>
          <Typography variant="subtitle1">{'No questionnaire for selected trust. Sorry for inconveniences.'}</Typography>
        </Grid>
      )}
      {(questionnaireLoading || responseDataLoading) && <CustomBackdrop label="Loading" />}
    </Grid>
  );
};

export default withRouter(withStyles(styles)(EraCareResults));
