import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Dashboard from '../../../../layouts/Dashboard';

import CreateArea from '../../../../components/Global/Areas/Create';

import { areasInitQuery, Category } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

const CreateAreaOrg = ({ classes, history }: Props): React.ReactElement => {
  const [categories, setCategories] = useState<Category[]>([]);

  const { data: areasInitData } = useQuery(areasInitQuery(), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && areasInitData) {
      setCategories(areasInitData.categories);
    }
    return () => {
      mounted = false;
    };
  }, [areasInitData]);

  const handleFinish = (id: string) => {
    history.push(`/trust/departments/${id}`);
  };

  return (
    <>
      <Dashboard breadcrumbs={[{ label: 'Departments', link: `/trust/departments` }, { label: 'Create' }]}>
        <CreateArea categories={categories} handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(withStyles(styles)(CreateAreaOrg));
