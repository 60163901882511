
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _editEmergencyInitQuery = (id: string) => ({
  emergencies_by_pk: params(
    {
      id: rawString(id),
    },
    {
      name: types.string,
    },
  )
});

type X = Unpacked<typeof _editEmergencyInitQuery>;
export type Emergency = X['emergencies_by_pk'];

export const editEmergencyInitQuery = (id: string) => gql`${query(_editEmergencyInitQuery(id))}`;
