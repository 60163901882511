import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import {
  Grid,
} from '@mui/material';

import ReadOnlyBlock from '../../../../components/ReadOnlyBlock';

import { selectEmergencyRoleInitQuery, EmergencyRole } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
  emergency_role_id: string,
  handleEdit: () => void,
}

const ViewEmergencyRole = ({ classes, history, emergency_role_id, handleEdit }: Props): React.ReactElement => {

  const [emergency, setEmergency] = useState<EmergencyRole>({
    id: '',
    name: '',
    description: '',
    emergency_assignments: [],
  });

  const { data: emergencyRoleInitData } = useQuery(selectEmergencyRoleInitQuery(emergency_role_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && emergencyRoleInitData) {
      setEmergency(emergencyRoleInitData.emergencies_by_pk);
    }
    return () => { mounted = false; };
  }, [emergencyRoleInitData]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <>
          <ReadOnlyBlock
            title={emergency.name}
            gridSize={3}
            items={[
              { label: 'Description', value: emergency.description },
              { label: 'Emergencies', list: emergency.emergency_assignments.map((i) => i.emergency.name) },
            ]}
            handleEdit={handleEdit} />
        </>
      </Grid>
    </Grid>
  );
};

export default withRouter(withStyles(styles)(ViewEmergencyRole));
