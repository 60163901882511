import { Theme } from '@mui/system';

import { createStyles } from '@mui/styles';

const styles = ({palette}: Theme ) => createStyles({
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    fontFamily: 'Roboto',
    // backgroundColor: palette.background.paper,
  },
});


export default styles;
