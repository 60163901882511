import React, { useCallback, useState } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import CustomStepper from '../../../../../../components/CustomStepper';
import { CustomTransferListItem } from '../../../../../../components/CustomTransferList';
import { Emergency, validationErrors, ValidationErrors, EmergencyReducerAction, validation, Category, EmergencyRole } from '../..';
import StepDetails from '../StepDetails';
import StepCategories from '../StepCategories';
import StepRoles from '../StepRoles';
import StepRoleOrder from '../StepRoleOrder';
import StepReview from '../StepReview';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  completeLabel: string;
  emergency: Emergency;
  categories: Category[];
  roles: EmergencyRole[];
  dispatch: React.Dispatch<{ type: EmergencyReducerAction; value: any }>;
  handleSave: () => void;
}

const EmergencySteps = ({ classes, completeLabel, emergency, categories, roles, dispatch, handleSave }: Props): React.ReactElement => {
  const [step, setStep] = useState<number>(0);

  const [errors, setErrors] = useState<ValidationErrors>({
    name: false,
  });

  const validate = useCallback(
    (_step: number) => {
      const errorItems: UnknownObject = {};
      if (_step === 0) {
        errorItems.name = validation.name(emergency.name);
      }
      setErrors({ ...errors, ...errorItems });
      return (Object.keys(errorItems) as Array<keyof typeof validationErrors>).some((i) => errorItems[i]);
    },
    [emergency, errors]
  );

  const handleCategoryAssignment = (_: unknown, assigned: CustomTransferListItem[]) => {
    const assignedIds = assigned.map((i) => i.id);
    const a = categories.filter((i: Category) => assignedIds.includes(i.id));
    dispatch({ type: EmergencyReducerAction.UPDATE_CATEGORIES, value: a });
  };

  const handleRoleAssignment = (_: unknown, assigned: CustomTransferListItem[]) => {
    const assignedIds = assigned.map((i) => i.id);
    const a = roles.filter((i: Category) => assignedIds.includes(i.id));
    dispatch({ type: EmergencyReducerAction.UPDATE_ROLES, value: a });
  };

  return (
    <>
      <CustomStepper
        step={step}
        isNextDisabled={(Object.keys(errors) as Array<keyof typeof errors>).some((i) => errors[i])}
        completeLabel={completeLabel}
        setStep={setStep}
        validate={validate}
        handleComplete={handleSave}>
        {[
          {
            label: 'Details',
            component: <StepDetails emergency={emergency} errors={errors} setErrors={setErrors} dispatch={dispatch} />,
          },
          {
            label: 'Grouping assignment',
            gridSize: 8,
            component: (
              <StepCategories
                items={categories.map((c: Category) => ({ id: c.id, label: c.name }))}
                assigned={emergency.categories.map((c: Category) => ({ id: c.id, label: c.name }))}
                handleAssignment={handleCategoryAssignment}
              />
            ),
          },
          {
            label: 'Role assignment',
            gridSize: 8,
            component: (
              <StepRoles
                items={roles.map((c: EmergencyRole) => ({ id: c.id, label: c.name }))}
                assigned={emergency.roles.map((c: EmergencyRole) => ({ id: c.id, label: c.name }))}
                handleAssignment={handleRoleAssignment}
              />
            ),
          },
          {
            label: 'Roles ordering',
            gridSize: 8,
            component: <StepRoleOrder items={emergency.roles} dispatch={dispatch} />,
          },
          {
            label: 'Summary',
            component: <StepReview emergency={emergency} setStep={setStep} />,
          },
        ]}
      </CustomStepper>
    </>
  );
};

export default withStyles(styles)(EmergencySteps);
