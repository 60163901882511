import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { WithStyles, ClassNameMap } from '@mui/styles';

import { Button, Fade, Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import {
  BlockOutlined as ErrorIcon,
} from '@mui/icons-material';

import Centered from '../../../layouts/Centered';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps  {
  classes: ClassNameMap<string>,
}

const NotAuthorised = ({ classes, history }: Props): React.ReactElement => {
  const handleBack = () => {
    history.go(-1);
  }

  return (
    <Centered>
      <Fade in timeout={1700}>
        <div className={classes.root}>
          <div className={classes.container}>
            <ErrorIcon className={classes.icon} color="primary" />
            <div className={classes.text}>
              <Typography variant="h2" color="primary">401</Typography>
              <Typography variant="subtitle2" color="primary">page not authorised</Typography>
            </div>
          </div>
          <div className={classes.action}>
            <Button fullWidth variant="outlined" color="primary" onClick={handleBack}>go back</Button>
          </div>
        </div>
      </Fade>
    </Centered>
  );
};

export default withRouter(withStyles(styles)(NotAuthorised));
