import { capitaliseFirstOnly, numbersOnly } from '../../../../../../utils/stringUtils';
import { ValidationType } from '../../../../../../react-app-env';

export interface Station {
  name: string;
  phone: string;
}

export interface StationItem {
  id: string;
  name: string;
  phone: string;
  active: boolean;
}

export interface ValidationErrors {
  name: ValidationType;
}

export const validationErrors: ValidationErrors = {
  name: 'Please provide a name',
};

export interface Validation {
  name: (name: string) => ValidationType;
}

export const validation: Validation = {
  name: (name) => (!name ? validationErrors.name : false),
};

export enum StationReducerAction {
  NAME,
  PHONE,
  INIT,
}

export const stationReducer = (state: Station, action: { type: StationReducerAction; value: any }): Station => {
  switch (action.type) {
    case StationReducerAction.NAME:
      return { ...state, name: capitaliseFirstOnly(action.value) };
    case StationReducerAction.PHONE:
      return { ...state, phone: numbersOnly(`${action.value}`) };
    case StationReducerAction.INIT:
      const { name, phone } = action.value;
      const obj = {
        name,
        phone,
      };
      return { ...(obj as Station) };
    default:
      throw new Error();
  }
};
