import { gql } from '@apollo/client';
import { Questionnaire } from '../Common';

export const getQuestionnaires = gql`
  query ($organisation_id: uuid!) {
    questionnaires(where: { organisation_id: { _eq: $organisation_id } }) {
      id
      name
      categories
    }
  }
`;

export interface GetQuestionnairesResponse {
  questionnaires: Questionnaire[];
}
