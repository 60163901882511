
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _editCategoryInitQuery = (id: string) => ({
  categories_by_pk: params(
    {
      id: rawString(id),
    },
    {
      name: types.string,
    },
  )
});

type X = Unpacked<typeof _editCategoryInitQuery>;
export type Category = X['categories_by_pk'];

export const editCategoryInitQuery = (id: string) => gql`${query(_editCategoryInitQuery(id))}`;
