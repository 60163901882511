import React, { useReducer } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Dashboard from '../../../../layouts/Dashboard';
import styles from './styles';
import { eraCareEventReducer } from '../Common';
import Steps from '../Common/components/Steps';
import CustomBackdrop from '../../../../components/CustomBackdrop';
import { useMutation } from '@apollo/client';
import { insertEraCareEventMutation } from './mutations';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

const CreateEraCareEvents = ({ classes, history }: Props): React.ReactElement => {
  const [eraCareEvent, dispatchEraCareEvent] = useReducer(eraCareEventReducer, {
    created_at: '',
    patient_first_name: '',
    patient_last_name: '',
    patient_mobile_number: null,
    patient_email: null,
    is_linked_to_emergency: false,
    is_tracked_by_era: false,
    patient_gives_consent_to_contact: false,
  });

  const handleFinish = () => {
    history.push('/department/care-events');
  };

  const [insertEraCareEvent, { loading: saving }] = useMutation(insertEraCareEventMutation, { onCompleted: handleFinish });

  const handleSave = async () => {
    await insertEraCareEvent({
      variables: {
        object: {
          ...eraCareEvent,
        },
      },
    });
  };

  return (
    <Dashboard breadcrumbs={[{ label: 'ERA Care Events', link: '/department/care-events' }, { label: 'Create' }]}>
      <Steps completeLabel="Create" eraCareEvent={eraCareEvent} dispatch={dispatchEraCareEvent} handleSave={handleSave} />
      {saving && <CustomBackdrop label="Loading" />}
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(CreateEraCareEvents));
