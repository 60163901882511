import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { Grid } from '@mui/material';

import ReadOnlyBlock from '../../../../components/ReadOnlyBlock';
import { selectCategoryInitQuery, Category } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  category_id: string;
  handleEdit: () => void;
}

const ViewCategory = ({ classes, history, category_id, handleEdit }: Props): React.ReactElement => {
  const [category, setCategory] = useState<Category>({
    id: '',
    name: '',
    description: '',
    emergency_assignments: [],
    area_assignments: [],
  });

  const { data: areaInitData } = useQuery(selectCategoryInitQuery(category_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && areaInitData) {
      setCategory(areaInitData.area_sections_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [areaInitData]);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <>
            <ReadOnlyBlock
              title={category.name}
              gridSize={3}
              items={[
                { label: 'Description', value: category.description },
                { label: 'Emergencies', list: category.emergency_assignments.map((i) => i.emergency.name) },
                { label: 'Departments', list: category.area_assignments.map((i) => i.area.name) },
              ]}
              handleEdit={handleEdit}
            />
          </>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(withStyles(styles)(ViewCategory));
