import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Grid } from '@mui/material';

import CustomSortableTable, { SortableTableHeader, SortableTableRow } from '../../../../components/CustomSortableTable';

import { Event, EventRole } from '../List';

import styles from './styles';
import { dateDiffFromString, formatDate } from '../../../../utils/dateUtils';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  events: Event[];
  loading: boolean;
}

const ReportEvents = ({ classes, history, events, loading = false }: Props): React.ReactElement => {
  const headers: SortableTableHeader[] = [
    { key: 'created_at', label: 'Created at' },
    { key: 'name', label: 'Name' },
    { key: 'capture_name', label: 'Patient name' },
    { key: 'department', label: 'Department' },
    { key: 'section', label: 'Ward' },
    { key: 'station', label: 'Station' },
  ];

  const rows: SortableTableRow[] = events.map((item: Event) => ({
    key: item.id,
    columns: [
      {
        key: 'created_at',
        label: formatDate(new Date(item.created_at), 'yyyy-MM-DD hh:mm:ss'),
      },
      {
        key: 'name',
        label: item.emergency.name,
      },
      {
        key: 'capture_name',
        label: item.emergency.capture_name ?? '---',
      },
      {
        key: 'department',
        label: item.area.name,
      },
      {
        key: 'section',
        label: item.section.name,
      },
      {
        key: 'station',
        label: item.station.name,
      },
    ],
    collapsible: [
      {
        title: 'Roles',
        counter: item.roles.length,
        headers: [
          { key: 'name', label: 'Name' },
          { key: 'response_time', label: 'Response time' },
          // { key: 'assigned_time', label: 'Assigned time' },
          // { key: 'completed_time', label: 'Completed time' },
        ],
        rows: item.roles.map((i: EventRole) => ({
          key: i.id,
          columns: [
            { key: 'name', label: i.name },
            { key: 'response_time', label: `${Math.round(dateDiffFromString(i.updated_at, i.created_at))} seconds` },
            // { key: 'assigned_time', label: i.assigned_at ? `${Math.round(dateDiffFromString(i.assigned_at, i.created_at))} seconds` : 'NA' },
            // { key: 'completed_time', label: `${Math.round(dateDiffFromString(i.updated_at, i.created_at))} seconds` },
          ],
        })),
      },
    ].filter((i) => i.rows.length > 0),
  }));

  return (
    <Grid container spacing={4} alignItems="stretch">
      <Grid item xs={12}>
        <CustomSortableTable
          title="Events"
          orderdBy="created_at"
          ordered="asc"
          headers={headers}
          counter={rows.length}
          rows={rows}
          exportRows={{
            exportButtonLabel: 'Export events',
            exportDocumentLabel: 'events_export',
            columnKeys: ['created_at', 'name', 'capture_name', 'trigger_emergency_label', 'department', 'section', 'station'],
          }}
        />
      </Grid>
    </Grid>
  );
};

export default withRouter(withStyles(styles)(ReportEvents));
