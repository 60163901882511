import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) => createStyles({
  root: {},
  fill: {
    height: '100%',
  },
});

export default styles;
