import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    '&:hover $expandDivider': {
      backgroundColor: theme.palette.primary.light,
      opacity: 1,
    },
    '&:hover $expandToggle': {
      opacity: 1,
    },
  },
  listContainer: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 2,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  containerExpand: {
    width: 200,
  },
  containerCollapse: {
    width: 56,
  },
  expandContainer: {
    margin: theme.spacing(2),
    marginLeft: 0,
    zIndex: 1,
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    cursor: 'pointer',
  },
  expandDivider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  expandToggle: {
    position: 'absolute',
    cursor: 'pointer',
    opacity: 0,
    width: 24,
    height: 24,
    marginTop: theme.spacing(4) + 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 24,
    backgroundColor: theme.palette.background.paper,
    '& > svg': {
      width: 24,
      height: 24,
      color: theme.palette.primary.main,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      '& > svg': {
        color: theme.palette.background.paper,
      },
    },
  },
  listDivider: {
    margin: theme.spacing(1),
  },
});

export default styles;
