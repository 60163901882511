import Amplify, { Auth } from 'aws-amplify';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { CustomError, CognitoUser, AdminUser, OrgUser, AreaUser } from '../../react-app-env';

import useLocalStorage from '../../hooks/useLocalStorage';
import { getUserRoleQuery, getAdminUserQuery, getOrganisationUserQuery } from './queries';

Amplify.configure({
  aws_cognito_region: 'eu-west-2',
  aws_user_pools_id: process.env.REACT_APP_COGNITO_USER_POOL,
  aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_CLIENT,
});

export enum EnumUserRole {
  ADMIN = 'admin',
  ORG = 'org',
  AREA = 'area',
}

export const adminRoles = [EnumUserRole.ADMIN];
export const orgRoles = [EnumUserRole.ORG];
export const areaRoles = [EnumUserRole.AREA];

interface AuthContextItems {
  Auth: typeof Auth;
  userId: string | null;
  user: AdminUser | OrgUser | AreaUser | null;
  loggedInUser: CognitoUser | null;
  userRole: EnumUserRole | null;
  signIn: (username: string, password: string, ignoreSet?: boolean) => Promise<CognitoUser | CustomError | any>;
  signOut: () => Promise<void>;
  forgotPassword: (email: string) => Promise<void | CustomError>;
  confirmSignUp: (username: string, code: string) => Promise<true | CustomError>;
  setLoggedInUser: React.Dispatch<React.SetStateAction<CognitoUser | null>>;
  resendSignUp: (username: string) => Promise<true | CustomError>;
  completeNewPassword: (password: string, attributes: { given_name: string; family_name: string }) => Promise<true | CustomError>;
}

export const AuthContext = React.createContext<AuthContextItems | null>(null);

type Props = { children: React.ReactNode };

const AuthProvider = ({ children }: Props): React.ReactElement => {
  const [subId, setSubId] = useState<string | null>(null);
  const [user, setUser] = useState<AdminUser | null>(null);
  const [userRole, setUserRole] = useState<EnumUserRole | null>(null);
  const [loggedInUser, setLoggedInUser] = useState<CognitoUser | null>(null);
  const [, setToken, removeToken] = useLocalStorage('token', null);

  const setSentryUser = (userResponse: { id: string; email: string }) => {
    Sentry.setUser({
      id: userResponse.id,
      email: userResponse.email,
    });
  };

  const getUser = useCallback(async () => {
    if (loggedInUser && loggedInUser.attributes && subId && userRole) {
      const role: EnumUserRole = userRole;
      if (role === EnumUserRole.ADMIN) {
        const user = await getAdminUserQuery(subId);
        if (user) {
          setUser({
            ...user,
            user_type: 'Admin',
          } as AdminUser);

          setSentryUser(user);
        }
      }
      if (role === EnumUserRole.ORG) {
        const user = await getOrganisationUserQuery(subId);
        if (user) {
          setUser({
            ...user,
            user_type: 'Trust admin',
          } as OrgUser);

          setSentryUser(user);
        }
      }
      if (role === EnumUserRole.AREA) {
        const user = await getOrganisationUserQuery(subId);
        if (user) {
          setUser({
            ...user,
            user_type: 'Department admin',
          } as AreaUser);

          setSentryUser(user);
        }
      }
    }
  }, [loggedInUser, subId, userRole, setUser]);

  useEffect(() => {
    let mounted = true;
    if (mounted && loggedInUser && subId && userRole) {
      getUser();
    }
    return () => {
      mounted = false;
    };
  }, [loggedInUser, subId, userRole, getUser]);

  const fetchRoles = useCallback(async () => {
    try {
      if (subId) {
        const res = await getUserRoleQuery(subId);
        setUserRole(res.data.user_roles[0]?.role as EnumUserRole);
      }
    } catch (err: any) {
      if (err.message === 'Authentication hook unauthorized this request') {
        return;
      }
      console.error(err);
    }
  }, [subId, setUserRole]);

  useEffect(() => {
    let mounted = true;
    if (mounted && loggedInUser && loggedInUser.attributes && subId) {
      fetchRoles();
    } else {
      setUserRole(null);
    }
    return () => {
      mounted = false;
    };
  }, [loggedInUser, subId, fetchRoles]);

  useEffect(() => {
    let mounted = true;
    if (mounted && !subId) {
      Auth.currentAuthenticatedUser()
        .then(async (user) => {
          setSubId(user.attributes.sub);
          setLoggedInUser(user);
          setToken(user.signInUserSession.idToken.jwtToken);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    return () => {
      mounted = false;
    };
  }, [subId, setToken]);

  const authContext: AuthContextItems = {
    Auth,
    user,
    userId: subId,
    userRole,
    loggedInUser,
    setLoggedInUser,
    forgotPassword: async (email) => Auth.forgotPassword(email).catch((error) => ({ error: true, ...error })),
    signIn: async (emailAddress: string, password: string) => {
      localStorage.removeItem('_expiredTime');
      const cognitoUser = await Auth.signIn(emailAddress.toLowerCase(), password);
      if (cognitoUser.attributes) {
        setSubId(cognitoUser.attributes.sub);
        setLoggedInUser(cognitoUser);
        if (cognitoUser.challengeName !== 'NEW_PASSWORD_REQUIRED') {
          setToken(cognitoUser.signInUserSession.accessToken.jwtToken);
        }
      }
      return cognitoUser;
    },
    signOut: async () => {
      setToken(null);
      removeToken();
      await Auth.signOut();
      setLoggedInUser(null);
    },
    completeNewPassword: async (password, attributes) => {
      try {
        await Auth.completeNewPassword(loggedInUser, password, attributes);
        return true;
      } catch (error: any) {
        return error;
      }
    },
    confirmSignUp: async (username: string, code: string) => {
      try {
        await Auth.confirmSignUp(username.toLowerCase(), code);
        return true;
      } catch (error: any) {
        return error;
      }
    },
    resendSignUp: async (username: string) => {
      try {
        await Auth.resendSignUp(username);
        return true;
      } catch (error: any) {
        return error;
      }
    },
  };
  return <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export const useAuthProvider = () => {
  const authContext = useContext(AuthContext);
  if (authContext === null) {
    throw new Error('No AuthContext');
  }

  const { user, userRole } = authContext;

  let organisationId = userRole === EnumUserRole.ADMIN ? undefined : (user as OrgUser).organisation_id;
  let areaId = userRole === EnumUserRole.AREA ? (user as AreaUser).area_id : undefined;

  return {
    ...authContext,
    organisationId,
    areaId,
  };
};
