
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _editEmergencyRoleInitQuery = (id: string) => ({
  emergency_roles_by_pk: params(
    {
      id: rawString(id),
    },
    {
      name: types.string,
      description: types.string,
      emergency_assignments: [{
        id: types.string,
        emergency: {
          id: types.string,
          name: types.string,
        },
      }],
      organisation: {
        emergencies: [{
          id: types.string,
          name: types.string,
        }],
      },
    },
  )
});

type X = Unpacked<typeof _editEmergencyRoleInitQuery>;
export type Organisation = X['emergency_roles_by_pk']['organisation'];

export const editEmergencyRoleInitQuery = (id: string) => gql`${query(_editEmergencyRoleInitQuery(id))}`;
