import React, { useCallback, useState } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { UnknownObject } from '../../../../../../../../react-app-env';

import {
} from '@mui/material';

import CustomStepper from '../../../../../../../../components/CustomStepper';
import { Station, validationErrors, ValidationErrors, StationReducerAction, validation } from '../..';
import StepDetails from '../StepDetails';
import StepReview from '../StepReview';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  completeLabel: string,
  station: Station,
  dispatch: React.Dispatch<{ type: StationReducerAction, value: any }>,
  handleSave: () => void,
}

const OrganisationSteps = ({ classes, completeLabel, station, dispatch, handleSave }: Props): React.ReactElement => {
  const [step, setStep] = useState<number>(0);

  const [errors, setErrors] = useState<ValidationErrors>({
    name: false,
  });

  const validate = useCallback((_step: number) => {
    const errorItems: UnknownObject = {}
    if (_step === 0) {
      errorItems.name = validation.name(station.name);
    }
    setErrors({...errors, ...errorItems});
    return (Object.keys(errorItems) as Array<keyof typeof validationErrors>).some((i) => errorItems[i]);
  }, [station, errors]);

  return (
    <>
      <CustomStepper
        step={step}
        isNextDisabled={(Object.keys(errors) as Array<keyof typeof errors>).some((i) => errors[i])}
        completeLabel={completeLabel}
        setStep={setStep}
        validate={validate}
        handleComplete={handleSave} >
        {[
          {
            label: 'Details',
            component:
              <StepDetails
                station={station}
                errors={errors}
                setErrors={setErrors}
                dispatch={dispatch} />
          },
          {
            label: 'Summary',
            component:
              <StepReview
                station={station}
                setStep={setStep} />
            },
          ]}
        </CustomStepper>
    </>
  );
};

export default withStyles(styles)(OrganisationSteps);
