import React, { useState, useEffect, useCallback } from 'react';
import { WithStyles, ClassNameMap } from '@mui/styles';

import withStyles from '@mui/styles/withStyles';

import { exactDateString, getDaysPast, getMonthsPast, getStartOfWeek } from '../../../../utils/dateUtils';

import { useQuery } from '@apollo/client';

import { query, params, types, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

import { Badge, Button, Divider, Drawer, Grid, List, ListItem, ListItemText } from '@mui/material';

import Metrics from '../Metrics/metrics';

import styles from './styles';
import { ToggleButtonGroup, ToggleButton } from '@mui/lab';
import CustomDatePicker from '../../../../components/CustomDatePicker';
import ReportEvents from '../ReportEvents';
import FiltersPane, { FilterOptions, Filters } from './FiltersPane';

const _query = (startDate: string, endDate: string, filters: Filters, organisationId?: string) => ({
  events: params(
    {
      where: {
        closed: { _eq: true },
        _and: [
          { created_at: { _gte: rawString(startDate) } },
          { created_at: { _lte: rawString(endDate) } },
          ...(organisationId
            ? [
                {
                  organisation_id: { _eq: rawString(organisationId) },
                },
              ]
            : []),
          ...(filters.areas.length
            ? [
                {
                  _or: filters.areas.map((item) => ({ area_id: { _eq: rawString(item) } })),
                },
              ]
            : []),
          ...(filters.sections.length
            ? [
                {
                  _or: filters.sections.map((item) => ({ section_id: { _eq: rawString(item) } })),
                },
              ]
            : []),
          ...(filters.emergencies.length
            ? [
                {
                  _or: filters.emergencies.map((item) => ({ emergency: { _contains: { name: rawString(item) } } })),
                },
              ]
            : []),
        ],
      },
    },
    [
      {
        id: types.string,
        emergency: types.custom<{ name: string; capture_name?: string }>(),
        created_at: types.string,
        area: {
          id: types.string,
          name: types.string,
        },
        section: {
          id: types.string,
          name: types.string,
        },
        station: {
          id: types.string,
          name: types.string,
        },
        roles: [
          {
            id: types.string,
            name: types.string,
            created_at: types.string,
            updated_at: types.string,
            assigned_at: types.string,
          },
        ],
      },
    ]
  ),
});

export type Event = Unpacked<typeof _query>['events'][0];
export type EventRole = Event['roles'][0];

export const initQuery = (startDate: string, endDate: string, filters: Filters, organisationId?: string) =>
  gql`
    ${query(_query(startDate, endDate, filters, organisationId))}
  `;

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  organisationId?: string;
  filterOptions: FilterOptions;
  filters: Filters;
  setFilters: (value: React.SetStateAction<Filters>) => void;
}

const Reports = ({ classes, organisationId, filterOptions, filters, setFilters }: Props): React.ReactElement => {
  const [namedReport, setNamedReport] = useState<string | null>(null);

  const [events, setEvents] = useState<Event[]>([]);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [historyFiltered, setHistoryFiltered] = useState<string>('ONE');
  const [customStartDate, setCustomStartDate] = useState<Date>(new Date());
  const [customEndDate, setCustomEndDate] = useState<Date>(new Date());
  const [customDateErrors, setCustomDateErrors] = useState<{ startDate: string | boolean; endDate: string | boolean }>({ startDate: false, endDate: false });

  const getStartDate = useCallback((): Date => {
    let date: Date = new Date();
    switch (historyFiltered) {
      case 'ONE':
        date = getDaysPast(0);
        break;
      case 'WEEK':
        date = getStartOfWeek(0);
        break;
      case 'MONTH':
        date = getMonthsPast(1);
        break;
      case '3_MONTH':
        date = getMonthsPast(3);
        break;
      case 'CUSTOM':
        date = customStartDate;
        break;
      default:
        break;
    }
    date.setHours(0, 0, 0);
    return date;
  }, [historyFiltered, customStartDate]);

  const getEndDate = useCallback((): Date => {
    let date: Date = new Date();
    if (historyFiltered === 'CUSTOM') {
      date = customEndDate;
    }
    date.setHours(23, 59, 59);
    return date;
  }, [historyFiltered, customEndDate]);

  const { data, loading } = useQuery(initQuery(exactDateString(getStartDate()), exactDateString(getEndDate()), filters, organisationId), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      setEvents(data.events);
    }
    return () => {
      mounted = false;
    };
  }, [data]);

  const validateCustomDate = useCallback(async () => {
    if (historyFiltered === 'CUSTOM') {
      if (!customStartDate || !customEndDate) {
        setCustomDateErrors({ startDate: false, endDate: false });
        return;
      }
      if (customStartDate > customEndDate) {
        setCustomDateErrors((i) => ({ ...i, startDate: 'Start date must be before end date' }));
        return;
      } else {
        setCustomDateErrors({ startDate: false, endDate: false });
      }
    }
  }, [customStartDate, customEndDate, historyFiltered]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      validateCustomDate();
    }
    return () => {
      mounted = false;
    };
  }, [customStartDate, customEndDate, validateCustomDate]);

  const historyTypes = [
    { key: 'ONE', label: 'Today' },
    { key: 'WEEK', label: 'This week' },
    { key: 'MONTH', label: 'This Month' },
    { key: '3_MONTH', label: 'Last 3 Months' },
    { key: 'CUSTOM', label: 'Custom' },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item container spacing={2} alignItems={'center'}>
        <Grid item>
          <ToggleButtonGroup value={historyFiltered} exclusive color="primary" onChange={(_: unknown, value: string | null) => setHistoryFiltered(value || 'ONE')}>
            {historyTypes.map((i) => (
              <ToggleButton className={classes.toggleButton} key={i.key} value={i.key}>
                {i.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={2}>
          {historyFiltered === 'CUSTOM' && (
            <CustomDatePicker
              label="Start date"
              date={customStartDate}
              fullWidth
              error={customDateErrors.startDate as boolean}
              helperText={customDateErrors.startDate !== false ? (customDateErrors.startDate as string) : undefined}
              handleDateChange={(date) => setCustomStartDate(date as Date)}
            />
          )}
        </Grid>
        <Grid item xs={2}>
          {historyFiltered === 'CUSTOM' && (
            <CustomDatePicker
              label="End date"
              fullWidth
              date={customEndDate}
              error={customDateErrors.endDate as boolean}
              helperText={customDateErrors.endDate !== false ? (customDateErrors.endDate as string) : undefined}
              handleDateChange={(date) => setCustomEndDate(date as Date)}
            />
          )}
        </Grid>
        <Grid item textAlign={'end'} flexGrow={1}>
          <ToggleButtonGroup size="small">
            <Badge color="primary" variant={'dot'} invisible={!filters.areas.length && !filters.sections.length && !filters.emergencies.length}>
              <Button onClick={() => setIsFiltersOpen(true)} variant="outlined">
                Filters
              </Button>
            </Badge>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <List className={classes.list} component="nav">
          <ListItem className={classes.listItem} key="metrics" button onClick={() => setNamedReport(null)} selected={namedReport === null}>
            <ListItemText primary="Metrics" />
          </ListItem>
          <Divider />
          <ListItem className={classes.listItem} key="events" button onClick={() => setNamedReport('EVENTS')} selected={namedReport === 'EVENTS'}>
            <ListItemText primary="Events" />
          </ListItem>
        </List>
      </Grid>
      {namedReport && (
        <>
          <Grid item xs={10}>
            {namedReport === 'EVENTS' && <ReportEvents events={events} loading={loading} />}
          </Grid>
        </>
      )}
      {!namedReport && (
        <Grid item xs={10}>
          <Metrics events={events} loading={loading} />
        </Grid>
      )}
      <Drawer classes={{ paper: classes.drawer }} open={isFiltersOpen} onClose={() => setIsFiltersOpen(false)} anchor="right">
        <FiltersPane filterOptions={filterOptions} filters={filters} setFilters={setFilters} onClose={() => setIsFiltersOpen(false)} />
      </Drawer>
    </Grid>
  );
};

export default withStyles(styles)(Reports);
