import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
const _editOrganisationInitQuery = (id: string) => ({
  organisations_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
      postcode: types.string,
      first_assistance_label: types.string,
      second_assistance_label: types.string,
      trigger_emergency_label: types.string,
    }
  ),
});

export const editOrganisationInitQuery = (id: string) =>
  gql`
    ${query(_editOrganisationInitQuery(id))}
  `;
