import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import Dashboard from '../../../../../../layouts/Dashboard';
import ListSections from '../../../../../../components/Global/Areas/Sections/List';

import { sectionsInitQuery, Area } from './queries';

import styles from './styles';
import { updateAreaSection } from './mutations';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  organisation_id: string;
  area_id: string;
}

const ListSectionsAdmin = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id, area_id } = useParams<UrlParams>();

  const [area, setArea] = useState<Area>({
    name: '',
    organisation: {
      name: '',
    },
    sections: [],
  });

  const { data: sectionsInitData, refetch } = useQuery(sectionsInitQuery(area_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && sectionsInitData) {
      setArea(sectionsInitData.areas_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [sectionsInitData]);

  const handleCreate = () => {
    history.push(`/admin/trusts/${organisation_id}/departments/${area_id}/wards/create`);
  };

  const handleView = (id: string) => {
    history.push(`/admin/trusts/${organisation_id}/departments/${area_id}/wards/${id}`);
  };

  const handleActiveStatusChange = async (id: string, isActive: boolean) => {
    const updateVariables = {
      pk_columns: {
        id: id,
      },
      set: {
        active: isActive,
      },
    };
    await updateAreaSection(updateVariables);

    await refetch();
  };

  return (
    <Dashboard
      breadcrumbs={[
        { label: 'Trusts', link: '/admin/trusts' },
        { label: area.organisation.name, link: `/admin/trusts/${organisation_id}` },
        { label: 'Departments', link: `/admin/trusts/${organisation_id}/departments` },
        { label: area.name, link: `/admin/trusts/${organisation_id}/departments/${area_id}` },
        { label: 'Wards' },
      ]}>
      <ListSections items={area.sections} handleCreate={handleCreate} handleView={handleView} handleActiveStatusChange={handleActiveStatusChange} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ListSectionsAdmin));
