import { types, query } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _selectAdminUsersInitQuery = {
  users_admin: {
    id: types.string,
    full_name: types.string,
    email: types.string,
  },
};

type X = Unpacked<typeof _selectAdminUsersInitQuery>;
export type AdminUser = X['users_admin'];

export const selectAdminUsersInitQuery = gql`
  ${query(_selectAdminUsersInitQuery)}
`;
