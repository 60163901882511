import { gql } from '@apollo/client';

export const getOrganisation = gql`
  query ($id: uuid!) {
    organisations_by_pk(id: $id) {
      name
    }
  }
`;

export interface GetOrganisationResponse {
  organisations_by_pk: {
    name: string;
  };
}
