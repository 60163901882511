import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import Dashboard from '../../../../../layouts/Dashboard';

import ListAreas from '../../../../../components/Global/Areas/List';

import { areasInitQuery, Organisation } from './queries';

import styles from './styles';
import { updateArea } from './mutations';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  organisation_id: string;
}

const ListAreasAdmin = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id } = useParams<UrlParams>();

  const [organisation, setOrganisation] = useState<Organisation>({
    name: '',
    areas: [],
  });

  const { data: areasInitData, refetch } = useQuery(areasInitQuery(organisation_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && areasInitData) {
      setOrganisation(areasInitData.organisations_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [areasInitData]);

  const handleCreate = () => {
    history.push(`/admin/trusts/${organisation_id}/departments/create`);
  };

  const handleView = (id: string) => {
    history.push(`/admin/trusts/${organisation_id}/departments/${id}`);
  };

  const handleEdit = (id: string, view: string) => {
    history.push(`/admin/trusts/${organisation_id}/${view}/${id}/edit`);
  };

  const handleActiveStatusChange = async (id: string, isActive: boolean) => {
    const updateVariables = {
      pk_columns: {
        id: id,
      },
      set: {
        active: isActive,
      },
    };
    await updateArea(updateVariables);

    await refetch();
  };

  return (
    <Dashboard breadcrumbs={[{ label: 'Trusts', link: '/admin/trusts' }, { label: organisation.name, link: `/admin/trusts/${organisation_id}` }, { label: 'Departments' }]}>
      <ListAreas items={organisation.areas} handleCreate={handleCreate} handleView={handleView} handleEdit={handleEdit} handleActiveStatusChange={handleActiveStatusChange} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ListAreasAdmin));
