import React, { useState, useReducer, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import CustomBackdrop from '../../../../components/CustomBackdrop';
import { organisationUserReducer, OrganisationUserType } from '../Common';
import Steps from '../Common/components/Steps';

import { createOrganisationUserInitQuery, getUsersCountQuery, GetUsersCountRequest, GetUsersCountResponse } from './queries';
import { createOrganisationUser } from './mutations';
import styles from './styles';
import { useLazyQuery, useQuery } from '@apollo/client';
import CustomDialog from '../../../CustomDialog';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  organisation_id?: string;
  area_id?: string;
  handleFinish: (id: string) => void;
}

const CreateOrganisationUser = ({ classes, history, organisation_id, area_id, handleFinish }: Props): React.ReactElement => {
  const [userTypes, setUserTypes] = useState<OrganisationUserType[]>([]);

  const [user, dispatchUser] = useReducer(organisationUserReducer, {
    first_name: '',
    last_name: '',
    user_type: area_id ? 'AREA' : 'ORG',
    email: '',
    active: true,
  });

  const [saving, setSaving] = useState<boolean>(false);
  const [validationDialog, setValidationDialog] = useState<boolean>(false);

  const { data: organisationUserInitData } = useQuery(createOrganisationUserInitQuery(), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && organisationUserInitData) {
      setUserTypes(organisationUserInitData.enum_organisation_user_types);
    }
    return () => {
      mounted = false;
    };
  }, [organisationUserInitData]);

  const handleSave = () => {
    setSaving(true);
    getUsers({ variables: { email: user.email } });
    setSaving(false);
  };

  const [getUsers] = useLazyQuery<GetUsersCountResponse, GetUsersCountRequest>(getUsersCountQuery, {
    onCompleted(usersResponse) {
      if (usersResponse) {
        const {
          users_admin_aggregate: {
            aggregate: { count: adminsCount },
          },
          users_organisation_aggregate: {
            aggregate: { count: orgAdminsCount },
          },
        } = usersResponse;

        if (adminsCount === 0 && orgAdminsCount === 0) {
          const createVariables = {
            object: {
              organisation_id,
              area_id,
              ...user,
            },
          };
          createOrganisationUser(createVariables).then((user_id: string | undefined) => {
            if (user_id) {
              handleFinish(user_id);
            }
          });
        } else {
          setValidationDialog(true);
        }
      }
    },
    fetchPolicy: 'no-cache',
  });

  return (
    <>
      <Steps completeLabel="Create" user={user} userTypes={userTypes} isAreaUser={area_id !== undefined} dispatch={dispatchUser} handleSave={handleSave} />
      {saving && <CustomBackdrop label="Creating User" />}
      <CustomDialog
        open={validationDialog}
        title="Unable to create a user"
        message="User with the same email already exists"
        handleClose={() => setValidationDialog(false)}
        actions={[
          {
            label: 'OK',
            onClick: () => setValidationDialog(false),
          },
        ]}
      />
    </>
  );
};

export default withRouter(withStyles(styles)(CreateOrganisationUser));
