import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';

import { Questionnaire, QuestionnaireReducerAction, ValidationErrors } from '../..';

import styles from './styles';
import { Button, Divider, Grid, IconButton, TextField } from '@mui/material';

import { Delete as DeleteIcon } from '@mui/icons-material';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  questionnaire: Questionnaire;
  errors: ValidationErrors;
  dispatch: React.Dispatch<{ type: QuestionnaireReducerAction; value: any }>;
}

const StepQuestions = ({ classes, questionnaire, errors, dispatch }: Props): React.ReactElement => {
  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          color={(errors.categories as boolean) ? 'error' : undefined}
          fullWidth
          onClick={() => dispatch({ type: QuestionnaireReducerAction.ADD_CATEGORY, value: '' })}>
          Add category
        </Button>
      </Grid>
      {questionnaire.categories.map((category, categoryIndex) => (
        <>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={11}>
                <TextField
                  fullWidth
                  className={classes.textField}
                  data-qa="category-name-textfield"
                  required
                  label="Category Name"
                  variant="outlined"
                  error={errors.categoryName[categoryIndex] as boolean}
                  helperText={errors.categoryName[categoryIndex]}
                  value={category.name}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    dispatch({
                      type: QuestionnaireReducerAction.SET_CATEGORY_NAME,
                      value: {
                        index: categoryIndex,
                        value: event.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={1} alignContent="center">
                <IconButton size="small" onClick={() => dispatch({ type: QuestionnaireReducerAction.DELETE_CATEGORY, value: categoryIndex })}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Grid container rowSpacing={4} columnSpacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      className={classes.textField}
                      data-qa="low-threshold-textfield"
                      required
                      minRows={4}
                      multiline
                      label="Low Threshold Message"
                      variant="outlined"
                      error={errors.lowThresholdMessage[categoryIndex] as boolean}
                      helperText={errors.lowThresholdMessage[categoryIndex]}
                      value={category.lowThreshold.message}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        dispatch({
                          type: QuestionnaireReducerAction.SET_CATEGORY_LOW_THRESHOLD_MESSAGE,
                          value: {
                            index: categoryIndex,
                            value: event.target.value,
                          },
                        })
                      }
                    />
                  </Grid>
                  <Grid container spacing={2} display={'block'} item xs={6}>
                    <Grid item xs={12}>
                      <TextField
                        inputProps={{ type: 'number' }}
                        fullWidth
                        className={classes.textField}
                        data-qa="low-threshold-score-textfield"
                        required
                        label="Low Threshold Score"
                        variant="outlined"
                        error={errors.lowThresholdScore[categoryIndex] as boolean}
                        helperText={errors.lowThresholdScore[categoryIndex]}
                        value={category.lowThreshold.score}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          dispatch({
                            type: QuestionnaireReducerAction.SET_CATEGORY_LOW_THRESHOLD,
                            value: {
                              index: categoryIndex,
                              value: event.target.value,
                            },
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        className={classes.textField}
                        data-qa="low-threshold-referral-textfield"
                        label="Low Threshold Referral Email"
                        variant="outlined"
                        error={errors.lowThresholdEmail[categoryIndex] as boolean}
                        helperText={errors.lowThresholdEmail[categoryIndex]}
                        value={category.lowThreshold.referralEmail}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          dispatch({
                            type: QuestionnaireReducerAction.SET_CATEGORY_LOW_THRESHOLD_REFERRAL_EMAIL,
                            value: {
                              index: categoryIndex,
                              value: event.target.value,
                            },
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      className={classes.textField}
                      data-qa="medium-threshold-textfield"
                      required
                      multiline
                      minRows={4}
                      label="Medium Threshold Message"
                      variant="outlined"
                      error={errors.mediumThresholdMessage[categoryIndex] as boolean}
                      helperText={errors.mediumThresholdMessage[categoryIndex]}
                      value={category.mediumThreshold.message}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        dispatch({
                          type: QuestionnaireReducerAction.SET_CATEGORY_MEDIUM_THRESHOLD_MESSAGE,
                          value: {
                            index: categoryIndex,
                            value: event.target.value,
                          },
                        })
                      }
                    />
                  </Grid>
                  <Grid item container display={'block'} spacing={2} xs={6}>
                    <Grid item xs={12}>
                      <TextField
                        inputProps={{ type: 'number' }}
                        fullWidth
                        className={classes.textField}
                        data-qa="medium-threshold-score-textfield"
                        required
                        label="Medium Threshold Score"
                        variant="outlined"
                        error={errors.mediumThresholdScore[categoryIndex] as boolean}
                        helperText={errors.mediumThresholdScore[categoryIndex]}
                        value={category.mediumThreshold.score}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          dispatch({
                            type: QuestionnaireReducerAction.SET_CATEGORY_MEDIUM_THRESHOLD,
                            value: {
                              index: categoryIndex,
                              value: event.target.value,
                            },
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        className={classes.textField}
                        data-qa="medium-threshold-referral-textfield"
                        label="Medium Threshold Referral Email"
                        variant="outlined"
                        error={errors.mediumThresholdEmail[categoryIndex] as boolean}
                        helperText={errors.mediumThresholdEmail[categoryIndex]}
                        value={category.mediumThreshold.referralEmail}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          dispatch({
                            type: QuestionnaireReducerAction.SET_CATEGORY_MEDIUM_THRESHOLD_REFERRAL_EMAIL,
                            value: {
                              index: categoryIndex,
                              value: event.target.value,
                            },
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      className={classes.textField}
                      data-qa="high-threshold-textfield"
                      required
                      multiline
                      minRows={4}
                      label="High Threshold Message"
                      variant="outlined"
                      error={errors.hightThresholdMessage[categoryIndex] as boolean}
                      helperText={errors.hightThresholdMessage[categoryIndex]}
                      value={category.highThreshold.message}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        dispatch({
                          type: QuestionnaireReducerAction.SET_CATEGORY_HIGH_THRESHOLD_MESSAGE,
                          value: {
                            index: categoryIndex,
                            value: event.target.value,
                          },
                        })
                      }
                    />
                  </Grid>
                  <Grid container item spacing={2} display={'block'} xs={6}>
                    <Grid item xs={12}>
                      <TextField
                        inputProps={{ type: 'number' }}
                        fullWidth
                        className={classes.textField}
                        data-qa="high-threshold-score-textfield"
                        required
                        label="High Threshold Score"
                        variant="outlined"
                        error={errors.hightThresholdScore[categoryIndex] as boolean}
                        helperText={errors.hightThresholdScore[categoryIndex]}
                        value={category.highThreshold.score}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          dispatch({
                            type: QuestionnaireReducerAction.SET_CATEGORY_HIGH_THRESHOLD,
                            value: {
                              index: categoryIndex,
                              value: event.target.value,
                            },
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        className={classes.textField}
                        data-qa="high-threshold-email-textfield"
                        label="High Threshold Referral Email"
                        variant="outlined"
                        error={errors.hightThresholdEmail[categoryIndex] as boolean}
                        helperText={errors.hightThresholdEmail[categoryIndex]}
                        value={category.highThreshold.referralEmail}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          dispatch({
                            type: QuestionnaireReducerAction.SET_CATEGORY_HIGH_THRESHOLD_REFERRAL_EMAIL,
                            value: {
                              index: categoryIndex,
                              value: event.target.value,
                            },
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1}>
                <Divider orientation="vertical" variant="fullWidth" />
              </Grid>
              <Grid item xs={11}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      color={errors.questions?.[categoryIndex] ? 'error' : undefined}
                      fullWidth
                      onClick={() => dispatch({ type: QuestionnaireReducerAction.ADD_QUESTION, value: categoryIndex })}>
                      Add question
                    </Button>
                  </Grid>
                  {category.questions.map((question, questionIndex) => (
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={11}>
                          <TextField
                            fullWidth
                            className={classes.textField}
                            data-qa="question-name-textfield"
                            required
                            label={`Question ${questionIndex + 1}`}
                            variant="outlined"
                            error={errors.questionName?.[categoryIndex]?.[questionIndex] as boolean}
                            helperText={errors.questionName?.[categoryIndex]?.[questionIndex]}
                            value={question.name}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                              dispatch({
                                type: QuestionnaireReducerAction.SET_QUESTION_NAME,
                                value: {
                                  categoryIndex: categoryIndex,
                                  questionIndex: questionIndex,
                                  value: event.target.value,
                                },
                              })
                            }
                          />
                        </Grid>
                        <Grid item xs={1} alignContent="center">
                          <IconButton
                            size="small"
                            onClick={() =>
                              dispatch({
                                type: QuestionnaireReducerAction.DELETE_QUESTION,
                                value: {
                                  categoryIndex: categoryIndex,
                                  questionIndex: questionIndex,
                                },
                              })
                            }>
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={11}>
                              <Button
                                variant="outlined"
                                fullWidth
                                color={errors.answers?.[categoryIndex]?.[questionIndex] ? 'error' : undefined}
                                onClick={() =>
                                  dispatch({
                                    type: QuestionnaireReducerAction.ADD_ANSWER,
                                    value: {
                                      categoryIndex: categoryIndex,
                                      questionIndex: questionIndex,
                                    },
                                  })
                                }>
                                Add answer
                              </Button>
                            </Grid>
                            {question.answers.map((answer, answerIndex) => (
                              <>
                                <Grid item xs={6}>
                                  <TextField
                                    fullWidth
                                    className={classes.textField}
                                    data-qa="answer-textfield"
                                    required
                                    label="Answer"
                                    variant="outlined"
                                    error={errors.answerName?.[categoryIndex]?.[questionIndex]?.[answerIndex] as boolean}
                                    helperText={errors.answerName?.[categoryIndex]?.[questionIndex]?.[answerIndex]}
                                    value={answer.name}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                      dispatch({
                                        type: QuestionnaireReducerAction.SET_ANSWER_NAME,
                                        value: {
                                          categoryIndex: categoryIndex,
                                          questionIndex: questionIndex,
                                          answerIndex: answerIndex,
                                          value: event.target.value,
                                        },
                                      })
                                    }
                                  />
                                </Grid>
                                <Grid item xs={5}>
                                  <TextField
                                    fullWidth
                                    inputProps={{ type: 'number' }}
                                    className={classes.textField}
                                    data-qa="answer-score-textfield"
                                    required
                                    label="Score"
                                    variant="outlined"
                                    error={errors.answerScore?.[categoryIndex]?.[questionIndex]?.[answerIndex] as boolean}
                                    helperText={errors.answerScore?.[categoryIndex]?.[questionIndex]?.[answerIndex]}
                                    value={answer.score}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                      dispatch({
                                        type: QuestionnaireReducerAction.SET_ANSWER_SCORE,
                                        value: {
                                          categoryIndex: categoryIndex,
                                          questionIndex: questionIndex,
                                          answerIndex: answerIndex,
                                          value: event.target.value,
                                        },
                                      })
                                    }
                                  />
                                </Grid>
                                <Grid item xs={1} alignContent="center">
                                  <IconButton
                                    size="small"
                                    onClick={() =>
                                      dispatch({
                                        type: QuestionnaireReducerAction.DELETE_ANSWER,
                                        value: {
                                          categoryIndex: categoryIndex,
                                          questionIndex: questionIndex,
                                          answerIndex: answerIndex,
                                        },
                                      })
                                    }>
                                    <DeleteIcon />
                                  </IconButton>
                                </Grid>
                              </>
                            ))}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider variant="fullWidth">{category.name}</Divider>
          </Grid>
        </>
      ))}
    </Grid>
  );
};

export default withStyles(styles)(StepQuestions);
