import { capitaliseFirstOnly } from '../../../../utils/stringUtils';
import { ValidationType } from '../../../../react-app-env';

export interface Category {
  name: string;
  description: string;
  areas: Area[];
  emergencies: Emergency[];
}

export interface CategoryItem {
  id: string;
  name: string;
  description: string;
  area_assignments: AreaAssignment[];
  emergency_assignments: EmergencyAssignment[];
  active: boolean;
}

export interface Area {
  id: string;
  name: string;
  department: string;
}

export interface AreaAssignment {
  id: string;
  area: {
    id: string;
    name: string;
    phone: string;
    department: string;
  };
}

export interface Emergency {
  id: string;
  name: string;
  description: string;
}

export interface EmergencyAssignment {
  id: string;
  emergency: {
    id: string;
    name: string;
    description: string;
  };
}

export interface ValidationErrors {
  name: ValidationType;
  description: ValidationType;
}

export const validationErrors: ValidationErrors = {
  name: 'Please provide a name',
  description: 'Please provide a description',
};

export interface Validation {
  name: (value: string) => ValidationType;
  description: (value: string) => ValidationType;
}

export const validation: Validation = {
  name: (value) => (!value ? validationErrors.name : false),
  description: (value) => (!value ? validationErrors.description : false),
};

export enum CategoryReducerAction {
  NAME,
  DESCRIPTION,
  UPDATE_AREAS,
  UPDATE_EMERGENCIES,
  INIT,
}

export const categoryReducer = (state: Category, action: { type: CategoryReducerAction; value: any }): Category => {
  switch (action.type) {
    case CategoryReducerAction.NAME:
      return { ...state, name: capitaliseFirstOnly(action.value) };
    case CategoryReducerAction.DESCRIPTION:
      return { ...state, description: action.value as string };
    case CategoryReducerAction.UPDATE_AREAS:
      return { ...state, areas: action.value };
    case CategoryReducerAction.UPDATE_EMERGENCIES:
      return { ...state, emergencies: action.value };
    case CategoryReducerAction.INIT:
      const { name, description, area_assignments, emergency_assignments } = action.value;
      const obj = {
        name,
        description,
        areas: area_assignments.map((a: AreaAssignment) => a.area) as Area[],
        emergencies: emergency_assignments.map((a: EmergencyAssignment) => a.emergency) as Emergency[],
      };
      return { ...(obj as Category) };
    default:
      throw new Error();
  }
};
