
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';

const _editSectionInitQuery = (id: string) => ({
  area_sections_by_pk: params(
    {
      id: rawString(id),
    },
    {
      name: types.string,
      phone: types.string,
    },
  )
});

export const editSectionInitQuery = (id: string) => gql`${query(_editSectionInitQuery(id))}`;
