import { gql } from '@apollo/client';
import gqlClient from '../../../../../../../utils/apolloClient';

const updateAreaSectionStationMutation = gql`
  mutation ($pk_columns: area_section_stations_pk_columns_input!, $set: area_section_stations_set_input!) {
    update_area_section_stations_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

export const updateAreaSectionStation = async (variables: object) => {
  return await gqlClient.mutate<{
    update_area_section_stations_by_pk: {
      id: string;
    };
  }>({ mutation: updateAreaSectionStationMutation!, variables });
};
