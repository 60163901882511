import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import Dashboard from '../../../../../layouts/Dashboard';
import ListSections from '../../../../../components/Global/Categories/List';

import { categoriesInitQuery, Organisation } from './queries';

import styles from './styles';
import { updateCategory } from './mutations';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  organisation_id: string;
}

const ListSectionsAdmin = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id } = useParams<UrlParams>();

  const [organisation, setOrganisation] = useState<Organisation>({
    name: '',
    categories: [],
  });

  const { data: categoriesInitData, refetch } = useQuery(categoriesInitQuery(organisation_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && categoriesInitData) {
      setOrganisation(categoriesInitData.organisations_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [categoriesInitData]);

  const handleCreate = () => {
    history.push(`/admin/trusts/${organisation_id}/grouping/create`);
  };

  const handleEdit = (id: string, view: string) => {
    history.push(`/admin/trusts/${organisation_id}/${view}/${id}/edit`);
  };

  const handleActiveStatusChange = async (id: string, isActive: boolean) => {
    const updateVariables = {
      pk_columns: {
        id: id,
      },
      set: {
        active: isActive,
      },
    };
    await updateCategory(updateVariables);

    await refetch();
  };

  return (
    <Dashboard breadcrumbs={[{ label: 'Trusts', link: '/admin/trusts' }, { label: organisation.name, link: `/admin/trusts/${organisation_id}` }, { label: 'Grouping' }]}>
      <ListSections items={organisation.categories} handleCreate={handleCreate} handleEdit={handleEdit} handleActiveStatusChange={handleActiveStatusChange} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ListSectionsAdmin));
