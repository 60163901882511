import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Dashboard from '../../../../../../layouts/Dashboard';
import CreateSection from '../../../../../../components/Global/Areas/Sections/Create';

import { sectionIitQuery, Area } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  organisation_id: string;
  area_id: string;
}

const CreateSectionAdmin = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id, area_id } = useParams<UrlParams>();

  const [area, setArea] = useState<Area>({
    name: '',
    organisation: {
      name: '',
    },
  });

  const { data: sectionInitData } = useQuery(sectionIitQuery(area_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && sectionInitData) {
      setArea(sectionInitData.areas_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [sectionInitData]);

  const handleFinish = (id: string) => {
    history.push(`/admin/trusts/${organisation_id}/departments/${area_id}/wards/${id}`);
  };

  return (
    <>
      <Dashboard
        breadcrumbs={[
          { label: 'Trusts', link: '/admin/trusts' },
          { label: area.organisation.name, link: `/admin/trusts/${organisation_id}` },
          { label: 'Departments', link: `/admin/trusts/${organisation_id}/departments` },
          { label: area.name, link: `/admin/trusts/${organisation_id}/departments/${area_id}` },
          { label: 'Wards', link: `/admin/trusts/${organisation_id}/departments/${area_id}/wards` },
          { label: 'Create' },
        ]}>
        <CreateSection area_id={area_id} organisation_id={organisation_id} handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(withStyles(styles)(CreateSectionAdmin));
