import React, { useState, useEffect, useReducer } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Dashboard from '../../../../layouts/Dashboard';
import CustomBackdrop from '../../../../components/CustomBackdrop';

import { organisationReducer, OrganisationReducerAction } from '../Common';
import Steps from '../Common/components/Steps';

import { editOrganisationInitQuery } from './queries';
import { updateOrganisation } from './mutations';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  organisation_id: string;
}

const EditOrganisation = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id } = useParams<UrlParams>();

  const [organisation, dispatchOrganisation] = useReducer(organisationReducer, {
    name: '',
    postcode: '',
    first_assistance_label: '',
    second_assistance_label: '',
    trigger_emergency_label: '',
  });

  const [saving, setSaving] = useState<boolean>(false);

  const { data: editOrganisationInitData } = useQuery(editOrganisationInitQuery(organisation_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editOrganisationInitData) {
      const org = editOrganisationInitData.organisations_by_pk;
      dispatchOrganisation({ type: OrganisationReducerAction.INIT, value: org });
    }
    return () => {
      mounted = false;
    };
  }, [editOrganisationInitData]);

  const handleSave = async () => {
    setSaving(true);

    const updateVariables = {
      pk_columns: {
        id: organisation_id,
      },
      set: {
        ...organisation,
        first_assistance_label: organisation.first_assistance_label ? organisation.first_assistance_label : 'Assistance Label 1',
        second_assistance_label: organisation.second_assistance_label ? organisation.second_assistance_label : 'Assistance Label 2',
        trigger_emergency_label: organisation.trigger_emergency_label ? organisation.trigger_emergency_label : 'Trigger Emergency',
      },
    };
    await updateOrganisation(updateVariables);

    setSaving(false);
    handleFinish();
  };

  const handleFinish = () => {
    history.push(`/admin/trusts/${organisation_id}`);
  };

  return (
    <>
      <Dashboard breadcrumbs={[{ label: 'Trusts', link: '/admin/trusts' }, { label: organisation.name, link: `/admin/trusts/${organisation_id}` }, { label: 'Edit' }]}>
        <Steps completeLabel="Save" organisation={organisation} dispatch={dispatchOrganisation} handleSave={handleSave} />
      </Dashboard>
      {saving && <CustomBackdrop label="Saving Changes" />}
    </>
  );
};

export default withRouter(withStyles(styles)(EditOrganisation));
