import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';

import ReadOnlyBlock from '../../../../../../components/ReadOnlyBlock';
import { EraCareEvent } from '../..';

import styles from './styles';
import { booleanToYesNo } from '../../../../../../utils/stringUtils';
import { formatDate } from '../../../../../../utils/dateUtils';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  eraCareEvent: EraCareEvent;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const StepReview = ({ classes, eraCareEvent, setStep }: Props): React.ReactElement => {
  return (
    <>
      <ReadOnlyBlock
        gridSize={8}
        items={[
          { label: 'Created At', value: formatDate(new Date(eraCareEvent.created_at), 'yyyy-MM-DD') },
          { label: 'Patient First Name', value: eraCareEvent.patient_first_name },
          { label: 'Patient Last Name', value: eraCareEvent.patient_last_name },
          { label: 'Patient Mobile Number', value: eraCareEvent.patient_mobile_number ? eraCareEvent.patient_mobile_number : 'Not provided' },
          { label: 'Patient Email', value: eraCareEvent.patient_email ? eraCareEvent.patient_email : 'Not provided' },
          { label: 'Patient gives consent to contact', value: booleanToYesNo(eraCareEvent.patient_gives_consent_to_contact) },
        ]}
        handleEdit={() => setStep(0)}
      />
    </>
  );
};

export default withStyles(styles)(StepReview);
