
import { query, types } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _areasInitQuery = () => ({
  categories: [{
    id: types.string,
    name: types.string,
  }],
});

type X = Unpacked<typeof _areasInitQuery>;
export type Category = X['categories'][0];

export const areasInitQuery = () => gql`${query(_areasInitQuery())}`;
