import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Dashboard from '../../../../layouts/Dashboard';
import styles from './styles';
import ListEraCareQuestionnaireComponent from '../../../../components/Global/EraCareQuestionnaire/List';
import { useAuthProvider } from '../../../../components/AuthProvider';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

const ListEraCareQuestionnaire = ({ classes, history }: Props): React.ReactElement => {
  const { organisationId } = useAuthProvider();

  const handleCreate = () => {
    history.push(`/trust/care-questionnaires/create`);
  };

  const handleEdit = (id: string) => {
    history.push(`/trust/care-questionnaires/${id}/edit`);
  };

  return (
    <Dashboard breadcrumbs={[{ label: 'ERA Care Questionnaire' }]}>
      <ListEraCareQuestionnaireComponent organisationId={organisationId!} handleCreate={handleCreate} handleEdit={handleEdit} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ListEraCareQuestionnaire));
