import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _selectAdminUserInitQuery = (admin_user_id: string) => ({
  users_admin_by_pk: params(
    { id: rawString(admin_user_id) },
    {
      id: types.string,
      full_name: types.string,
      email: types.string,
    },
  ),
});

type X = Unpacked<typeof _selectAdminUserInitQuery>
export type AdminUser = X['users_admin_by_pk'];

export const selectAdminUserInitQuery = (admin_user_id: string) => gql`${query(_selectAdminUserInitQuery(admin_user_id))}`;
