
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _selectAreaInitQuery = (id: string) => ({
  areas_by_pk: params(
    { id: rawString(id) },
    {
      id: types.string,
      name: types.string,
      phone: types.string,
      department: types.string,
      category_assignments: [{
        category: {
          name: types.string,
        },
      }],
      organisation: {
        name: types.string,
      },
      sections_aggregate: params(
        {
          where: { stations: { base_station: { _eq: false } } },
        },
        {
          aggregate: {
            count: types.number,
          },
        },
      ),
      users_aggregate: {
        aggregate: {
          count: types.number,
        },
      },
    }
  ),
});

type X = Unpacked<typeof _selectAreaInitQuery>
export type Area = X['areas_by_pk'];
export type CategoryAssignment = Area['category_assignments'][0];
export type Category = CategoryAssignment['category'];

export const selectAreaInitQuery = (id: string) => gql`${query(_selectAreaInitQuery(id))}`;
