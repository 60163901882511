import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import Dashboard from '../../../../../layouts/Dashboard';
import ListEmergencies from '../../../../../components/Global/Emergencies/List';

import { emergenciesInitQuery, Organisation } from './queries';

import styles from './styles';
import { updateEmergencies, updateEmergency } from './mutations';
import { ReorderDirection } from '../../../../../components/Global/Emergencies/Common';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  organisation_id: string;
}

const ListEmergenciesAdmin = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id } = useParams<UrlParams>();

  const [organisation, setOrganisation] = useState<Organisation>({
    name: '',
    emergencies: [],
  });

  const { data: emergenciesInitData, refetch } = useQuery(emergenciesInitQuery(organisation_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && emergenciesInitData) {
      setOrganisation(emergenciesInitData.organisations_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [emergenciesInitData]);

  const handleCreate = () => {
    history.push(`/admin/trusts/${organisation_id}/emergencies/create`);
  };

  const handleEdit = (id: string, view: string) => {
    history.push(`/admin/trusts/${organisation_id}/${view}/${id}/edit`);
  };

  const handleActiveStatusChange = async (id: string, isActive: boolean) => {
    const updateVariables = {
      pk_columns: {
        id: id,
      },
      set: {
        active: isActive,
      },
    };
    await updateEmergency(updateVariables);

    await refetch();
  };

  const handlePriorityChange = async (id: string, priority: number, direction: ReorderDirection) => {
    const previousPriority = direction === ReorderDirection.UP ? priority + 1 : priority - 1;

    const prevElement = organisation.emergencies.find((item) => item.priority_index === priority);
    const currentElement = organisation.emergencies.find((item) => item.priority_index === previousPriority);

    const updateVariables = [
      {
        id: prevElement!.id,
        priority_index: previousPriority,
        name: prevElement!.name,
      },
      {
        id: id,
        priority_index: priority,
        name: currentElement!.name,
      },
    ];

    await updateEmergencies(updateVariables);

    await refetch();
  };

  return (
    <Dashboard breadcrumbs={[{ label: 'Trusts', link: '/admin/trusts' }, { label: organisation.name, link: `/admin/trusts/${organisation_id}` }, { label: 'Emergencies' }]}>
      <ListEmergencies
        items={organisation.emergencies}
        handleCreate={handleCreate}
        handleEdit={handleEdit}
        handleActiveStatusChange={handleActiveStatusChange}
        handlePriorityChange={handlePriorityChange}
      />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ListEmergenciesAdmin));
