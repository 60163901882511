
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../react-app-env';

const _selectStationInitQuery = (id: string) => ({
  area_section_stations_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
      section: {
        name: types.string,
      },
    }
  ),
});

type X = Unpacked<typeof _selectStationInitQuery>
export type Station = X['area_section_stations_by_pk'];

export const selectStationInitQuery = (id: string) => gql`${query(_selectStationInitQuery(id))}`;
