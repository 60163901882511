import React, { useState, useReducer } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { UnknownObject } from '../../../../react-app-env';

import {
} from '@mui/material';

import CustomBackdrop from '../../../../components/CustomBackdrop';
import { Emergency, emergencyReducer } from '../Common';
import Steps from '../Common/components/Steps';

import { createStation } from './mutations';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
  organisation_id?: string,
  emergencies: Emergency[],
  handleFinish: (id: string) => void,
}

const CreateEmergencyRole = ({ classes, history, organisation_id, emergencies, handleFinish }: Props): React.ReactElement => {
  
  const [emergencyRole, dispatchEmergency] = useReducer(emergencyReducer, {
    name: '',
    description: '',
    emergencies: [],
  });

  const [saving, setSaving] = useState<boolean>(false);

  const handleSave = async () => {
    setSaving(true);

    const item: UnknownObject = {
      ...emergencyRole,
    };

    delete item.emergencies;

    const insertVariables = {
      object: {
        ...item,
        organisation_id,
        description: emergencyRole.description || undefined,
        emergency_assignments: {
          data: emergencyRole.emergencies.map((i: Emergency) => ({
            organisation_id,
            emergency_id: i.id,
          })),
        },
      },
    };
    const emergency_id: string | undefined = await createStation(insertVariables);
    if (emergency_id) {
      setSaving(false);
      handleFinish(emergency_id);
    }
  };

  return (
    <>
      <Steps
        completeLabel="Create"
        emergency={emergencyRole}
        emergencies={emergencies}
        dispatch={dispatchEmergency}
        handleSave={handleSave} />
      {saving && <CustomBackdrop label="Creating Emergency role" />}
    </>
  );
};

export default withRouter(withStyles(styles)(CreateEmergencyRole));
