import { types, query, rawString, params } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../../react-app-env';

const _selectAreaUsersInitQuery = (id: string) => ({
  areas_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
      organisation: {
        name: types.string,
      },
      users: [
        {
          id: types.string,
          full_name: types.string,
          email: types.string,
          active: types.boolean,
          enum_user_type: {
            user_type: types.string,
            description: types.string,
          },
        },
      ],
    }
  ),
});

type X = Unpacked<typeof _selectAreaUsersInitQuery>;
export type Area = X['areas_by_pk'];
export type AreaUser = Area['users'][0];

export const selectAreaUsersInitQuery = (id: string) =>
  gql`
    ${query(_selectAreaUsersInitQuery(id))}
  `;

export const selectOrganisationUserQuery = gql`
  query ($user_id: uuid!) {
    users_organisation_by_pk(id: $user_id) {
      id
      organisation_id
      active
      first_name
      last_name
      email
      user_type
      area_id
      created_at
    }
  }
`;

export interface SelectOrganisationUserResponse {
  users_organisation_by_pk: {
    id: string;
    organisation_id: string;
    active: boolean;
    first_name: string;
    last_name: string;
    email: string;
    user_type: string;
    area_id: string;
    created_at: string;
  };
}

export interface SelectOrganisationUserRequest {
  user_id: string;
}
