import React, { useEffect, useState } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import Dashboard from '../../../layouts/Dashboard';
import ListEvents from '../../../components/Global/Events/List';

import styles from './styles';
import { Filters } from '../../../components/Global/Events/List/FiltersPane';
import { Area, Emergency, Section, eventsInitQuery } from './queries';
import { useQuery } from '@apollo/client';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface State {
  areas: Area[];
  sections: Section[];
  emergencies: Emergency[];
}

const ListEventsOrg = ({ classes, history }: Props): React.ReactElement => {
  const [pageState, setPageState] = useState<State>({
    areas: [],
    sections: [],
    emergencies: [],
  });

  const [filters, setFilters] = useState<Filters>({ areas: [], emergencies: [], sections: [] });

  const { data: eventsData } = useQuery(eventsInitQuery(filters.areas), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && eventsData) {
      setPageState({
        areas: eventsData.areas,
        sections: eventsData.area_sections,
        emergencies: eventsData.emergencies,
      });
    }
    return () => {
      mounted = false;
    };
  }, [eventsData]);
  return (
    <Dashboard title="Reports">
      <ListEvents filterOptions={{ areas: pageState.areas, sections: pageState.sections, emergencies: pageState.emergencies }} filters={filters} setFilters={setFilters} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ListEventsOrg));
