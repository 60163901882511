import { gql } from '@apollo/client';

export const getUsersCountQuery = gql`
  query ($email: String!) {
    users_admin_aggregate(where: { email: { _eq: $email } }) {
      aggregate {
        count
      }
    }
    users_organisation_aggregate(where: { email: { _eq: $email } }) {
      aggregate {
        count
      }
    }
  }
`;

export interface GetUsersCountRequest {
  email: string;
}

export interface GetUsersCountResponse {
  users_admin_aggregate: {
    aggregate: {
      count: number;
    };
  };
  users_organisation_aggregate: {
    aggregate: {
      count: number;
    };
  };
}
