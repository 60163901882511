import { createStyles, Theme } from '@mui/material';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(1),
    },
  });

export default styles;
