import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';

import ReadOnlyBlock from '../../../../../../../components/ReadOnlyBlock';
import { Section } from '../..';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  section: Section;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const StepReview = ({ classes, section, setStep }: Props): React.ReactElement => {
  return (
    <>
      <ReadOnlyBlock
        items={[
          { label: 'Department name', value: section.name },
          { label: 'Phone', value: section.phone || 'No phone number' },
        ]}
        handleEdit={() => setStep(0)}
      />
    </>
  );
};

export default withStyles(styles)(StepReview);
