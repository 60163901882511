
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _selectEmergencyInitQuery = (id: string) => ({
  emergencies_by_pk: params(
    { id: rawString(id) },
    {
      id: types.string,
      name: types.string,
      description: types.string,
      category_assignments: [{
        id: types.string,
        category: {
          id: types.string,
          name: types.string,
        },
      }],
    }
  ),
});

type X = Unpacked<typeof _selectEmergencyInitQuery>
export type Emergency = X['emergencies_by_pk'];

export const selectEmergencyInitQuery = (id: string) => gql`${query(_selectEmergencyInitQuery(id))}`;
