import React, { useState, useEffect, useReducer } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { UnknownObject } from '../../../../react-app-env';

import {
} from '@mui/material';

import CustomBackdrop from '../../../../components/CustomBackdrop';

import { areaReducer, AreaReducerAction, Category, CategoryAssignment } from '../Common';
import Steps from '../Common/components/Steps';

import { editAreaInitQuery } from './queries';
import { updateArea, updateCategoryAssignments } from './mutations';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
  organisation_id?: string,
  categories: Category[],
  area_id: string,
  handleFinish: () => void,
}

const EditArea = ({ classes, history, organisation_id, area_id, categories, handleFinish }: Props): React.ReactElement => {

  const [area, dispatchArea] = useReducer(areaReducer, {
    name: '',
    phone: '',
    department: '',
    categories: [],
  });

  const [assignedCategories, setAssignedCategories] = useState<CategoryAssignment[]>([]);

  const [saving, setSaving] = useState<boolean>(false);

  const { data: editAreaInitData } = useQuery(editAreaInitQuery(area_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editAreaInitData) {
      setAssignedCategories(editAreaInitData.areas_by_pk.category_assignments);
      dispatchArea({ type: AreaReducerAction.INIT, value: editAreaInitData.areas_by_pk });
    }
    return () => { mounted = false; };
  }, [editAreaInitData]);

  const handleSave = async () => {
    setSaving(true);

    const assignments = area.categories
      .filter((a: Category) => !assignedCategories.some((i: CategoryAssignment) => a.id === i.category.id))
      .map((a: Category) => ({ category_id: a.id, organisation_id, area_id }));
    
    const assignmentsRemove = assignedCategories
      .filter((i: CategoryAssignment) => !area.categories.some((a: Category) => a.id === i.category.id))
      .map((i: CategoryAssignment) => i.id);

    const item: UnknownObject = {
      ...area,
    };

    delete item.categories;

    const mData = {
      ...item,
      organisation_id,
    };

    const updateVariables = {
      pk_columns: {
        id: area_id,
      },
      set: mData,
    };
    await updateArea(updateVariables);
    await updateCategoryAssignments(assignments, assignmentsRemove);

    setSaving(false);
    handleFinish();
  };

  return (
    <>
      <Steps
        completeLabel="Save"
        area={area}
        categories={categories}
        dispatch={dispatchArea}
        handleSave={handleSave} />
      {saving && <CustomBackdrop label="Saving Changes" />}
    </>
  );
};

export default withRouter(withStyles(styles)(EditArea));
