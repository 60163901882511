import { capitaliseFirstOnly } from '../../../../utils/stringUtils';
import { ValidationType } from '../../../../react-app-env';

export interface Organisation {
  name: string;
  postcode: string;
  first_assistance_label: string;
  second_assistance_label: string;
  trigger_emergency_label: string;
}

export interface ValidationErrors {
  name: ValidationType;
  postcode: ValidationType;
}

export const validationErrors: ValidationErrors = {
  name: 'Please provide a name',
  postcode: 'Please provide a postcode',
};

export interface Validation {
  name: (name: string) => ValidationType;
  postcode: (phone: string) => ValidationType;
}

export const validation: Validation = {
  name: (name) => (!name ? validationErrors.name : false),
  postcode: (phone) => (!phone ? validationErrors.postcode : false),
};

export enum OrganisationReducerAction {
  NAME,
  POSTCODE,
  FIRST_ASSISTANCE_LABEL,
  SECOND_ASSISTANCE_LABEL,
  TRIGGER_EMERGENCY_LABEL,
  INIT,
}

export const organisationReducer = (state: Organisation, action: { type: OrganisationReducerAction; value: any }): Organisation => {
  switch (action.type) {
    case OrganisationReducerAction.NAME:
      return { ...state, name: capitaliseFirstOnly(action.value) };
    case OrganisationReducerAction.POSTCODE:
      return { ...state, postcode: `${action.value}`.toUpperCase() };
    case OrganisationReducerAction.FIRST_ASSISTANCE_LABEL:
      return { ...state, first_assistance_label: action.value };
    case OrganisationReducerAction.SECOND_ASSISTANCE_LABEL:
      return { ...state, second_assistance_label: action.value };
    case OrganisationReducerAction.TRIGGER_EMERGENCY_LABEL:
      return { ...state, trigger_emergency_label: action.value };
    case OrganisationReducerAction.INIT:
      const { name, postcode, first_assistance_label, second_assistance_label, trigger_emergency_label } = action.value;
      const obj = {
        name,
        postcode,
        first_assistance_label,
        second_assistance_label,
        trigger_emergency_label,
      };
      return { ...(obj as Organisation) };
    default:
      throw new Error();
  }
};
