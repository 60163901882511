import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    actions: {
      '@media print': {
        display: 'none',
      },
    },
  });

export default styles;
