import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) => createStyles({
  root: {},
  paper: {
    minWidth: 400,
    minHeight: 400,
    maxHeight: 900,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  listTitle: {
    textAlign: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  error: {
    color: theme.palette.error.main,
  },
  search: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  searchInput: {
    width: '100%',
  },
  searchButton: {
    cursor: 'pointer',
    width: 24,
    height: 24,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  description: {

  },
});

export default styles;
