import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Dashboard from '../../../../../../../layouts/Dashboard';
import EditStation from '../../../../../../../components/Global/Areas/Sections/Stations/Edit';

import { editStationInitQuery, Station } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  organisation_id: string;
  area_id: string;
  section_id: string;
  station_id: string;
}

const EditStationAdmin = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id, area_id, section_id, station_id } = useParams<UrlParams>();

  const [station, setStation] = useState<Station>({
    name: '',
    section: {
      name: '',
    },
    area: {
      name: '',
    },
    organisation: {
      name: '',
    },
  });

  const { data: editStationInitData } = useQuery(editStationInitQuery(station_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editStationInitData) {
      setStation(editStationInitData.area_section_stations_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [editStationInitData]);

  const handleFinish = () => {
    history.push(`/admin/trusts/${organisation_id}/departments/${area_id}/wards/${section_id}/bed-spaces`);
  };

  return (
    <>
      <Dashboard
        breadcrumbs={[
          { label: 'Trusts', link: '/admin/trusts' },
          { label: station.organisation.name, link: `/admin/trusts/${organisation_id}` },
          { label: 'Departments', link: `/admin/trusts/${organisation_id}/departments` },
          { label: station.area.name, link: `/admin/trusts/${organisation_id}/departments/${area_id}` },
          { label: 'Wards', link: `/admin/trusts/${organisation_id}/departments/${area_id}/wards` },
          { label: station.section.name, link: `/admin/trusts/${organisation_id}/departments/${area_id}/wards/${section_id}` },
          { label: 'Bed spaces', link: `/admin/trusts/${organisation_id}/departments/${area_id}/wards/${section_id}/bed-spaces` },
          { label: station.name, link: `/admin/trusts/${organisation_id}/departments/${area_id}/wards/${section_id}/bed-spaces/${station_id}` },
          { label: 'Edit' },
        ]}>
        <EditStation station_id={station_id} handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(withStyles(styles)(EditStationAdmin));
