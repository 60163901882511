import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import Dashboard from '../../../../../../layouts/Dashboard';

import CreateOrganisationUser from '../../../../../../components/Global/OrganisationUsers/Create';

import { createAreaUserInitQuery, Area } from './queries';
import styles from './styles';
import { useQuery } from '@apollo/client';

interface UrlParams {
  organisation_id: string;
  area_id: string;
}
interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

const CreateAreaUserAdmin = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id, area_id } = useParams<UrlParams>();

  const [area, setArea] = useState<Area>({
    name: '',
    organisation: {
      name: '',
    },
  });

  const { data: organisationUserInitData } = useQuery(createAreaUserInitQuery(area_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && organisationUserInitData) {
      setArea(organisationUserInitData.areas_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [organisationUserInitData]);

  const handleFinish = (id: string) => {
    history.push(`/admin/trusts/${organisation_id}/departments/${area_id}/users/${id}`);
  };

  return (
    <>
      <Dashboard
        breadcrumbs={[
          { label: 'Trusts', link: '/admin/trusts' },
          { label: area.organisation.name, link: `/admin/trusts/${organisation_id}` },
          { label: 'Departments', link: `/admin/trusts/${organisation_id}/departments` },
          { label: area.name, link: `/admin/trusts/${organisation_id}/departments/${area_id}` },
          { label: 'Users', link: `/admin/trusts/${organisation_id}/departments/${area_id}/users` },
          { label: 'Create' },
        ]}>
        <CreateOrganisationUser organisation_id={organisation_id} area_id={area_id} handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(withStyles(styles)(CreateAreaUserAdmin));
