import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  modifiers: {
    marginBottom: theme.spacing(2),
  }
});

export default styles;
