import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  modifiers: {
    marginBottom: theme.spacing(2),
  },
  box: {
    minHeight: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  },
});

export default styles;
