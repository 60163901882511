import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Dashboard from '../../../../../layouts/Dashboard';
import EditEmergencyRole from '../../../../../components/Global/EmergencyRoles/Edit';

import { editEmergencyRoleInitQuery, EmergencyRole } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  organisation_id: string;
  emergency_role_id: string;
}

const EditEmergencyRoleAdmin = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id, emergency_role_id } = useParams<UrlParams>();

  const [emergencyRole, setEmergencyRole] = useState<EmergencyRole>({
    name: '',
    organisation: {
      name: '',
    },
  });

  const { data: editEmergencyRoleInitData } = useQuery(editEmergencyRoleInitQuery(emergency_role_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editEmergencyRoleInitData) {
      setEmergencyRole(editEmergencyRoleInitData.emergency_roles_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [editEmergencyRoleInitData]);

  const handleFinish = () => {
    history.push(`/admin/trusts/${organisation_id}/emergency_roles`);
  };

  return (
    <>
      <Dashboard
        breadcrumbs={[
          { label: 'Trusts', link: '/admin/trusts' },
          { label: emergencyRole.organisation.name, link: `/admin/trusts/${organisation_id}` },
          { label: 'Emergency roles', link: `/admin/trusts/${organisation_id}/emergency_roles` },
          { label: emergencyRole.name },
          { label: 'Edit' },
        ]}>
        <EditEmergencyRole organisation_id={organisation_id} emergency_role_id={emergency_role_id} handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(withStyles(styles)(EditEmergencyRoleAdmin));
