import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import {} from '@mui/material';

import Dashboard from '../../../../layouts/Dashboard';
import EditEmergency from '../../../../components/Global/Emergencies/Edit';

import { editEmergencyInitQuery, Emergency } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  emergency_id: string;
}

const EditEmergencyOrg = ({ classes, history }: Props): React.ReactElement => {
  const { emergency_id } = useParams<UrlParams>();

  const [emergency, setEmergency] = useState<Emergency>({
    name: '',
  });

  const { data: editEmergencyInitData } = useQuery(editEmergencyInitQuery(emergency_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editEmergencyInitData) {
      setEmergency(editEmergencyInitData.emergencies_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [editEmergencyInitData]);

  const handleFinish = () => {
    history.push(`/trust/emergencies/${emergency_id}`);
  };

  return (
    <>
      <Dashboard breadcrumbs={[{ label: 'Emergency', link: `/trust/emergencies` }, { label: emergency.name }, { label: 'Edit' }]}>
        <EditEmergency emergency_id={emergency_id} handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(withStyles(styles)(EditEmergencyOrg));
