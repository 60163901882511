import React, { useState, useEffect, useReducer } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { UnknownObject } from '../../../../react-app-env';

import {
} from '@mui/material';

import CustomBackdrop from '../../../../components/CustomBackdrop';

import { emergencyReducer, EmergencyReducerAction, Category, CategoryAssignment, EmergencyRoleAssignment, EmergencyRole } from '../Common';
import Steps from '../Common/components/Steps';

import { editEmergencyInitQuery, Organisation } from './queries';
import { updateAssignments, updateEmergency } from './mutations';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
  organisation_id?: string,
  emergency_id: string,
  handleFinish: () => void,
}

const EditEmergency = ({ classes, history, organisation_id, emergency_id, handleFinish }: Props): React.ReactElement => {

  const [emergency, dispatchEmergency] = useReducer(emergencyReducer, {
    name: '',
    description: '',
    categories: [],
    roles: [],
  });

  const [organisation, setOrganisation] = useState<Organisation>({
    categories: [],
    emergency_roles: [],
  });

  const [assignedCategories, setAssignedCategories] = useState<CategoryAssignment[]>([]);
  const [assignedRoles, setAssignedRoles] = useState<EmergencyRoleAssignment[]>([]);

  const [saving, setSaving] = useState<boolean>(false);

  const { data: editEmergencyInitData } = useQuery(editEmergencyInitQuery(emergency_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editEmergencyInitData) {
      setOrganisation(editEmergencyInitData.emergencies_by_pk.organisation);
      setAssignedCategories(editEmergencyInitData.emergencies_by_pk.category_assignments);
      setAssignedRoles(editEmergencyInitData.emergencies_by_pk.role_assignments);
      dispatchEmergency({ type: EmergencyReducerAction.INIT, value: editEmergencyInitData.emergencies_by_pk });
    }
    return () => { mounted = false; };
  }, [editEmergencyInitData]);

  const handleSave = async () => {
    setSaving(true);

    const categoryAssignments = emergency.categories
      .filter((a: Category) => !assignedCategories.some((i: CategoryAssignment) => a.id === i.category.id))
      .map((a: Category) => ({ category_id: a.id, organisation_id, emergency_id }));
    
    const categoryAssignmentsRemove = assignedCategories
      .filter((i: CategoryAssignment) => !emergency.categories.some((a: Category) => a.id === i.category.id))
      .map((i: CategoryAssignment) => i.id);

    const roleAssignments = emergency.roles
      .map((a: EmergencyRole, order_index: number) => { 
        const assignment = assignedRoles.find((i) => i.role.id === a.id);
        return {
          id: assignment ? assignment.id : undefined, 
          role_id: a.id, 
          order_index,
          organisation_id, emergency_id,
        }
      });
    
    const roleAssignmentsRemove = assignedRoles
      .filter((i: EmergencyRoleAssignment) => !emergency.roles.some((a: EmergencyRole) => a.id === i.role.id))
      .map((i: EmergencyRoleAssignment) => i.id);

    const item: UnknownObject = {
      ...emergency,
    };

    delete item.categories;
    delete item.roles;

    const mData = {
      ...item,
      organisation_id,
      description: emergency.description || undefined,
    };

    const updateVariables = {
      pk_columns: {
        id: emergency_id,
      },
      set: mData,
    };
    await updateEmergency(updateVariables);
    await updateAssignments(categoryAssignments, roleAssignments, categoryAssignmentsRemove, roleAssignmentsRemove);

    setSaving(false);
    handleFinish();
  };

  return (
    <>
      <Steps
        completeLabel="Save"
        emergency={emergency}
        categories={organisation.categories}
        roles={organisation.emergency_roles}
        dispatch={dispatchEmergency}
        handleSave={handleSave} />
      {saving && <CustomBackdrop label="Saving Changes" />}
    </>
  );
};

export default withRouter(withStyles(styles)(EditEmergency));
