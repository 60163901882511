
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _editCategoryInitQuery = (id: string) => ({
  categories_by_pk: params(
    {
      id: rawString(id),
    },
    {
      name: types.string,
      description: types.string,
      area_assignments: [{
        id: types.string,
        area: {
          id: types.string,
          name: types.string,
          department: types.string,
        },
      }],
      emergency_assignments: [{
        id: types.string,
        emergency: {
          id: types.string,
          name: types.string,
          description: types.string,
        },
      }],
      organisation: {
        areas: [{
          id: types.string,
          name: types.string,
          department: types.string,
        }],
        emergencies: [{
          id: types.string,
          name: types.string,
          description: types.string,
        }],
      },
    },
  )
});

type X = Unpacked<typeof _editCategoryInitQuery>;
export type Organisation = X['categories_by_pk']['organisation'];

export const editCategoryInitQuery = (id: string) => gql`${query(_editCategoryInitQuery(id))}`;
