import { gql } from '@apollo/client';
import { EraCareEvent } from '../Common';

export const getEraCareEventQuery = gql`
  query ($id: uuid!) {
    era_care_events_by_pk(id: $id) {
      id
      created_at
      patient_first_name
      patient_last_name
      patient_mobile_number
      patient_email
      is_tracked_by_era
      is_linked_to_emergency
      patient_gives_consent_to_contact
    }
  }
`;

export interface GetEraCareEventQueryResponse {
  era_care_events_by_pk: EraCareEvent;
}
