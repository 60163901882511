import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { Grid } from '@mui/material';

import ReadOnlyBlock from '../../../../components/ReadOnlyBlock';
import { selectAreaInitQuery, Area } from './queries';
import { GridCard } from '../../../../components/Grid';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  area_id: string;
  handleEdit: () => void;
  handleCreate: (view: string) => void;
  handleView: (view: string) => void;
}
const ViewArea = ({ classes, history, area_id, handleEdit, handleCreate, handleView }: Props): React.ReactElement => {
  const [area, setArea] = useState<Area>({
    id: '',
    name: '',
    phone: '',
    department: '',
    category_assignments: [],
    organisation: {
      name: '',
    },
    sections_aggregate: { aggregate: { count: 0 } },
    users_aggregate: { aggregate: { count: 0 } },
  });

  const { data: areaInitData } = useQuery(selectAreaInitQuery(area_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && areaInitData) {
      setArea(areaInitData.areas_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [areaInitData]);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <>
            <ReadOnlyBlock
              title={area.name}
              gridSize={3}
              items={[
                { label: 'Phone', value: area.phone || 'No phone number' },
                { label: 'Grouping', list: area.category_assignments.map((i) => i.category.name) },
              ]}
              handleEdit={handleEdit}
            />
          </>
        </Grid>
        <GridCard
          fill
          gridSize={4}
          counter={area?.users_aggregate?.aggregate.count}
          key="Ward managers"
          title="Ward managers"
          subTitle="These are users set up to specifically see this ward as well as being able to login to the bedside iPads and base stations. They are able to see metrics and run reports."
          editLabel="Create"
          handleEdit={() => handleCreate('users')}
          handleView={() => handleView('users')}
        />
        <GridCard
          fill
          gridSize={4}
          counter={area?.sections_aggregate?.aggregate.count}
          key="Wards"
          title="Wards"
          subTitle="Wards within your department."
          editLabel="Create"
          handleEdit={() => handleCreate('wards')}
          handleView={() => handleView('wards')}
        />
      </Grid>
    </>
  );
};

export default withRouter(withStyles(styles)(ViewArea));
