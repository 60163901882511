import React, { useReducer } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import styles from './styles';
import { useMutation } from '@apollo/client';
import Steps from '../Common/components/Steps';
import { questionnaireReducer } from '../Common';
import { insertQuestionnaireMutation } from './mutations';
import CustomBackdrop from '../../../../components/CustomBackdrop';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  organisationId?: string;
  handleFinish: () => void;
}

const CreateEraCareQuestionnaireComponent = ({ organisationId, handleFinish, classes, history }: Props): React.ReactElement => {
  const [questionnaire, dispatchQuestionnaire] = useReducer(questionnaireReducer, {
    name: '',
    categories: [],
  });

  const [insertQuestionnaire, { loading: saving }] = useMutation(insertQuestionnaireMutation, { onCompleted: handleFinish });

  const handleSave = async () => {
    await insertQuestionnaire({
      variables: {
        object: {
          ...questionnaire,
          ...(organisationId ? { organisation_id: organisationId } : {}),
        },
      },
    });
  };

  return (
    <>
      <Steps completeLabel="Create" questionnaire={questionnaire} dispatch={dispatchQuestionnaire} handleSave={handleSave} />
      {saving && <CustomBackdrop label="Loading" />}
    </>
  );
};

export default withRouter(withStyles(styles)(CreateEraCareQuestionnaireComponent));
