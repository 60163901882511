import React from 'react';
import { WithStyles, ClassNameMap } from '@mui/styles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { Typography, Paper, Toolbar, Button } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import styles from './styles';
import { useMutation, useQuery } from '@apollo/client';
import { GetEraCareNotificationResponse, getEraCareNotification } from './queries';
import CustomBackdrop from '../../../../components/CustomBackdrop';
import { insertEraCareNotificationDenyListItemMutation } from './mutations';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  notification_id: string;
}

const EraCareUnsubscribe = ({ classes, history }: Props): React.ReactElement => {
  const { notification_id } = useParams<UrlParams>();

  const {
    data: notificationData,
    loading,
    refetch,
  } = useQuery<GetEraCareNotificationResponse>(getEraCareNotification, { variables: { id: notification_id }, fetchPolicy: 'no-cache' });

  const [unsubscribeAsync, { loading: saving }] = useMutation(insertEraCareNotificationDenyListItemMutation, { onCompleted: refetch });

  const handleUnsubscribe = async () => {
    await unsubscribeAsync({
      variables: {
        object: {
          era_care_notification_id: notification_id,
        },
      },
    });
  };

  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.main}>
        {notificationData?.era_care_notifications_by_pk && !notificationData?.era_care_notifications_by_pk.unsubscribe ? (
          <>
            <div className={classes.toolbarRoot}>
              <Toolbar>
                <Typography className={classes.title} variant="h2">
                  {'Era-care notifications management'}
                </Typography>
              </Toolbar>
            </div>
            <main className={classes.content}>
              <Typography variant="body2" component="span">
                {'Do you want to unsubscribe from future notifications?'}
              </Typography>
              <Button variant="text" onClick={handleUnsubscribe}>
                Unsubscribe
              </Button>
            </main>
          </>
        ) : (
          <>
            <div className={classes.toolbarRoot}>
              <Toolbar>
                <Typography className={classes.title} variant="h2">
                  {'Era-care notifications management'}
                </Typography>
              </Toolbar>
            </div>
            <main className={classes.content}>
              <Typography variant="body2">{'You are not on the notifications list.'}</Typography>
            </main>
          </>
        )}
      </Paper>
      {(loading || saving) && <CustomBackdrop label="Loading" />}
    </div>
  );
};

export default withRouter(withStyles(styles)(EraCareUnsubscribe));
