import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import Dashboard from '../../../../layouts/Dashboard';
import ListEmergencyRoles from '../../../../components/Global/EmergencyRoles/List';

import { emergencyRolesInitQuery, EmergencyRole } from './queries';

import styles from './styles';
import { updateEmergencyRole } from './mutations';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

const ListEmergencyRolesOrg = ({ classes, history }: Props): React.ReactElement => {
  const [emergencyRoles, setEmergencyRoles] = useState<EmergencyRole[]>([]);

  const { data: emergencyRolesInitData, refetch } = useQuery(emergencyRolesInitQuery(), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && emergencyRolesInitData) {
      setEmergencyRoles(emergencyRolesInitData.emergency_roles);
    }
    return () => {
      mounted = false;
    };
  }, [emergencyRolesInitData]);

  const handleCreate = () => {
    history.push(`/trust/emergency_roles/create`);
  };

  const handleEdit = (id: string) => {
    history.push(`/trust/emergency_roles/${id}/edit`);
  };

  const handleActiveStatusChange = async (id: string, isActive: boolean) => {
    const updateVariables = {
      pk_columns: {
        id: id,
      },
      set: {
        active: isActive,
      },
    };
    await updateEmergencyRole(updateVariables);

    await refetch();
  };

  return (
    <Dashboard breadcrumbs={[{ label: 'Emergency roles' }]}>
      <ListEmergencyRoles items={emergencyRoles} handleCreate={handleCreate} handleEdit={handleEdit} handleActiveStatusChange={handleActiveStatusChange} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ListEmergencyRolesOrg));
