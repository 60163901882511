import React, { useState, useEffect } from 'react';
import { WithStyles, ClassNameMap } from '@mui/styles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';
import { UnknownObject } from '../../../../react-app-env';

import { useQuery } from '@apollo/client';

import { Grid } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import Dashboard from '../../../../layouts/Dashboard';
import ReadOnlyBlock from '../../../../components/ReadOnlyBlock';

import { selectAdminUserInitQuery, AdminUser } from './queries';
import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
}

interface UrlParams {
  admin_user_id: string,
}

const ViewOrganisation = ({ classes, history }: Props): React.ReactElement => {
  const { admin_user_id } = useParams<UrlParams>();

  const [user, setUser] = useState<AdminUser | UnknownObject>({});

  const { data: adminUserInitQuery } = useQuery(selectAdminUserInitQuery(admin_user_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && adminUserInitQuery) {
      const usr = adminUserInitQuery.users_admin_by_pk;
      setUser(usr);
    }
    return () => { mounted = false; };
  }, [adminUserInitQuery]);

  const handleEdit = () => {
    history.push(`/admin/users/${admin_user_id}/edit`);
  };

  return (
    <Dashboard
      breadcrumbs={[
        { label: 'Admin' },
        { label: 'Users', link: '/admin/users' },
        { label: `${user.full_name}` },
      ]}>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <>
            <ReadOnlyBlock
              items={[
                { label: 'Name', value: user.full_name },
                { label: 'Email', value: user.email },
              ]}
              handleEdit={handleEdit} />
          </>
        </Grid>
      </Grid>
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ViewOrganisation));
