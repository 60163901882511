import { alias, params, query, types } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../react-app-env';

const _homeInitQuery = () => ({
  areas_aggregate: {
    aggregate: {
      count: types.number,
    },
  },
  users_organisation_aggregate: {
    aggregate: {
      count: types.number,
    },
  },
  emergencies_aggregate: {
    aggregate: {
      count: types.number,
    },
  },
  emergency_roles_aggregate: {
    aggregate: {
      count: types.number,
    },
  },
  categories_aggregate: {
    aggregate: {
      count: types.number,
    },
  },
  events_aggregate: {
    aggregate: {
      count: types.number,
    },
  },
  questionnaires_aggregate: {
    aggregate: {
      count: types.number,
    },
  },
  [alias('missing_patients_info', 'events_aggregate')]: params(
    {
      where: {
        _and: [
          {
            _not: { patient: {} },
          },
          {
            notification_closed: { _eq: false },
          },
        ],
      },
    },
    {
      aggregate: {
        count: types.number,
      },
    }
  ),
});

export type Counters = Unpacked<typeof _homeInitQuery>;

export const homeInitQuery = () =>
  gql`
    ${query(_homeInitQuery())}
  `;
