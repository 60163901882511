import React, { useState } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Dashboard from '../../../../layouts/Dashboard';
import styles from './styles';
import { useQuery } from '@apollo/client';
import { GetEraCareEventsQueryResponse, getEraCareEventsQuery } from './queries';
import CustomSortableTable, { SortableTableAction, SortableTableHeader, SortableTableRow } from '../../../../components/CustomSortableTable';
import { AddCircle as AddCircleIcon } from '@mui/icons-material';
import { formatDate } from '../../../../utils/dateUtils';
import { booleanToYesNo } from '../../../../utils/stringUtils';
import {
  DateRangeFilteringOptions,
  EraCareEvent,
  NotificationStatus,
  dateRangeOptions,
  getEndDate,
  getNotificationProgress,
  getNotificationProgressMessage,
  getStartDate,
  notificationStatusOptions,
} from '../Common';
import { FormControl, Grid, InputLabel, LinearProgress, MenuItem, Select, SelectChangeEvent, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import CustomDatePicker from '../../../../components/CustomDatePicker';

interface Filters {
  startDate: Date;
  endDate: Date;
  startDateValidation: string | boolean;
  endDateValidation: string | boolean;
  notificationStatus: NotificationStatus;
  dateRange: DateRangeFilteringOptions;
}

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

const ListEraCareEvents = ({ classes, history }: Props): React.ReactElement => {
  const [filters, setFilters] = useState<Filters>({
    startDate: getStartDate(DateRangeFilteringOptions.TWO_MONTHS),
    endDate: getEndDate(DateRangeFilteringOptions.TWO_MONTHS),
    startDateValidation: false,
    endDateValidation: false,
    notificationStatus: NotificationStatus.ALL,
    dateRange: DateRangeFilteringOptions.TWO_MONTHS,
  });

  const { data: eraCareEvents, loading } = useQuery<GetEraCareEventsQueryResponse>(getEraCareEventsQuery, {
    variables: {
      where: {
        _and: [
          { created_at: { _gte: filters.startDate } },
          { created_at: { _lte: filters.endDate } },
          ...(filters.notificationStatus === NotificationStatus.PENDING
            ? [
                {
                  _not: { notification: {} },
                },
              ]
            : []),
          ...(filters.notificationStatus === NotificationStatus.WAITING_FOR_RESPONSE ? [{ _or: { notification: {} } }, { _not: { response: {} } }] : []),
          ...(filters.notificationStatus === NotificationStatus.COMPLETED ? [{ _or: { notification: {} } }, { _or: { response: {} } }] : []),
        ],
      },
    },
    fetchPolicy: 'no-cache',
  });

  const handleCreate = () => {
    history.push(`/department/care-events/create`);
  };

  const handleEdit = (id: string) => {
    history.push(`/department/care-events/${id}/edit`);
  };

  const headers: SortableTableHeader[] = [
    {
      key: 'created_at',
      label: 'Created At',
    },
    {
      key: 'patient_first_name',
      label: 'First Name',
    },
    {
      key: 'patient_last_name',
      label: 'Last Name',
    },
    {
      key: 'patient_mobile_number',
      label: 'Mobile',
    },
    {
      key: 'patient_email',
      label: 'Email',
    },
    {
      key: 'patient_gives_consent_to_contact',
      label: 'Consent to contact',
    },
    {
      key: 'status',
      label: '',
    },
  ];

  const renderNotificationStatus = (status: NotificationStatus) => (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <Tooltip placement="top" title={getNotificationProgressMessage(status)}>
          <LinearProgress className={classes.notificationProgressStatus} variant="determinate" value={getNotificationProgress(status)} />
        </Tooltip>
      </Grid>
    </Grid>
  );

  const getNotificationStatus = (item: EraCareEvent): NotificationStatus => {
    // if (item.notification_closed) {
    //   return NotificationStatus.CLOSED;
    // }

    if (item.notification && !item.response) {
      return NotificationStatus.WAITING_FOR_RESPONSE;
    }

    if (item.notification && item.response) {
      return NotificationStatus.COMPLETED;
    }

    return NotificationStatus.PENDING;
  };

  const rows: SortableTableRow[] =
    eraCareEvents?.era_care_events.map((e) => {
      const status = getNotificationStatus(e);
      return {
        key: e.id!,
        actions: [
          {
            label: 'Edit',
            onClick: () => handleEdit(e.id!),
          },
        ],
        columns: [
          {
            key: 'created_at',
            label: formatDate(new Date(e.created_at), 'yyyy-MM-DD'),
          },
          {
            key: 'patient_first_name',
            label: e.patient_first_name,
          },
          {
            key: 'patient_last_name',
            label: e.patient_last_name,
          },
          {
            key: 'patient_mobile_number',
            label: e.patient_mobile_number ?? '',
          },
          {
            key: 'patient_email',
            label: e.patient_email ?? '',
          },
          {
            key: 'patient_gives_consent_to_contact',
            label: booleanToYesNo(e.patient_gives_consent_to_contact),
          },
          {
            key: 'status',
            label: '',
            component: renderNotificationStatus(status),
          },
        ],
      };
    }) ?? [];

  const actions: SortableTableAction[] = [
    {
      key: 'create',
      label: 'Create',
      icon: <AddCircleIcon />,
      onClick: () => handleCreate(),
    },
  ];

  const onDateRangeChanged = (value: string | null) => {
    const selectedOption = (value as DateRangeFilteringOptions) || DateRangeFilteringOptions.TWO_MONTHS;

    setFilters((prev) => ({
      ...prev,
      dateRange: selectedOption,
      startDate: getStartDate(selectedOption, prev.startDate),
      endDate: getEndDate(selectedOption, prev.endDate),
    }));
  };

  const onStartDateChanged = (startDate: Date) => {
    if (startDate > filters.endDate) {
      setFilters((prev) => ({ ...prev, startDateValidation: 'Start date must be before end date' }));
    } else {
      setFilters((prev) => ({ ...prev, startDate: startDate, startDateValidation: false }));
    }
  };

  const onEndDateChanged = (endDate: Date) => {
    if (endDate < filters.startDate) {
      setFilters((prev) => ({ ...prev, endDateValidation: 'End date must be after start date' }));
    } else {
      setFilters((prev) => ({ ...prev, endDate: endDate, endDateValidation: false }));
    }
  };

  return (
    <Dashboard breadcrumbs={[{ label: 'ERA Care Events' }]}>
      <Grid container spacing={4}>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel id="notification-status-select-label">Event notification status</InputLabel>
            <Select
              labelId="notification-status-select-label"
              id="notification-status-select"
              value={filters.notificationStatus}
              label="Report notification status"
              onChange={(event: SelectChangeEvent) => setFilters((prev) => ({ ...prev, notificationStatus: event.target.value as NotificationStatus }))}>
              {notificationStatusOptions.map((option) => (
                <MenuItem value={option}>{getNotificationProgressMessage(option)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sx={{ height: '120px' }}>
          <ToggleButtonGroup size="large" value={filters.dateRange} exclusive color="primary" onChange={(_: unknown, value: string | null) => onDateRangeChanged(value)}>
            {dateRangeOptions.map((i) => (
              <ToggleButton key={i.key} value={i.key}>
                {i.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Grid>
        {filters.dateRange === DateRangeFilteringOptions.CUSTOM && (
          <>
            <Grid item>
              <CustomDatePicker
                label="Start date"
                date={filters.startDate}
                error={filters.startDateValidation as boolean}
                helperText={filters.startDateValidation !== false ? (filters.startDateValidation as string) : undefined}
                handleDateChange={(date) => onStartDateChanged(date as Date)}
              />
            </Grid>
            <Grid item>
              <CustomDatePicker
                label="End date"
                date={filters.endDate}
                error={filters.endDateValidation as boolean}
                helperText={filters.endDateValidation !== false ? (filters.endDateValidation as string) : undefined}
                handleDateChange={(date) => onEndDateChanged(date as Date)}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <CustomSortableTable title="Events" orderdBy="name" ordered="asc" actions={actions} headers={headers} counter={rows.length} rows={rows} loading={loading} />
        </Grid>
      </Grid>
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ListEraCareEvents));
