import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import Dashboard from '../../../../../../layouts/Dashboard';
import ListStations from '../../../../../../components/Global/Areas/Sections/Stations/List';

import { stationsInitQuery, Section } from './queries';

import styles from './styles';
import { updateAreaSectionStation } from './mutations';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  area_id: string;
  section_id: string;
}

const ListStationsOrg = ({ classes, history }: Props): React.ReactElement => {
  const { area_id, section_id } = useParams<UrlParams>();

  const [section, setSection] = useState<Section>({
    name: '',
    area: {
      name: '',
    },
    stations: [],
  });

  const { data: stationsInitData, refetch } = useQuery(stationsInitQuery(section_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && stationsInitData) {
      setSection(stationsInitData.area_sections_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [stationsInitData]);

  const handleCreate = () => {
    history.push(`/trust/departments/${area_id}/wards/${section_id}/bed-spaces/create`);
  };

  const handleEdit = (id: string) => {
    history.push(`/trust/departments/${area_id}/wards/${section_id}/bed-spaces/${id}/edit`);
  };

  const handleActiveStatusChange = async (id: string, isActive: boolean) => {
    const updateVariables = {
      pk_columns: {
        id: id,
      },
      set: {
        active: isActive,
      },
    };
    await updateAreaSectionStation(updateVariables);

    await refetch();
  };

  return (
    <Dashboard
      breadcrumbs={[
        { label: 'Departments', link: `/trust/departments` },
        { label: section.area.name, link: `/trust/departments/${area_id}` },
        { label: 'Wards', link: `/trust/departments/${area_id}/wards` },
        { label: section.name, link: `/trust/departments/${area_id}/wards/${section_id}` },
        { label: 'Bed spaces' },
      ]}>
      <ListStations section_id={section_id} items={section.stations} handleEdit={handleEdit} handleCreate={handleCreate} handleActiveStatusChange={handleActiveStatusChange} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ListStationsOrg));
