import { gql } from '@apollo/client';

export const deleteOrganisationUserMutation = gql`
  mutation ($user_id: uuid!) {
    delete_users_organisation_by_pk(id: $user_id) {
      id
    }
  }
`;

export interface DeleteOrganisationUserResponse {
  delete_users_organisation_by_pk: {
    id: string;
  };
}

export const insertOrganisationUserMutation = gql`
  mutation ($object: users_organisation_insert_input!) {
    insert_users_organisation_one(object: $object) {
      id
    }
  }
`;

export interface InsertOrganisationUserResponse {
  insert_users_organisation_one: {
    id: string;
  };
}
