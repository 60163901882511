import { query, params, types } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _areasInitQuery = () => ({
  areas: params({ order_by: { name: 'asc' } }, [
    {
      id: types.string,
      name: types.string,
      phone: types.string,
      department: types.string,
      category_assignments: params(
        {
          where: { category: { active: { _eq: true } } },
        },
        [
          {
            category: {
              id: types.string,
              name: types.string,
              description: types.string,
            },
          },
        ]
      ),
      sections: params(
        {
          where: { active: { _eq: true } },
        },
        [
          {
            id: types.string,
            name: types.string,
            phone: types.string,
          },
        ]
      ),
      users_aggregate: {
        aggregate: {
          count: types.number,
        },
      },
      active: types.boolean,
    },
  ]),
});

type X = Unpacked<typeof _areasInitQuery>;
export type Area = X['areas'][0];

export const areasInitQuery = () =>
  gql`
    ${query(_areasInitQuery())}
  `;
