import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const updateEmergencyRoleMutation = gql`
  mutation ($pk_columns: emergency_roles_pk_columns_input!, $set: emergency_roles_set_input!) {
    update_emergency_roles_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

export const updateEmergencyRole = async (variables: object) => {
  return await gqlClient.mutate<{
    update_emergency_roles_by_pk: {
      id: string,
    }
  }>({ mutation: updateEmergencyRoleMutation!, variables });
}

const updateEmergencyAssignmentsMutation = gql`
  mutation ($assignments: [emergency_role_assignments_insert_input!]!, $remove: [uuid!]!) {
    insert_emergency_role_assignments(objects: $assignments) {
      returning {
        id
      }
    }
    delete_emergency_role_assignments(where: { id: { _in: $remove }}) {
      affected_rows
    }
  }
`;

export const updateEmergencyAssignments = async (assignments: object[], remove: string[]) => {
  return await gqlClient.mutate<{
    insert_emergency_role_assignments: {
      returning: [{
        id: string,
      }],
    }
    delete_emergency_role_assignments: {
      affected_rows: number
    }
  }>({ mutation: updateEmergencyAssignmentsMutation!, variables: { assignments, remove } });
}