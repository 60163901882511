import { types, query } from 'typed-graphqlify';
import { gql } from '@apollo/client';

const _createOrganisationUserInitQuery = () => ({
  enum_organisation_user_types: [
    {
      user_type: types.string,
      description: types.string,
    },
  ],
});

export const createOrganisationUserInitQuery = () =>
  gql`
    ${query(_createOrganisationUserInitQuery())}
  `;

export const getUsersCountQuery = gql`
  query ($email: String!) {
    users_admin_aggregate(where: { email: { _eq: $email } }) {
      aggregate {
        count
      }
    }
    users_organisation_aggregate(where: { email: { _eq: $email } }) {
      aggregate {
        count
      }
    }
  }
`;

export interface GetUsersCountRequest {
  email: string;
}

export interface GetUsersCountResponse {
  users_admin_aggregate: {
    aggregate: {
      count: number;
    };
  };
  users_organisation_aggregate: {
    aggregate: {
      count: number;
    };
  };
}
