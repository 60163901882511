
import { query, params, types, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../react-app-env';

const _areasInitQuery = (id: string) => ({
  organisations_by_pk: params(
    {
      id: rawString(id),
    },
    {
      name: types.string,
      categories: [{
        id: types.string,
        name: types.string,
      }],
    },
  )
});

type X = Unpacked<typeof _areasInitQuery>;
export type Organisation = X['organisations_by_pk'];

export const areasInitQuery = (id: string) => gql`${query(_areasInitQuery(id))}`;
