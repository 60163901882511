import React, { useState, useEffect } from 'react';

import { GridCard } from '../../../Grid';
import { Grid, Typography } from '@mui/material';
import { dateDiffFromString } from '../../../../utils/dateUtils';
import { Event, EventRole } from '../List';

type TopValueTotal = {
  id: string;
  name: string;
  total: number;
};

interface Values {
  average_role_time: number;
  total_events: number;
  top_events: TopValue[];
  top_stations: TopValue[];
}

interface TopValue {
  label: string;
  value: number;
}

interface Props {
  events: Event[];
  loading: boolean;
}

const mergeEvents = (events: Event[]) => {
  const items: TopValueTotal[] = [];
  events.forEach((i: Event) => {
    let item = items.find((a: TopValueTotal) => a.id === i.emergency.name);
    if (!item) {
      item = { id: i.emergency.name, name: i.emergency.name, total: 0 };
      items.push(item);
    }
    item.total++;
  });
  items.sort((a, b) => b.total - a.total);
  return items;
};

const mergeStations = (events: Event[]) => {
  const items: TopValueTotal[] = [];
  events.forEach((i: Event) => {
    let item = items.find((a: TopValueTotal) => a.id === i.station.id);
    if (!item) {
      item = { id: i.station.id, name: i.station.name, total: 0 };
      items.push(item);
    }
    item.total++;
  });
  items.sort((a, b) => b.total - a.total);
  return items;
};

const MetricEvents = ({ events, loading = false }: Props): React.ReactElement => {
  const [value, setValue] = useState<Values>({
    average_role_time: 0,
    total_events: 0,
    top_events: [],
    top_stations: [],
  });

  useEffect(() => {
    let mounted = true;
    if (mounted && events) {
      const allRoles = events.flatMap((i: Event) => i.roles);
      const total_events = events.length;
      const average_role_time1 = allRoles.reduce((a: number, b: EventRole) => a + dateDiffFromString(b.updated_at, b.created_at), 0);
      const average_role_time2 = total_events > 0 ? average_role_time1 / total_events : 0;
      const average_role_time = Math.round(average_role_time2);

      const topEvents = mergeEvents(events);
      const topStations = mergeStations(events);

      setValue({
        total_events,
        average_role_time,
        top_events: [...topEvents].splice(0, 5).map((i) => ({ label: i.name, value: i.total })),
        top_stations: [...topStations].splice(0, 5).map((i) => ({ label: i.name, value: i.total })),
      });
    }
    return () => {
      mounted = false;
    };
  }, [events]);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">Reports</Typography>
      </Grid>
      <GridCard fill gridSize={6} loading={loading} key="total_events" title="Total reports" counterSmall counter={value.total_events} />
      <GridCard
        fill
        gridSize={6}
        loading={loading}
        key="average_role_time"
        title="Average role time"
        description="Average time in seconds between the start of an event and the time to accept a role"
        counterSmall
        counterLabel="Seconds"
        counter={value.average_role_time}
      />
      <GridCard
        fill
        gridSize={6}
        loading={loading}
        key="top_events"
        title="Most common reports"
        counterSmall
        components={value.top_events.map((i) => (
          <li>{`${i.label} - ${i.value}`}</li>
        ))}
      />
      <GridCard
        fill
        gridSize={6}
        loading={loading}
        key="top_stations"
        title="Most common stations"
        counterSmall
        components={value.top_stations.map((i) => (
          <li>{`${i.label} - ${i.value}`}</li>
        ))}
      />
    </>
  );
};

export default MetricEvents;
