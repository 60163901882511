import React, { useCallback, useState } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import CustomStepper from '../../../../../../components/CustomStepper';
import { EraCareEvent, ValidationErrors, EraCareEventReducerAction, validation, validationErrors } from '../../../Common';
import StepDetails from '../StepDetails';
import StepReview from '../StepReview';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  completeLabel: string;
  eraCareEvent: EraCareEvent;
  dispatch: React.Dispatch<{ type: EraCareEventReducerAction; value: any }>;
  handleSave: () => void;
}

const QuestionnaireSteps = ({ classes, completeLabel, eraCareEvent, dispatch, handleSave }: Props): React.ReactElement => {
  const [step, setStep] = useState<number>(0);

  const [errors, setErrors] = useState<ValidationErrors>({
    created_at: false,
    patient_first_name: false,
    patient_last_name: false,
    patient_mobile_number: false,
    patient_email: false,
  });

  const validate = useCallback(
    (_step: number) => {
      const errorItems: UnknownObject = {};

      if (_step === 0) {
        errorItems.created_at = validation.created_at(eraCareEvent.created_at);
        errorItems.patient_first_name = validation.patient_first_name(eraCareEvent.patient_first_name);
        errorItems.patient_last_name = validation.patient_last_name(eraCareEvent.patient_last_name);
        errorItems.patient_mobile_number = validation.patient_mobile_number(eraCareEvent.patient_mobile_number, eraCareEvent.patient_email);
        errorItems.patient_email = validation.patient_email(eraCareEvent.patient_email, eraCareEvent.patient_mobile_number);
      }

      setErrors({ ...errors, ...errorItems });

      return (Object.keys(errorItems) as Array<keyof typeof validationErrors>).some((i) => errorItems[i]);
    },
    [eraCareEvent, errors]
  );

  return (
    <>
      <CustomStepper
        step={step}
        isNextDisabled={(Object.keys(errors) as Array<keyof typeof errors>).some((i) => errors[i])}
        completeLabel={completeLabel}
        gridSize={6}
        setStep={setStep}
        validate={validate}
        handleComplete={handleSave}>
        {[
          {
            label: 'Details',
            component: <StepDetails eraCareEvent={eraCareEvent} errors={errors} setErrors={setErrors} dispatch={dispatch} />,
          },
          {
            label: 'Summary',
            component: <StepReview eraCareEvent={eraCareEvent} setStep={setStep} />,
          },
        ]}
      </CustomStepper>
    </>
  );
};

export default withStyles(styles)(QuestionnaireSteps);
