import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useLocalStorage from '../../../../hooks/useLocalStorage';

import {
  Divider,
  Tooltip,
  Paper,
} from '@mui/material';

import { WithStyles, withStyles } from '@mui/styles';

import {
  ChevronLeftOutlined as CollapseIcon,
  ChevronRightOutlined as ExpandIcon,
} from '@mui/icons-material';

import SidebarItemsList, { ListItemRoles } from './components/SidebarItemsList';
import { SettingsMenu } from './components';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  userRole: string,
  listItemRoles: ListItemRoles,
  signOut: () => Promise<void>,
}

const Sidebar = ({ classes, history, listItemRoles, userRole, signOut }: Props): React.ReactElement => {
  const [isExpanded, setIsExpanded] = useLocalStorage<boolean>('sidebar-expanded', true);

  const handleExpandToggle = () => {
    setIsExpanded((i) => !i);
  };

  const listItemRole = listItemRoles[userRole as keyof typeof listItemRoles] || undefined;
  const roleItems = listItemRole;

  return (
    <nav className={classes.root}>
      <Paper
        square
        elevation={0}
        className={`${classes.container} ${isExpanded ? classes.containerExpand : classes.containerCollapse}`}>
          <div className={classes.listContainer}>
            <SidebarItemsList listItems={roleItems?.items || []} isExpanded={isExpanded} />
          </div>
          <SettingsMenu signOut={signOut} isExpanded={isExpanded} />
      </Paper>
      <div className={classes.expandContainer} onClick={handleExpandToggle}>
        <Divider orientation="vertical" className={classes.expandDivider} />
        <Tooltip title={isExpanded ? 'Collapse' : 'Expand'} placement="bottom">
          <div className={classes.expandToggle}>
            {isExpanded && <CollapseIcon />}
            {!isExpanded && <ExpandIcon />}
          </div>
        </Tooltip>
      </div>
    </nav>
  );
};

export default withRouter(withStyles(styles)(Sidebar));
