import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import {} from '@mui/material';

import Dashboard from '../../../../layouts/Dashboard';

import CreateOrganisationUser from '../../../../components/Global/OrganisationUsers/Create';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

const CreateOrganisationUserOrg = ({ classes, history }: Props): React.ReactElement => {
  const handleFinish = (id: string) => {
    history.push(`/trust/users/${id}`);
  };

  return (
    <>
      <Dashboard breadcrumbs={[{ label: 'Users', link: `/trust/users` }, { label: 'Create' }]}>
        <CreateOrganisationUser handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(withStyles(styles)(CreateOrganisationUserOrg));
