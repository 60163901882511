import React from 'react';
import { WithStyles, ClassNameMap } from '@mui/styles';

import { Backdrop, CircularProgress, Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  label: string,
}

const CustomBackdrop = ({ classes, label }: Props): React.ReactElement => {
  return (
    <Backdrop className={classes.root} open >
      <CircularProgress />
      <Typography className={classes.label} variant="subtitle1">{label}</Typography>
    </Backdrop>
  );
};

export default withStyles(styles)(CustomBackdrop);
