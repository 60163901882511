const spacing = 8;

export interface ThemeModes {
  primary: ThemeMainDefaults,
  secondary: ThemeMainDefaults,
  dark: ThemeDefaults,
  light: ThemeDefaults,
}

interface ThemeMainDefaults {
  main: string,
  light: string,
  contrastText: string,
}

interface ThemeDefaults {
  background: {
    paper: string,
    default: string,
  },
  text: {
    primary: string,
    secondary: string,
  }
}

const theme = (darkMode: boolean, modes: ThemeModes) => ({
  spacing,
  palette: {
    mode: darkMode ? 'dark' : 'light',
    type: darkMode ? 'dark' : 'light',
    primary: {
      main: modes.primary.main,
      light: modes.primary.light,
      contrastText: modes.primary.contrastText,
    },
    primaryVariant: {
      main: modes.primary.light,
      contrastText: modes.primary.contrastText,
    },
    secondary: {
      main: modes.secondary.main,
      contrastText: modes.secondary.contrastText,
    },
    secondaryVariant: {
      main: modes.secondary.light,
      contrastText: modes.secondary.contrastText,
    },
    neutral: {
      main: '#BDBDBD',
      contrastText: '#FFFFFF',
    },
    surface: {
      main: '#FFFFFF',
      contrastText: '#1F384D',
    },
    listItem: {
      main: '#1F384D1F',
      contrastText: modes.primary.main,
    },
    text: {
      primary: darkMode ? modes.dark.text.primary : modes.light.text.primary,
      secondary: darkMode ? modes.dark.text.secondary : modes.light.text.secondary,
      disabled: '#B4B4B4D2',
    },
    background: {
      paper: darkMode ? modes.dark.background.paper : modes.light.background.paper,
      default: darkMode ? modes.dark.background.default : modes.light.background.default,
    }
  },
  components: {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          borderRadius: spacing,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          borderRadius: spacing,
        },
      },
    },
    MuiGrid: {
      spacing: 4,
    },
    MuiTab: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: spacing,
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          borderRadius: spacing,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiIconButton: {
      style: {
        borderRadius: '50%',
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiToggleButton: {
      style: {
        '&:not:(first-of-type):not(:last-of-type)': {
          borderRadius: 0,
        },
        '&:first-of-type': {
          borderTopLeftRadius: `${spacing}px !important`,
          borderBottomLeftRadius: `${spacing}px !important`,
        },
        '&:last-of-type': {
          borderTopRightRadius: `${spacing}px !important`,
          borderBottomRightRadius: `${spacing}px !important`,
        },
      },
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
      }
    },
    MuiInputBase: {
      input: {
        '&:-webkit-autofill': {
          caretColor: 'none !important',
          '-webkit-box-shadow': `0 0 0 100px ${darkMode ? modes.dark.background.paper : modes.light.background.paper} inset !important`,
          '-webkit-text-fill-color': `${darkMode ? '#FFFFFF' : '#000000DE'} !important`,
        },
      },
    },
  },
  typography: {
    fontFamily: 'Roboto, Nunito, Arial',
    h1: {
      fontFamily: 'Roboto',
      fontSize: 98,
      fontWeight: 300,
      fontStyle: 'normal',
      letterSpacing: -0.015625,
    },
    h2: {
      fontFamily: 'Roboto',
      fontSize: 62,
      fontWeight: 300,
      fontStyle: 'normal',
      letterSpacing: -0.008333334,
    },
    h3: {
      fontFamily: 'Roboto',
      fontSize: 50,
      fontWeight: 400,
      fontStyle: 'normal',
      letterSpacing: 0.0,
    },
    h4: {
      fontFamily: 'Roboto',
      fontSize: 36,
      fontWeight: 400,
      fontStyle: 'normal',
      letterSpacing: 0.007352941,
    },
    h5: {
      fontFamily: 'Roboto',
      fontSize: 26,
      fontWeight: 500,
      fontStyle: 'normal',
      letterSpacing: 0.0,
    },
    h6: {
      fontFamily: 'Roboto',
      fontSize: 22,
      fontWeight: 500,
      fontStyle: 'normal',
      letterSpacing: 0.0125,
    },
    subtitle1: {
      fontFamily: 'Roboto',
      fontSize: 18,
      fontWeight: 400,
      fontStyle: 'normal',
      letterSpacing: 0.009375,
    },
    subtitle2: {
      fontFamily: 'Roboto',
      fontSize: 15,
      fontWeight: 400,
      fontStyle: 'normal',
      letterSpacing: 0.007142857,
    },
    body1: {
      fontFamily: 'Nunito',
      fontSize: 18,
      fontWeight: 400,
      fontStyle: 'normal',
      letterSpacing: 0.03125,
    },
    body2: {
      fontFamily: 'Nunito',
      fontSize: 16,
      fontWeight: 400,
      fontStyle: 'normal',
      letterSpacing: 0.017857144,
    },
    button: {
      fontFamily: 'Roboto',
      fontSize: 15,
      fontWeight: 600,
      fontStyle: 'normal',
      letterSpacing: 0.08928572,
    },
    caption: {
      fontFamily: 'Roboto',
      fontSize: 14,
      fontWeight: 500,
      fontStyle: 'normal',
      letterSpacing: 0.033333335,
    },
    overline: {
      fontName: 'Roboto',
      fontSize: 12,
      fontWeight: 500,
      fontStyle: 'normal',
      letterSpacing: 0.16666667,
    },
  },
});

export default theme;
