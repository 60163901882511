
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../react-app-env';

const _selectEmergencyRoleInitQuery = (id: string) => ({
  emergencies_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
      organisation: {
        name: types.string,
      },
    }
  ),
});

type X = Unpacked<typeof _selectEmergencyRoleInitQuery>
export type Emergency = X['emergencies_by_pk'];

export const selectEmergencyRoleInitQuery = (id: string) => gql`${query(_selectEmergencyRoleInitQuery(id))}`;
