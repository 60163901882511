import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import Dashboard from '../../../../layouts/Dashboard';
import ListEvents from '../../../../components/Global/Events/List';

import { selectOrganisationUsersInitQuery, Organisation, Section, Emergency } from './queries';
import styles from './styles';
import { Filters } from '../../../../components/Global/Events/List/FiltersPane';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  organisation_id: string;
}

interface State {
  organisation: Organisation;
  sections: Section[];
  emergencies: Emergency[];
}

const ListOrganisationEventsAdmin = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id } = useParams<UrlParams>();

  const [pageState, setPageState] = useState<State>({
    organisation: {
      name: '',
      areas: [],
    },
    sections: [],
    emergencies: [],
  });

  const [filters, setFilters] = useState<Filters>({ areas: [], emergencies: [], sections: [] });

  const { data: organisationUserInitQuery } = useQuery(selectOrganisationUsersInitQuery(organisation_id, filters.areas), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && organisationUserInitQuery) {
      setPageState({
        organisation: organisationUserInitQuery.organisations_by_pk,
        sections: organisationUserInitQuery.area_sections,
        emergencies: organisationUserInitQuery.emergencies,
      });
    }
    return () => {
      mounted = false;
    };
  }, [organisationUserInitQuery]);

  return (
    <Dashboard breadcrumbs={[{ label: 'Trusts', link: '/admin/trusts' }, { label: pageState.organisation.name, link: `/admin/trusts/${organisation_id}` }, { label: 'Reports' }]}>
      <ListEvents
        organisationId={organisation_id}
        filterOptions={{ areas: pageState.organisation.areas, sections: pageState.sections, emergencies: pageState.emergencies }}
        filters={filters}
        setFilters={setFilters}
      />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ListOrganisationEventsAdmin));
