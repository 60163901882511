import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import {} from '@mui/material';

import Dashboard from '../../../.././layouts/Dashboard';
import EditOrganisationUser from '../../../.././components/Global/OrganisationUsers/Edit';

import styles from './styles';
import { editOrganisationUserInitQuery, OrganisationUser } from './queries';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  organisation_user_id: string;
}

const EditOrganisationUserOrg = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_user_id } = useParams<UrlParams>();

  const [user, setUser] = useState<OrganisationUser>({
    full_name: '',
  });

  const { data: editOrganisationUserInitQueryData } = useQuery(editOrganisationUserInitQuery(organisation_user_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editOrganisationUserInitQueryData) {
      setUser(editOrganisationUserInitQueryData.users_organisation_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [editOrganisationUserInitQueryData]);

  const handleFinish = () => {
    history.push(`/trust/users/${organisation_user_id}`);
  };

  return (
    <>
      <Dashboard breadcrumbs={[{ label: 'Users', link: `/trust/users` }, { label: user.full_name, link: `/trust/users/${organisation_user_id}` }, { label: 'Edit' }]}>
        <EditOrganisationUser organisation_user_id={organisation_user_id} handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(withStyles(styles)(EditOrganisationUserOrg));
