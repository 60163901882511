import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';
import Dashboard from '../../../../../layouts/Dashboard';
import styles from './styles';
import { useQuery } from '@apollo/client';
import { GetOrganisationResponse, getOrganisation } from './queries';
import CustomBackdrop from '../../../../../components/CustomBackdrop';
import CreateEraCareQuestionnaireComponent from '../../../../../components/Global/EraCareQuestionnaire/Create';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  organisation_id: string;
}

const CreateEraCareQuestionnaireAdmin = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id } = useParams<UrlParams>();

  const handleFinish = () => {
    history.push(`/admin/trusts/${organisation_id}/care-questionnaires`);
  };

  const { data: organisationInitQuery, loading } = useQuery<GetOrganisationResponse>(getOrganisation, { variables: { id: organisation_id }, fetchPolicy: 'no-cache' });

  return (
    <Dashboard
      breadcrumbs={[
        { label: 'Trusts', link: '/admin/trusts' },
        { label: organisationInitQuery?.organisations_by_pk.name ?? '', link: `/admin/trusts/${organisation_id}` },
        { label: 'ERA Care Questionnaire', link: `/admin/trusts/${organisation_id}/care-questionnaires` },
        { label: 'Create' },
      ]}>
      <CreateEraCareQuestionnaireComponent organisationId={organisation_id} handleFinish={handleFinish} />
      {loading && <CustomBackdrop label="Loading" />}
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(CreateEraCareQuestionnaireAdmin));
