import React, { useState } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';

import { AddCircle as AddCircleIcon } from '@mui/icons-material';

import CustomSortableTable, { SortableTableAction, SortableTableFilter, SortableTableHeader, SortableTableRow } from '../../../../../components/CustomSortableTable';

import { SectionItem } from '../Common';

import styles from './styles';
import CustomDialog from '../../../../CustomDialog';
import { booleanToYesNo } from '../../../../../utils/stringUtils';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  items: SectionItem[];
  handleCreate: () => void;
  handleView: (id: string) => void;
  handleActiveStatusChange: (id: string, isActive: boolean) => Promise<void>;
}

const ListSections = ({ classes, history, items, handleCreate, handleView, handleActiveStatusChange }: Props): React.ReactElement => {
  const [activeFiltered, setActiveFiltered] = useState<boolean>(true);
  const [recordStatusState, setRecordStatusState] = useState<{
    dialogOpen: boolean;
    sectionId: string;
    isActive: boolean;
    isLoading: boolean;
  }>({
    dialogOpen: false,
    sectionId: '',
    isActive: false,
    isLoading: false,
  });

  const deactivateSection = async () => {
    setRecordStatusState((prev) => ({ ...prev, isLoading: true }));
    await handleActiveStatusChange(recordStatusState.sectionId, recordStatusState.isActive);
    setRecordStatusState((prev) => ({ ...prev, isLoading: false, dialogOpen: false }));
  };

  const headers: SortableTableHeader[] = [
    { key: 'name', label: 'Name' },
    { key: 'phone', label: 'Phone' },
    { key: 'stations', label: 'Bed spaces', align: 'center' },
    { key: 'active', label: 'Active' },
  ];

  const rows: SortableTableRow[] = items.map((section: SectionItem) => ({
    key: section.id,
    actions: [
      {
        label: section.active ? 'Deactivate' : 'Activate',
        destructive: section.active,
        onClick: () => setRecordStatusState((prev) => ({ ...prev, sectionId: section.id, isActive: !section.active, dialogOpen: true })),
      },
      {
        label: 'View',
        onClick: () => handleView(section.id),
      },
    ],
    columns: [
      {
        key: 'name',
        label: section.name,
      },
      {
        key: 'phone',
        label: section.phone,
      },
      {
        key: 'stations',
        label: section.stations_aggregate.aggregate.count,
      },
      {
        key: 'active',
        label: booleanToYesNo(section.active),
      },
    ],
  }));

  const actions: SortableTableAction[] = [
    {
      key: 'create',
      label: 'Create ward',
      icon: <AddCircleIcon />,
      onClick: () => handleCreate(),
    },
  ];

  const activeFilter: SortableTableFilter = {
    key: 'activeFilter',
    label: 'Active',
    filter: (rows: SortableTableRow[]) =>
      rows.filter((r) => {
        const col = r.columns.find((c) => c.key === 'active');
        if (col) {
          return activeFiltered ? col.label === 'Yes' : col.label === 'No';
        }
        return true;
      }),
    component: (
      <ToggleButtonGroup className={classes.activeFilter} size="medium" value={[activeFiltered]} color="primary" onChange={() => setActiveFiltered(!activeFiltered)}>
        <ToggleButton className={classes.toggleButton} key="active" value={true}>
          Active
        </ToggleButton>
        <ToggleButton className={classes.toggleButton} key="inactive" value={false}>
          Inactive
        </ToggleButton>
      </ToggleButtonGroup>
    ),
  };

  const filters = [activeFilter];

  const filterRows = (): SortableTableRow[] => {
    let filteredRows: SortableTableRow[] = rows;
    filters.forEach((f) => {
      filteredRows = f.filter(filteredRows);
    });
    return filteredRows;
  };

  return (
    <>
      <Grid container spacing={4} alignItems="stretch">
        <Grid item xs={12}>
          <CustomSortableTable title="Wards" orderdBy="name" ordered="asc" actions={actions} headers={headers} counter={rows.length} rows={filterRows()} filters={filters} />
        </Grid>
      </Grid>
      <CustomDialog
        open={recordStatusState.dialogOpen}
        title="Update Section"
        message={`Are you sure you want to ${recordStatusState.isActive ? 'activate' : 'deactivate'} this section?`}
        handleClose={() => setRecordStatusState((prev) => ({ ...prev, dialogOpen: false }))}
        actions={[
          { label: 'No', onClick: () => setRecordStatusState((prev) => ({ ...prev, dialogOpen: false })) },
          { label: 'Yes', onClick: () => deactivateSection() },
        ]}
      />
    </>
  );
};

export default withRouter(withStyles(styles)(ListSections));
