import React, { useContext, useRef, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  ClickAwayListener,
  Divider,
  Grow,
  Paper,
  Popper,
  Switch,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material';

import {
  SettingsOutlined as SettingsIcon,
} from '@mui/icons-material';

import { WithStyles, withStyles } from '@mui/styles';

import { CustomThemeContext } from '../../../../../../components/CustomThemeProvider';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  isExpanded: boolean,
  signOut: () => Promise<void>,
}

const SettingsMenu = ({ classes, history, isExpanded, signOut }: Props): React.ReactElement => {
  const { darkMode, toggleDarkMode } = useContext(CustomThemeContext)!;

  const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState(false);
  const settingsAnchorRef = useRef<HTMLButtonElement>(null);

  const handleSettingsMenuToggle = () => {
    setIsSettingsMenuOpen(i => !i);
  };

  const handleSettingsMenuClose = () => {
    setIsSettingsMenuOpen(false);
  };

  const handleSignOut = async () => {
    await signOut();
    history.replace('/sign-in');
  };

  return (
    <div className={classes.root}>
      <Divider className={classes.listDivider} />
      <List className={classes.list} component="div" disablePadding>
        <ListItem
          onClick={handleSettingsMenuToggle}
          className={classes.listItem}>
          {isExpanded && (
            <>
              <ListItemIcon className={classes.listItemIcon} >
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText} classes={{ primary: classes.listItemText }}
                primary="Settings"
                ref={settingsAnchorRef}
                aria-controls={isSettingsMenuOpen ? 'menu-list-grow' : undefined}
                aria-haspopup="true" />
            </>
          )}
          {!isExpanded && (
            <>
              <Tooltip
                title="Settings"
                placement="right"
                ref={settingsAnchorRef}
                aria-controls={isSettingsMenuOpen ? 'menu-list-grow' : undefined}
                aria-haspopup="true">
                  <ListItemIcon className={classes.listItemIcon}>
                    <SettingsIcon />
                  </ListItemIcon>
              </Tooltip>
            </>
          )}
          <Popper
            open={isSettingsMenuOpen}
            anchorEl={settingsAnchorRef.current}
            className={classes.menu}
            role={undefined}
            placement="right-end"
            // transition
            disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                // {...TransitionProps}
                style={{ transformOrigin: placement }}>
                  <ClickAwayListener onClickAway={handleSettingsMenuClose}>
                    <Paper variant="outlined" className={classes.paper}>
                      <MenuList className={classes.menuList} autoFocusItem={isSettingsMenuOpen}>
                        <MenuItem onClick={handleSignOut}>
                          <Typography variant="inherit" noWrap>Sign out</Typography>
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={toggleDarkMode}>
                          <Typography variant="inherit" noWrap>Toggle dark mode</Typography>
                          <ListItemIcon>
                            <Switch checked={darkMode} color="primary" />
                          </ListItemIcon>
                        </MenuItem>
                      </MenuList>
                    </Paper>
                  </ClickAwayListener>
              </Grow>
            )}
          </Popper>
        </ListItem>
      </List>
    </div>
  );
};

export default withRouter(withStyles(styles)(SettingsMenu));
