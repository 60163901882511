import { capitaliseFirstOnly } from '../../../../utils/stringUtils';
import { ValidationType } from '../../../../react-app-env';
import { validateEmailAddress } from '../../../../utils/validators';

export interface Questionnaire {
  id?: string;
  name: string;
  categories: QuestionnaireCategory[];
}

export interface QuestionnaireCategory {
  name: string;
  lowThreshold: CategoryThreshold;
  mediumThreshold: CategoryThreshold;
  highThreshold: CategoryThreshold;
  questions: Question[];
}

interface CategoryThreshold {
  score: number;
  message: string;
  referralEmail: string;
}

interface Question {
  name: string;
  answers: Answer[];
}

interface Answer {
  name: string;
  score: number;
}

export interface ValidationErrors {
  name: ValidationType;
  categories: ValidationType;
  questions: { [key: number]: string | boolean };
  answers: { [key: number]: { [key: number]: string | boolean } };
  categoryName: { [key: number]: string | boolean };
  lowThresholdMessage: { [key: number]: string | boolean };
  lowThresholdScore: { [key: number]: string | boolean };
  lowThresholdEmail: { [key: number]: string | boolean };
  mediumThresholdMessage: { [key: number]: string | boolean };
  mediumThresholdScore: { [key: number]: string | boolean };
  mediumThresholdEmail: { [key: number]: string | boolean };
  hightThresholdMessage: { [key: number]: string | boolean };
  hightThresholdScore: { [key: number]: string | boolean };
  hightThresholdEmail: { [key: number]: string | boolean };
  questionName: { [key: number]: { [key: number]: string | boolean } };
  answerName: { [key: number]: { [key: number]: { [key: number]: string | boolean } } };
  answerScore: { [key: number]: { [key: number]: { [key: number]: string | boolean } } };
}

export const validationErrors = {
  name: 'Please provide questionnaire name',
  categories: 'Please create at least one category',
  questions: 'Please create at least one question',
  answers: 'Please create at least one answers',
  categoryName: 'Please provide category name',
  thresholdMessage: 'Please provide threshold information',
  thresholdScore: 'Please provide a valid threshold score',
  thresholdEmail: 'Please provide a valid email address',
  questionName: 'Please provide question name',
  answerName: 'Please provide answer name',
  answerScore: 'Please provide a valid answer score',
};

export interface Validation {
  name: (name: string) => ValidationType;
  categories: (categories: unknown[]) => ValidationType;
  questions: (questions: unknown[]) => ValidationType;
  answers: (answers: unknown[]) => ValidationType;
  categoryName: (name: string) => ValidationType;
  thresholdMessage: (msg: string) => ValidationType;
  thresholdScore: (score: number) => ValidationType;
  thresholdEmail: (score: string) => ValidationType;
  questionName: (name: string) => ValidationType;
  answerName: (name: string) => ValidationType;
  answerScore: (score: number) => ValidationType;
}

export const validation: Validation = {
  name: (name) => (!name ? validationErrors.name : false),
  categories: (categories) => (categories.length === 0 ? validationErrors.categories : false),
  questions: (questions) => (questions.length === 0 ? validationErrors.questions : false),
  answers: (answers) => (answers.length === 0 ? validationErrors.answers : false),
  categoryName: (name) => (!name ? validationErrors.categoryName : false),
  thresholdMessage: (msg) => (!msg ? validationErrors.thresholdMessage : false),
  thresholdScore: (score) => (score <= 0 ? validationErrors.thresholdScore : false),
  thresholdEmail: (email) => {
    if (email) {
      return validateEmailAddress(email) ? false : validationErrors.thresholdEmail;
    }

    return false;
  },
  questionName: (name) => (!name ? validationErrors.questionName : false),
  answerName: (name) => (!name ? validationErrors.answerName : false),
  answerScore: (score) => (score < 0 ? validationErrors.answerScore : false),
};

export enum QuestionnaireReducerAction {
  NAME,
  ADD_CATEGORY,
  SET_CATEGORY_NAME,
  SET_CATEGORY_LOW_THRESHOLD,
  SET_CATEGORY_LOW_THRESHOLD_MESSAGE,
  SET_CATEGORY_LOW_THRESHOLD_REFERRAL_EMAIL,
  SET_CATEGORY_MEDIUM_THRESHOLD,
  SET_CATEGORY_MEDIUM_THRESHOLD_MESSAGE,
  SET_CATEGORY_MEDIUM_THRESHOLD_REFERRAL_EMAIL,
  SET_CATEGORY_HIGH_THRESHOLD,
  SET_CATEGORY_HIGH_THRESHOLD_MESSAGE,
  SET_CATEGORY_HIGH_THRESHOLD_REFERRAL_EMAIL,
  DELETE_CATEGORY,
  ADD_QUESTION,
  SET_QUESTION_NAME,
  DELETE_QUESTION,
  ADD_ANSWER,
  SET_ANSWER_NAME,
  SET_ANSWER_SCORE,
  DELETE_ANSWER,
  INIT,
}

export const questionnaireReducer = (state: Questionnaire, action: { type: QuestionnaireReducerAction; value: any }): Questionnaire => {
  switch (action.type) {
    case QuestionnaireReducerAction.NAME:
      return { ...state, name: capitaliseFirstOnly(action.value) };
    case QuestionnaireReducerAction.ADD_CATEGORY:
      return {
        ...state,
        categories: [
          ...state.categories,
          {
            name: '',
            lowThreshold: {
              score: 1,
              message: '',
              referralEmail: '',
            },
            mediumThreshold: {
              score: 1,
              message: '',
              referralEmail: '',
            },
            highThreshold: {
              score: 1,
              message: '',
              referralEmail: '',
            },
            questions: [],
          },
        ],
      };
    case QuestionnaireReducerAction.SET_CATEGORY_NAME:
      return {
        ...state,
        categories: state.categories.map((item, index) => {
          if (index === action.value.index) {
            return {
              ...item,
              name: action.value.value,
            };
          }

          return item;
        }),
      };
    case QuestionnaireReducerAction.SET_CATEGORY_LOW_THRESHOLD:
      return {
        ...state,
        categories: state.categories.map((item, index) => {
          if (index === action.value.index) {
            return {
              ...item,
              lowThreshold: {
                ...item.lowThreshold,
                score: +action.value.value,
              },
            };
          }

          return item;
        }),
      };
    case QuestionnaireReducerAction.SET_CATEGORY_LOW_THRESHOLD_MESSAGE:
      return {
        ...state,
        categories: state.categories.map((item, index) => {
          if (index === action.value.index) {
            return {
              ...item,
              lowThreshold: {
                ...item.lowThreshold,
                message: action.value.value,
              },
            };
          }

          return item;
        }),
      };
    case QuestionnaireReducerAction.SET_CATEGORY_LOW_THRESHOLD_REFERRAL_EMAIL:
      return {
        ...state,
        categories: state.categories.map((item, index) => {
          if (index === action.value.index) {
            return {
              ...item,
              lowThreshold: {
                ...item.lowThreshold,
                referralEmail: action.value.value,
              },
            };
          }

          return item;
        }),
      };
    case QuestionnaireReducerAction.SET_CATEGORY_MEDIUM_THRESHOLD:
      return {
        ...state,
        categories: state.categories.map((item, index) => {
          if (index === action.value.index) {
            return {
              ...item,
              mediumThreshold: {
                ...item.mediumThreshold,
                score: +action.value.value,
              },
            };
          }

          return item;
        }),
      };
    case QuestionnaireReducerAction.SET_CATEGORY_MEDIUM_THRESHOLD_MESSAGE:
      return {
        ...state,
        categories: state.categories.map((item, index) => {
          if (index === action.value.index) {
            return {
              ...item,
              mediumThreshold: {
                ...item.mediumThreshold,
                message: action.value.value,
              },
            };
          }

          return item;
        }),
      };
    case QuestionnaireReducerAction.SET_CATEGORY_MEDIUM_THRESHOLD_REFERRAL_EMAIL:
      return {
        ...state,
        categories: state.categories.map((item, index) => {
          if (index === action.value.index) {
            return {
              ...item,
              mediumThreshold: {
                ...item.mediumThreshold,
                referralEmail: action.value.value,
              },
            };
          }

          return item;
        }),
      };
    case QuestionnaireReducerAction.SET_CATEGORY_HIGH_THRESHOLD:
      return {
        ...state,
        categories: state.categories.map((item, index) => {
          if (index === action.value.index) {
            return {
              ...item,
              highThreshold: {
                ...item.highThreshold,
                score: +action.value.value,
              },
            };
          }

          return item;
        }),
      };
    case QuestionnaireReducerAction.SET_CATEGORY_HIGH_THRESHOLD_MESSAGE:
      return {
        ...state,
        categories: state.categories.map((item, index) => {
          if (index === action.value.index) {
            return {
              ...item,
              highThreshold: {
                ...item.highThreshold,
                message: action.value.value,
              },
            };
          }

          return item;
        }),
      };
    case QuestionnaireReducerAction.SET_CATEGORY_HIGH_THRESHOLD_REFERRAL_EMAIL:
      return {
        ...state,
        categories: state.categories.map((item, index) => {
          if (index === action.value.index) {
            return {
              ...item,
              highThreshold: {
                ...item.highThreshold,
                referralEmail: action.value.value,
              },
            };
          }

          return item;
        }),
      };
    case QuestionnaireReducerAction.DELETE_CATEGORY:
      return {
        ...state,
        categories: state.categories.filter((_, index) => index !== action.value),
      };
    case QuestionnaireReducerAction.ADD_QUESTION:
      return {
        ...state,
        categories: state.categories.map((category, categoryIndex) => {
          if (categoryIndex === action.value) {
            return {
              ...category,
              questions: [
                ...category.questions,
                {
                  name: '',
                  answers: [
                    {
                      name: 'Yes',
                      score: 1,
                    },
                    {
                      name: 'No',
                      score: 0,
                    },
                  ],
                },
              ],
            };
          }

          return category;
        }),
      };
    case QuestionnaireReducerAction.SET_QUESTION_NAME:
      return {
        ...state,
        categories: state.categories.map((category, categoryIndex) => {
          if (categoryIndex === action.value.categoryIndex) {
            return {
              ...category,
              questions: category.questions.map((question, questionIndex) => {
                if (questionIndex === action.value.questionIndex) {
                  return {
                    ...question,
                    name: action.value.value,
                  };
                }

                return question;
              }),
            };
          }

          return category;
        }),
      };
    case QuestionnaireReducerAction.DELETE_QUESTION:
      return {
        ...state,
        categories: state.categories.map((category, categoryIndex) => {
          if (categoryIndex === action.value.categoryIndex) {
            return {
              ...category,
              questions: category.questions.filter((_, questionIndex) => questionIndex !== action.value.questionIndex),
            };
          }

          return category;
        }),
      };
    case QuestionnaireReducerAction.ADD_ANSWER:
      return {
        ...state,
        categories: state.categories.map((category, categoryIndex) => {
          if (categoryIndex === action.value.categoryIndex) {
            return {
              ...category,
              questions: category.questions.map((question, questionIndex) => {
                if (questionIndex === action.value.questionIndex) {
                  return {
                    ...question,
                    answers: [
                      ...question.answers,
                      {
                        name: '',
                        score: 1,
                      },
                    ],
                  };
                }

                return question;
              }),
            };
          }

          return category;
        }),
      };
    case QuestionnaireReducerAction.SET_ANSWER_NAME:
      return {
        ...state,
        categories: state.categories.map((category, categoryIndex) => {
          if (categoryIndex === action.value.categoryIndex) {
            return {
              ...category,
              questions: category.questions.map((question, questionIndex) => {
                if (questionIndex === action.value.questionIndex) {
                  return {
                    ...question,
                    answers: [
                      ...question.answers.map((answer, answerIndex) => {
                        if (answerIndex === action.value.answerIndex) {
                          return {
                            ...answer,
                            name: action.value.value,
                          };
                        }

                        return answer;
                      }),
                    ],
                  };
                }

                return question;
              }),
            };
          }

          return category;
        }),
      };
    case QuestionnaireReducerAction.SET_ANSWER_SCORE:
      return {
        ...state,
        categories: state.categories.map((category, categoryIndex) => {
          if (categoryIndex === action.value.categoryIndex) {
            return {
              ...category,
              questions: category.questions.map((question, questionIndex) => {
                if (questionIndex === action.value.questionIndex) {
                  return {
                    ...question,
                    answers: [
                      ...question.answers.map((answer, answerIndex) => {
                        if (answerIndex === action.value.answerIndex) {
                          return {
                            ...answer,
                            score: +action.value.value,
                          };
                        }

                        return answer;
                      }),
                    ],
                  };
                }

                return question;
              }),
            };
          }

          return category;
        }),
      };
    case QuestionnaireReducerAction.DELETE_ANSWER:
      return {
        ...state,
        categories: state.categories.map((category, categoryIndex) => {
          if (categoryIndex === action.value.categoryIndex) {
            return {
              ...category,
              questions: category.questions.map((question, questionIndex) => {
                if (questionIndex === action.value.questionIndex) {
                  return {
                    ...question,
                    answers: question.answers.filter((_, answerIndex) => answerIndex !== action.value.answerIndex),
                  };
                }

                return question;
              }),
            };
          }

          return category;
        }),
      };
    case QuestionnaireReducerAction.INIT:
      const { name, categories } = action.value;
      const obj = {
        name,
        categories,
      };
      return { ...(obj as Questionnaire) };
    default:
      throw new Error();
  }
};
