
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _selectAreaInitQuery = (id: string) => ({
  areas_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
    }
  ),
});

type X = Unpacked<typeof _selectAreaInitQuery>
export type Area = X['areas_by_pk'];

export const selectAreaInitQuery = (id: string) => gql`${query(_selectAreaInitQuery(id))}`;
