import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import Dashboard from '../../../../layouts/Dashboard';
import ListSections from '../../../../components/Global/Areas/Sections/List';

import { sectionsInitQuery, Section } from './queries';

import styles from './styles';
import { updateAreaSection } from './mutations';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

const ListSectionsArea = ({ classes, history }: Props): React.ReactElement => {
  const [sections, setSections] = useState<Section[]>([]);

  const { data: sectionsInitData, refetch } = useQuery(sectionsInitQuery(), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && sectionsInitData) {
      setSections(sectionsInitData.area_sections);
    }
    return () => {
      mounted = false;
    };
  }, [sectionsInitData]);

  const handleCreate = () => {
    history.push(`/department/wards/create`);
  };

  const handleView = (id: string) => {
    history.push(`/department/wards/${id}`);
  };

  const handleActiveStatusChange = async (id: string, isActive: boolean) => {
    const updateVariables = {
      pk_columns: {
        id: id,
      },
      set: {
        active: isActive,
      },
    };
    await updateAreaSection(updateVariables);

    await refetch();
  };

  return (
    <Dashboard breadcrumbs={[{ label: 'Wards' }]}>
      <ListSections items={sections} handleCreate={handleCreate} handleView={handleView} handleActiveStatusChange={handleActiveStatusChange} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ListSectionsArea));
