import { types, query } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../react-app-env';

const _eventsInitQuery = () => ({
  area_sections: [
    {
      id: types.string,
      name: types.string,
    },
  ],
  emergencies: [
    {
      name: types.string,
    },
  ],
});

type X = Unpacked<typeof _eventsInitQuery>;
export type Section = X['area_sections'][0];
export type Emergency = X['emergencies'][0];

export const eventsInitQuery = () =>
  gql`
    ${query(_eventsInitQuery())}
  `;
