import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import Dashboard from '../../../../layouts/Dashboard';
import ViewSection from '../../../../components/Global/Areas/Sections/View';
import { selectSectionInitQuery, Section } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  section_id: string;
}

const ViewSectionArea = ({ classes, history }: Props): React.ReactElement => {
  const { section_id } = useParams<UrlParams>();

  const [section, setSection] = useState<Section>({
    name: '',
  });

  const { data: sectionInitData } = useQuery(selectSectionInitQuery(section_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && sectionInitData) {
      setSection(sectionInitData.area_sections_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [sectionInitData]);

  const handleEdit = () => {
    history.push(`/department/wards/${section_id}/edit`);
  };

  const handleCreate = () => {
    history.push(`/department/wards/${section_id}/bed-spaces/create`);
  };

  const handleView = () => {
    history.push(`/department/wards/${section_id}/bed-spaces`);
  };

  return (
    <Dashboard breadcrumbs={[{ label: 'Wards', link: `/department/wards` }, { label: section.name }]}>
      <ViewSection section_id={section_id} handleEdit={handleEdit} handleCreate={handleCreate} handleView={handleView} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ViewSectionArea));
