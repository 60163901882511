
import { query, params, types, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../../react-app-env';

const _stationInitQuery = (id: string) => ({
  area_sections_by_pk: params(
    {
      id: rawString(id),
    },
    {
      name: types.string,
      area: {
        name: types.string,
      },
    },
  )
});

type X = Unpacked<typeof _stationInitQuery>;
export type Section = X['area_sections_by_pk'];

export const stationInitQuery = (id: string) => gql`${query(_stationInitQuery(id))}`;
