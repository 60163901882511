import { WithStyles } from '@mui/styles';
import React, { PropsWithChildren } from 'react';

import withStyles from '@mui/styles/withStyles';
import { EmptyObject } from '../../react-app-env';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, PropsWithChildren<EmptyObject> {
}

const FullPage = ({ classes, children }: Props) => (
  <div className={classes.root}>{children}</div>
);

export default withStyles(styles)(FullPage);
