import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const updateAreaMutation = gql`
  mutation ($pk_columns: areas_pk_columns_input!, $set: areas_set_input!) {
    update_areas_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

export const updateArea = async (variables: object) => {
  return await gqlClient.mutate<{
    update_areas_by_pk: {
      id: string;
    };
  }>({ mutation: updateAreaMutation!, variables });
};
