import React, { useRef, useState } from 'react';
import { WithStyles, ClassNameMap } from '@mui/styles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { Typography, Paper, Toolbar, List, Grid, FormControl, RadioGroup, FormControlLabel, Radio, Divider, Button, FormLabel, ListItem, ListItemText } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import styles from './styles';
import { useMutation, useQuery } from '@apollo/client';
import { GetEraCareResponseQueryResponse, GetQuestionnaireQueryResponse, getEraCareResponseQuery, getQuestionnaireQuery } from './queries';
import CustomBackdrop from '../../../../components/CustomBackdrop';
import { insertEraCareQuestionnaireResponseMutation } from './mutations';

interface PatientResponse {
  categories: {
    name: string;
    questions: {
      name: string;
      answer: {
        name: string;
        score: number;
      };
    }[];
  }[];
}

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  organisation_id: string;
  area_id: string;
  era_care_event_id: string;
  era_care_notification_id: string;
}

const EraCareQuestionnaireComplete = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id, area_id, era_care_event_id, era_care_notification_id } = useParams<UrlParams>();

  const [patientResponse, setPatientResponse] = useState<PatientResponse>({
    categories: [],
  });

  const { data: questionnairesData } = useQuery<GetQuestionnaireQueryResponse>(getQuestionnaireQuery, {
    variables: { organisation_id },
    onCompleted(data) {
      if (data.questionnaires.length) {
        setPatientResponse({
          categories: data.questionnaires[0].categories.map((c) => ({
            name: c.name,
            questions: c.questions.map((q) => ({ name: q.name, answer: { name: '', score: 0 } })),
          })),
        });
      }
    },
  });

  const scrolledContainer = useRef<HTMLElement>(null);

  const {
    data: responseData,
    loading: responseDataLoading,
    refetch,
  } = useQuery<GetEraCareResponseQueryResponse>(getEraCareResponseQuery, {
    variables: { notification_id: era_care_notification_id },
    fetchPolicy: 'no-cache',
  });

  const [insertQuestionnaireResponse, { loading: savingQuestionnaire }] = useMutation(insertEraCareQuestionnaireResponseMutation, { onCompleted: refetch });

  const handleSave = async () => {
    scrolledContainer?.current?.scrollTo(0, 0);
    await insertQuestionnaireResponse({
      variables: {
        object: {
          organisation_id,
          area_id,
          era_care_event_id,
          era_care_notification_id,
          response: patientResponse,
        },
      },
    });
  };

  const handleAnswerSelected = (cIndex: number, qIndex: number, aIndex: number, name: string, score: number) => {
    setPatientResponse((prev) => ({
      categories: prev.categories.map((category, categoryIndex) => {
        if (categoryIndex === cIndex) {
          return {
            ...category,
            questions: category.questions.map((question, questionIndex) => {
              if (questionIndex === qIndex) {
                return {
                  ...question,
                  answer: {
                    name: name,
                    score: score,
                  },
                };
              }

              return question;
            }),
          };
        }

        return category;
      }),
    }));
  };

  const responseStored = !!responseData?.era_care_responses.length;
  const questionnaire = questionnairesData?.questionnaires.length ? questionnairesData.questionnaires[0] : undefined;

  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.main}>
        <div className={classes.toolbarRoot}>
          <Toolbar>
            <Typography className={classes.title} variant="h2">
              {'Self-check in'}
            </Typography>
          </Toolbar>
        </div>
        <main ref={scrolledContainer} className={classes.content}>
          {responseStored ? (
            <Grid container spacing={2}>
              <Grid item md={8} xs={12}>
                <Typography>
                  <p>Thank you for completing your self-check in.</p>

                  <p>Remember, if you or someone you are with has concerns about how you/they are feeling, you can get support from your doctor (GP) or Health Visitor.</p>

                  <p>
                    If you need urgent help with your mental health, please visit{' '}
                    <a href="https://openmentalhealth.org.uk/" target="_blank" rel="noreferrer">
                      https://openmentalhealth.org.uk/
                    </a>
                  </p>

                  <p>
                    Mindline Somerset:
                    <br />
                    Call: <a href="tel:01823276892">01823 276 892</a> or <a href="tel:08001381692">0800 138 1692</a>.<br />
                    Emotional support and mental health helpline open 24 hours a day, 7 days a week.
                  </p>

                  <p>
                    Somerset Maternity and Neonatal Voices Partnership (MNVP) supports us to hear feedback and make changes to our service. Have a look at their website for
                    information about:
                  </p>

                  <ul>
                    <li>sharing your feedback</li>
                    <li>birth reflections service</li>
                    <li>local information and groups</li>
                    <li>service improvements</li>
                  </ul>

                  <p>
                    MNVP link -{' '}
                    <a href="https://evolvingcommunities.co.uk/somerset-mvp/" target="_blank" rel="noreferrer">
                      Somerset Maternity Voices Partnership | Evolving Communities
                    </a>
                  </p>

                  <p>Thank you from the Somerset Foundation Trust Maternity team</p>
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2} justifyContent="center">
              {questionnaire ? (
                <>
                  <Grid item md={8} xs={12}>
                    <Typography textAlign="justify" variant="h5">
                      {questionnaire.name}
                    </Typography>
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <Grid container spacing={2}>
                      {questionnaire.categories.map((category, categoryIndex) => (
                        <>
                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Typography textAlign="justify" variant="subtitle1">
                                  {category.name}
                                </Typography>
                              </Grid>
                              {category.questions.map((question, questionIndex) => (
                                <Grid item xs={12}>
                                  <FormControl>
                                    <FormLabel id={`radio-buttons-group-label-${questionIndex}`}>{question.name}</FormLabel>
                                    <RadioGroup row aria-labelledby={`radio-buttons-group-label-${questionIndex}`} name={`radio-buttons-group-${questionIndex}`}>
                                      {question.answers.map((answer, answerIndex) => (
                                        <FormControlLabel
                                          value={answerIndex}
                                          control={<Radio />}
                                          label={answer.name}
                                          onClick={() => handleAnswerSelected(categoryIndex, questionIndex, answerIndex, answer.name, answer.score)}
                                        />
                                      ))}
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        </>
                      ))}
                    </Grid>
                  </Grid>

                  <Grid item md={8} xs={12}>
                    <Typography variant="body1" textAlign={'justify'}>
                      After you press submit, a reply will be sent to you based on your answers. It may give you:
                    </Typography>
                    <List sx={{ listStyleType: 'disc' }}>
                      <ListItem>
                        <ListItemText
                          sx={{ display: 'list-item', textAlign: 'justify' }}
                          primary="An opportunity to self-refer to other services that may be helpful to your wellbeing."
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText sx={{ display: 'list-item', textAlign: 'justify' }} primary="Ideas of things to speak to your doctor about at your post birth check." />
                      </ListItem>
                      <ListItem>
                        <ListItemText sx={{ display: 'list-item', textAlign: 'justify' }} primary="Information for immediate help." />
                      </ListItem>
                    </List>
                    <Button variant="contained" onClick={handleSave}>
                      Submit
                    </Button>
                  </Grid>
                </>
              ) : (
                <Grid item md={8} xs={12}>
                  <Typography>{'No questionnaire for selected trust. Sorry for inconveniences.'}</Typography>
                </Grid>
              )}
            </Grid>
          )}
        </main>
      </Paper>
      {(responseDataLoading || savingQuestionnaire) && <CustomBackdrop label="Loading" />}
    </div>
  );
};

export default withRouter(withStyles(styles)(EraCareQuestionnaireComplete));
