import React, { useState, useReducer } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import {
} from '@mui/material';

import CustomBackdrop from '../../../../../components/CustomBackdrop';
import { sectionReducer } from '../Common';
import Steps from '../Common/components/Steps';

import { createSection } from './mutations';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
  organisation_id?: string,
  area_id?: string,
  handleFinish: (id: string) => void,
}

const CreateSection = ({ classes, history, organisation_id, area_id, handleFinish }: Props): React.ReactElement => {
  
  const [section, dispatchSection] = useReducer(sectionReducer, {
    name: '',
    phone: '',
  });

  const [saving, setSaving] = useState<boolean>(false);

  const handleSave = async () => {
    setSaving(true);

    const insertVariables = {
      object: {
        ...section,
        organisation_id,
        area_id,
      },
    };
    const section_id: string | undefined = await createSection(insertVariables);
    if (section_id) {
      setSaving(false);
      handleFinish(section_id);
    }
  };

  return (
    <>
      <Steps
        completeLabel="Create"
        section={section}
        dispatch={dispatchSection}
        handleSave={handleSave} />
      {saving && <CustomBackdrop label="Creating Section" />}
    </>
  );
};

export default withRouter(withStyles(styles)(CreateSection));
