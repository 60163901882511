import React from 'react';

import { 
  Grid,
} from '@mui/material';

import { MetricEvents } from '.';
import { Event } from '../List';
 
 interface Props {
  events: Event[],
  loading: boolean,
}

const Metrics = ({ events, loading = false }: Props): React.ReactElement => {
  return (
    <Grid container spacing={2}>
      <MetricEvents events={events} loading={loading} />
    </Grid>
  );
};

export default Metrics;
