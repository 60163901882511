import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from './components/App';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: "https://d23608a2c00a40a191b20eb214307e8b@o777343.ingest.sentry.io/6489355",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: 'era-core-app@0.0.1',
  });
}

// import * as serviceWorker from './serviceWorker';

declare module '@mui/material/styles/createPalette' {
  interface CommonColors {
    black: string;
    white: string;
    green: string;
    red: string;
    neutral: string;
  }
  interface Palette {
    border: string;
  }
}


declare module '@mui/material/styles' {
  interface Theme {
    background: {
      default: string,
      paper: string,
    },
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    background?: {
      default: string,
      paper: string,
    },
    status?: {
      danger?: string;
    };
  }
}

// declare module '@mui/material/styles/createSpacing' {
//   interface Spacing {
//     unit: string;
//   }
// }

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
// serviceWorker.unregister();
