import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const insertAreaMutation = gql`
  mutation ($object: areas_insert_input!) {
    insert_areas_one(object: $object) {
      id
    }
  }
`;

export const createArea = async (variables: object) => {
  const res = await gqlClient.mutate<{
    insert_areas_one: {
      id: string,
    }
  }>({ mutation: insertAreaMutation!, variables });
  return res.data?.insert_areas_one.id;
}

const insertSectionMutation = gql`
  mutation ($object: area_sections_insert_input!) {
    insert_area_sections_one(object: $object) {
      id
    }
  }
`;

export const createSection = async (variables: object) => {
  const res = await gqlClient.mutate<{
    insert_area_sections_one: {
      id: string,
    }
  }>({ mutation: insertSectionMutation!, variables });
  return res.data?.insert_area_sections_one.id;
}




