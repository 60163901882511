import React, { useState, useReducer } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { UnknownObject } from '../../../../react-app-env';

import {
} from '@mui/material';

import CustomBackdrop from '../../../../components/CustomBackdrop';
import { areaReducer } from '../Common';
import Steps from '../Common/components/Steps';

import { createArea, createSection } from './mutations';
import { Category } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
  organisation_id?: string,
  categories: Category[],
  handleFinish: (id: string) => void,
}

const CreateArea = ({ classes, history, organisation_id, categories, handleFinish }: Props): React.ReactElement => {
  
  const [area, dispatchArea] = useReducer(areaReducer, {
    name: '',
    phone: '',
    department: '',
    categories: [],
  });

  const [saving, setSaving] = useState<boolean>(false);

  const handleSave = async () => {
    setSaving(true);

    const item: UnknownObject = {
      ...area,
    };

    delete item.categories;

    const insertVariables = {
      object: {
        ...item,
        organisation_id,
        category_assignments: {
          data: area.categories.map((i: Category) => ({
            organisation_id,
            category_id: i.id,
          })),
        },
      },
    };

    const area_id: string | undefined = await createArea(insertVariables);

    const insertSectionVariables = {
      object: {
        name: 'Base station',
        organisation_id,
        area_id,
        stations: {
          data: [
            {
              organisation_id,
              area_id,
              name: 'Base station',
              base_station: true,
            },
          ],
        },
      },
    };

    await createSection(insertSectionVariables);

    if (area_id) {
      setSaving(false);
      handleFinish(area_id);
    }
  };

  return (
    <>
      <Steps
        completeLabel="Create"
        area={area}
        categories={categories}
        dispatch={dispatchArea}
        handleSave={handleSave} />
      {saving && <CustomBackdrop label="Creating Area" />}
    </>
  );
};

export default withRouter(withStyles(styles)(CreateArea));
