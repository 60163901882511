import { gql } from '@apollo/client';
import { QuestionnaireCategory } from '../../../../components/Global/EraCareQuestionnaire/Common';

export const getEraCareResponseQuery = gql`
  query ($notification_id: uuid!) {
    era_care_responses(where: { era_care_notification_id: { _eq: $notification_id } }) {
      era_care_notification_id
    }
  }
`;

export interface GetEraCareResponseQueryResponse {
  era_care_responses: { era_care_notification_id: string }[];
}

export const getQuestionnaireQuery = gql`
  query ($organisation_id: uuid!) {
    questionnaires(where: { organisation_id: { _eq: $organisation_id } }) {
      name
      categories
    }
  }
`;

export interface GetQuestionnaireQueryResponse {
  questionnaires: { name: string; categories: QuestionnaireCategory[] }[];
}
