import moment from 'moment';

export const getStartOfDay = () => {
  const date = new Date();
  date.setUTCHours(0, 0, 0, 0);
  return date;
};

export const getEndOfDay = () => {
  const date = new Date();
  date.setUTCHours(23, 59, 59, 59);
  return date;
};

export const getDaysPast = (days: number) => {
  const date = getStartOfDay();
  date.setDate(date.getDate() - days);
  return date;
};

export const getWeeksPast = (weeks: number) => {
  const date = getStartOfDay();
  date.setDate(date.getDate() - weeks * 7);
  return date;
};

export const getStartOfWeek = (weeks: number) => {
  const d = getWeeksPast(weeks);
  const day = d.getDay();
  const diff = d.getDate() - day + (day === 0 ? -6 : 1);
  const nd = new Date(d.setDate(diff));
  nd.setUTCHours(0, 0, 0, 0);
  return nd;
};

export const getMonthsPast = (months: number) => {
  const date = getStartOfDay();
  date.setMonth(date.getMonth() - (months - 1));
  date.setDate(1);
  return date;
};

export const formatDate = (date: Date, format: string) => {
  return moment(date).format(format);
};

export const dateDiffFromString = (from: string, to: string): number => {
  const d1 = new Date(from);
  const d2 = new Date(to);
  return dateDiff(d1, d2);
};

export const dateDiff = (from: Date, to: Date): number => (from.getTime() - to.getTime()) / 1000;

export const dateDiffInDays = (from: Date, to: Date): number => dateDiff(from, to) / 60 / 60 / 24;

export const compensateDST = (dateIn: Date) => {
  const janOffset = new Date(dateIn.getFullYear(), 0, 1).getTimezoneOffset();
  const julOffset = new Date(dateIn.getFullYear(), 6, 1).getTimezoneOffset();
  const dstMinutes = dateIn.getTimezoneOffset() - Math.max(janOffset, julOffset);
  const dateOut = new Date(dateIn);
  dateOut.setMinutes(dateOut.getMinutes() - dstMinutes);
  return dateOut;
};

export const exactDateString = (date: Date) => {
  const nDate = date; //compensateDST(date);
  const year = nDate.getFullYear();
  const month = `${nDate.getMonth() + 1}`.padStart(2, '0');
  const day = `${nDate.getDate()}`.padStart(2, '0');
  const hours = `${nDate.getHours()}`.padStart(2, '0');
  const mins = `${nDate.getMinutes()}`.padStart(2, '0');
  const secs = `${nDate.getSeconds()}`.padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${mins}:${secs}`;
};
