import { capitaliseFirstOnly } from '../../../../utils/stringUtils';
import { ValidationType } from '../../../../react-app-env';

export interface EmergencyRole {
  name: string;
  description: string;
  emergencies: [];
}

export interface EmergencyRoleItem {
  id: string;
  name: string;
  description: string;
  emergency_assignments_aggregate: {
    aggregate: {
      count: number;
    };
  };
  active: boolean;
}

export interface Emergency {
  id: string;
  name: string;
}

export interface EmergencyAssignment {
  id: string;
  emergency: {
    id: string;
  };
}

export interface ValidationErrors {
  name: ValidationType;
}

export const validationErrors: ValidationErrors = {
  name: 'Please provide a name',
};

export interface Validation {
  name: (value: string) => ValidationType;
}

export const validation: Validation = {
  name: (value) => (!value ? validationErrors.name : false),
};

export enum EmergencyReducerAction {
  NAME,
  DESCRIPTION,
  UPDATE_EMERGENCIES,
  INIT,
}

export const emergencyReducer = (state: EmergencyRole, action: { type: EmergencyReducerAction; value: any }): EmergencyRole => {
  switch (action.type) {
    case EmergencyReducerAction.NAME:
      return { ...state, name: capitaliseFirstOnly(action.value) };
    case EmergencyReducerAction.DESCRIPTION:
      return { ...state, description: action.value };
    case EmergencyReducerAction.UPDATE_EMERGENCIES:
      return { ...state, emergencies: action.value };
    case EmergencyReducerAction.INIT:
      const { name, description, emergency_assignments } = action.value;
      const obj = {
        name,
        description: description || '',
        emergencies: emergency_assignments.map((a: EmergencyAssignment) => a.emergency as Emergency) as Emergency[],
      };
      return { ...(obj as EmergencyRole) };
    default:
      throw new Error();
  }
};
