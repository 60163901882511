
import { query, types } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _emergencyRoleInitQuery = () => ({
  emergencies: [{
    id: types.string,
    name: types.string,
  }],
});

type X = Unpacked<typeof _emergencyRoleInitQuery>;
export type Emergency = X['emergencies'][0];

export const emergencyRoleInitQuery = () => gql`${query(_emergencyRoleInitQuery())}`;
