import React from 'react';
import { Router } from 'react-router-dom';

import { ApolloProvider } from '@apollo/client/react';

import { createBrowserHistory } from 'history';
import { WithStyles } from '@mui/styles';

import { CssBaseline } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import Routes from '../../Routes';
import AuthProvider from '../AuthProvider';
import ApolloClient from '../../utils/apolloClient';

import CustomThemeProvider from '../CustomThemeProvider';
import { ThemeModes } from '../CustomThemeProvider/theme';

import styles from './styles';

const browserHistory = createBrowserHistory();

interface Props extends WithStyles<typeof styles> {}

const modes: ThemeModes = {
  primary: {
    main: '#005eb8',
    light: '#00308770',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#41B6E6',
    light: '#41B6E670',
    contrastText: '#FFFFFF',
  },
  light: {
    background: {
      paper: '#FFFFFF',
      default: '#F4F4F4',
    },
    text: {
      primary: '#000000DE',
      secondary: '#000000D2',
    }
  },
  dark: {
    background: {
      paper: '#222B36',
      default: '#171C24',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#FFFFFFB3',
    }
  },
};

const App = ({ classes }: Props): React.ReactElement => {
  return (
    <CustomThemeProvider modes={modes}>
      <CssBaseline />
      <AuthProvider>
        <ApolloProvider client={ApolloClient}>
              <div className={classes.root}>
                <Router history={browserHistory}>
                  <Routes />
                </Router>
              </div>
        </ApolloProvider>
      </AuthProvider>
    </CustomThemeProvider>
  );
};

export default withStyles(styles)(App);
