import React, { useCallback, useState } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import {
} from '@mui/material';

import CustomStepper from '../../../../../../components/CustomStepper';
import { CustomTransferListItem } from '../../../../../../components/CustomTransferList';
import { 
  EmergencyRole,
  validationErrors,
  ValidationErrors,
  EmergencyReducerAction,
  validation,
  Emergency,
 } from '../..';
import StepDetails from '../StepDetails';
import StepEmergencies from '../StepEmergencies';
import StepReview from '../StepReview';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  completeLabel: string,
  emergency: EmergencyRole,
  emergencies: Emergency[],
  dispatch: React.Dispatch<{ type: EmergencyReducerAction, value: any }>,
  handleSave: () => void,
}

const EmergencySteps = ({ classes, completeLabel, emergency, emergencies, dispatch, handleSave }: Props): React.ReactElement => {
  const [step, setStep] = useState<number>(0);

  const [errors, setErrors] = useState<ValidationErrors>({
    name: false,
  });

  const validate = useCallback((_step: number) => {
    const errorItems: UnknownObject = {}
    if (_step === 0) {
      errorItems.name = validation.name(emergency.name);
    }
    setErrors({...errors, ...errorItems});
    return (Object.keys(errorItems) as Array<keyof typeof validationErrors>).some((i) => errorItems[i]);
  }, [emergency, errors]);

  const handleCategoryAssignment = (_: unknown, assigned: CustomTransferListItem[]) => {
    const assignedIds = assigned.map((i) => i.id);
    const a = emergencies.filter((i: Emergency) => assignedIds.includes(i.id));
    dispatch({ type: EmergencyReducerAction.UPDATE_EMERGENCIES, value: a });
  }

  return (
    <>
      <CustomStepper
        step={step}
        isNextDisabled={(Object.keys(errors) as Array<keyof typeof errors>).some((i) => errors[i])}
        completeLabel={completeLabel}
        setStep={setStep}
        validate={validate}
        handleComplete={handleSave} >
        {[
          {
            label: 'Details',
            component:
              <StepDetails
                emergency={emergency}
                errors={errors}
                setErrors={setErrors}
                dispatch={dispatch} />
          },
          {
            label: 'Emergency assignment',
            gridSize: 8,
            component:
              <StepEmergencies
                items={emergencies.map((c: Emergency) => ({ id: c.id, label: c.name }))}
                assigned={emergency.emergencies.map((c: Emergency) => ({ id: c.id, label: c.name }))}
                handleAssignment={handleCategoryAssignment} />
          },
          {
            label: 'Summary',
            component:
              <StepReview
                emergency={emergency}
                setStep={setStep} />
            },
          ]}
        </CustomStepper>
    </>
  );
};

export default withStyles(styles)(EmergencySteps);
