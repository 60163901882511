
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../../react-app-env';

const _editAreaUserInitQuery = (id: string) => ({
  users_organisation_by_pk: params(
    { id: rawString(id) },
    {
      full_name: types.string,
      area: {
        name: types.string,
      },
      organisation: {
        name: types.string,
      },
    },
  ),
});

type X = Unpacked<typeof _editAreaUserInitQuery>
export type AreaUser = X['users_organisation_by_pk'];

export const editAreaUserInitQuery = (id: string) => gql`${query(_editAreaUserInitQuery(id))}`;
