
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _selectCategoryInitQuery = (id: string) => ({
  categories_by_pk: params(
    { id: rawString(id) },
    {
      id: types.string,
      name: types.string,
      description: types.string,
      emergency_assignments: [{
        id: types.string,
        emergency: {
          id: types.string,
          name: types.string,
        }
      }],
      area_assignments: [{
        id: types.string,
        area: {
          id: types.string,
          name: types.string,
        }
      }],
    }
  ),
});

type X = Unpacked<typeof _selectCategoryInitQuery>
export type Category = X['categories_by_pk'];

export const selectCategoryInitQuery = (id: string) => gql`${query(_selectCategoryInitQuery(id))}`;
