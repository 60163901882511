import React, { useState, useEffect, useReducer } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { UnknownObject } from '../../../../react-app-env';

import {
} from '@mui/material';

import CustomBackdrop from '../../../../components/CustomBackdrop';

import { Area, AreaAssignment, categoryReducer, CategoryReducerAction, Emergency, EmergencyAssignment } from '../Common';
import Steps from '../Common/components/Steps';

import { editCategoryInitQuery, Organisation } from './queries';
import { updateAreaAssignments, updateCategory } from './mutations';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
  category_id: string,
  organisation_id?: string,
  handleFinish: () => void,
}
const EditArea = ({ classes, history, category_id, organisation_id, handleFinish }: Props): React.ReactElement => {

  const [category, dispatchCategory] = useReducer(categoryReducer, {
    name: '',
    description: '',
    areas: [],
    emergencies: [],
  });

  const [organisation, setOrganisation] = useState<Organisation>({
    areas: [],
    emergencies: [],
  });

  const [assigned, setAssigned] = useState<{areas: AreaAssignment[], emergencies: EmergencyAssignment[]}>({
    areas: [],
    emergencies: [],
  });

  const [saving, setSaving] = useState<boolean>(false);

  const { data: editAreaInitData } = useQuery(editCategoryInitQuery(category_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editAreaInitData) {
      setOrganisation(editAreaInitData.categories_by_pk.organisation);
      setAssigned({
        areas: editAreaInitData.categories_by_pk.area_assignments,
        emergencies: editAreaInitData.categories_by_pk.emergency_assignments,
      });
      dispatchCategory({ type: CategoryReducerAction.INIT, value: editAreaInitData.categories_by_pk });
    }
    return () => { mounted = false; };
  }, [editAreaInitData]);

  const handleSave = async () => {
    setSaving(true);

    const areaAssignments = category.areas
      .filter((a: Area) => !assigned.areas.some((i: AreaAssignment) => a.id === i.area.id))
      .map((a: Area) => ({ area_id: a.id, organisation_id, category_id }));
    
    const areaAssignmentsRemove = assigned.areas
      .filter((i: AreaAssignment) => !category.areas.some((a: Area) => a.id === i.area.id))
      .map((i: AreaAssignment) => i.id);

    const emAssignments = category.emergencies
      .filter((a: Emergency) => !assigned.emergencies.some((i: EmergencyAssignment) => a.id === i.emergency.id))
      .map((a: Emergency) => ({ emergency_id: a.id, organisation_id, category_id }));
    
    const emAssignmentsRemove = assigned.emergencies
      .filter((i: EmergencyAssignment) => !category.emergencies.some((a: Emergency) => a.id === i.emergency.id))
      .map((i: EmergencyAssignment) => i.id);

    const item: UnknownObject = {
      ...category,
    };

    delete item.areas;
    delete item.emergencies;

    const mData = {
      ...item,
      organisation_id,
    };

    const updateVariables = {
      pk_columns: {
        id: category_id,
      },
      set: mData,
    };
    await updateCategory(updateVariables);
    await updateAreaAssignments(areaAssignments, emAssignments, areaAssignmentsRemove, emAssignmentsRemove);

    setSaving(false);
    handleFinish();
  };

  return (
    <>
      <Steps
        completeLabel="Save"
        category={category}
        areas={organisation.areas}
        emergencies={organisation.emergencies}
        dispatch={dispatchCategory}
        handleSave={handleSave} />
      {saving && <CustomBackdrop label="Saving Changes" />}
    </>
  );
};

export default withRouter(withStyles(styles)(EditArea));
