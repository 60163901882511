import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import Dashboard from '../../../../../layouts/Dashboard';

import CreateOrganisationUser from '../../../../../components/Global/OrganisationUsers/Create';

import { createOrganisationUserInitQuery, Organisation } from './queries';
import styles from './styles';
import { useQuery } from '@apollo/client';

interface UrlParams {
  organisation_id: string;
}
interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

const CreateOrganisationUserAdmin = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id } = useParams<UrlParams>();

  const [organisation, setOrganisation] = useState<Organisation>({
    name: '',
  });

  const { data: organisationUserInitData } = useQuery(createOrganisationUserInitQuery(organisation_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && organisationUserInitData) {
      setOrganisation(organisationUserInitData.organisations_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [organisationUserInitData]);

  const handleFinish = (id: string) => {
    history.push(`/admin/trusts/${organisation_id}/users/${id}`);
  };

  return (
    <>
      <Dashboard
        breadcrumbs={[
          { label: 'Trusts', link: '/admin/trusts' },
          { label: organisation.name, link: `/admin/trusts/${organisation_id}` },
          { label: 'Users', link: `/admin/trusts/${organisation_id}/users` },
          { label: 'Create' },
        ]}>
        <CreateOrganisationUser organisation_id={organisation_id} handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(withStyles(styles)(CreateOrganisationUserAdmin));
