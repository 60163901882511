import { query, params, types, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../react-app-env';

const _sectionsInitQuery = (id: string) => ({
  areas_by_pk: params(
    {
      id: rawString(id),
    },
    {
      name: types.string,
      sections: params({ order_by: { name: 'asc' } }, [
        {
          id: types.string,
          name: types.string,
          phone: types.string,
          stations_aggregate: params(
            { where: { base_station: { _eq: false } } },
            {
              aggregate: {
                count: types.number,
              },
            }
          ),
          active: types.boolean,
        },
      ]),
    }
  ),
});

type X = Unpacked<typeof _sectionsInitQuery>;
export type Area = X['areas_by_pk'];
export type Section = Area['sections'][0];

export const sectionsInitQuery = (id: string) =>
  gql`
    ${query(_sectionsInitQuery(id))}
  `;
