import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Dashboard from '../../../.././../../layouts/Dashboard';
import EditOrganisationUser from '../../../.././../../components/Global/OrganisationUsers/Edit';

import styles from './styles';
import { editAreaUserInitQuery, AreaUser } from './queries';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  organisation_id: string;
  area_id: string;
  area_user_id: string;
}

const EditAreaUserAdmin = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id, area_id, area_user_id } = useParams<UrlParams>();

  const [user, setUser] = useState<AreaUser>({
    full_name: '',
    area: {
      name: '',
    },
    organisation: {
      name: '',
    },
  });

  const { data: editAreaUserInitQueryData } = useQuery(editAreaUserInitQuery(area_user_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editAreaUserInitQueryData) {
      setUser(editAreaUserInitQueryData.users_organisation_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [editAreaUserInitQueryData]);

  const handleFinish = () => {
    history.push(`/admin/trusts/${organisation_id}/departments/${area_id}/users/${area_user_id}`);
  };

  return (
    <>
      <Dashboard
        breadcrumbs={[
          { label: 'Trusts', link: '/admin/trusts' },
          { label: user.organisation.name, link: `/admin/trusts/${organisation_id}` },
          { label: 'Departments', link: `/admin/trusts/${organisation_id}/departments` },
          { label: user.area.name, link: `/admin/trusts/${organisation_id}/departments/${area_id}` },
          { label: 'Users', link: `/admin/trusts/${organisation_id}/departments/${area_id}/users` },
          { label: user.full_name, link: `/admin/trusts/${organisation_id}/departments/${area_id}/users/${area_user_id}` },
          { label: 'Edit' },
        ]}>
        <EditOrganisationUser organisation_user_id={area_user_id} isAreaUser handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(withStyles(styles)(EditAreaUserAdmin));
