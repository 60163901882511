import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Dashboard from '../../../../layouts/Dashboard';

import CreateArea from '../../../../components/Global/Categories/Create';

import { categoryIitQuery, Area, Emergency } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

const CreateAreaOrg = ({ classes, history }: Props): React.ReactElement => {
  const [items, setItems] = useState<{ areas: Area[]; emergencies: Emergency[] }>({
    areas: [],
    emergencies: [],
  });

  const { data: categoryInitData } = useQuery(categoryIitQuery(), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && categoryInitData) {
      setItems({
        areas: categoryInitData.areas,
        emergencies: categoryInitData.emergencies,
      });
    }
    return () => {
      mounted = false;
    };
  }, [categoryInitData]);

  const handleFinish = (id: string) => {
    history.push(`/trust/grouping`);
  };

  return (
    <>
      <Dashboard breadcrumbs={[{ label: 'Grouping', link: `/trust/grouping` }, { label: 'Create' }]}>
        <CreateArea areas={items.areas} emergencies={items.emergencies} handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(withStyles(styles)(CreateAreaOrg));
