import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import {
  Grid,
} from '@mui/material';

import ReadOnlyBlock from '../../../../../../components/ReadOnlyBlock';
import { selectStationInitQuery, Station } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
  station_id: string,
  handleEdit: () => void,
}

const ViewStation = ({ classes, history, station_id, handleEdit }: Props): React.ReactElement => {

  const [station, setStation] = useState<Station>({
    name: '',
    phone: '',
  });

  const { data: areaInitData } = useQuery(selectStationInitQuery(station_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && areaInitData) {
      setStation(areaInitData.area_section_stations_by_pk);
    }
    return () => { mounted = false; };
  }, [areaInitData]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <>
          <ReadOnlyBlock
            title={station.name}
            gridSize={3}
            items={[
              { label: 'Phone', value: station.phone || 'No phone number' },
            ]}
            handleEdit={handleEdit} />
        </>
      </Grid>
    </Grid>
  );
};

export default withRouter(withStyles(styles)(ViewStation));
