import { capitaliseFirstOnly, numbersOnly } from '../../../../../utils/stringUtils';
import { ValidationType } from '../../../../../react-app-env';

export interface Section {
  name: string;
  phone: string;
}

export interface SectionItem {
  id: string;
  name: string;
  phone: string;
  stations_aggregate: {
    aggregate: {
      count: number;
    };
  };
  active: boolean;
}

export interface ValidationErrors {
  name: ValidationType;
}

export const validationErrors: ValidationErrors = {
  name: 'Please provide a name',
};

export interface Validation {
  name: (name: string) => ValidationType;
}

export const validation: Validation = {
  name: (name) => (!name ? validationErrors.name : false),
};

export enum SectionReducerAction {
  NAME,
  PHONE,
  INIT,
}

export const sectionReducer = (state: Section, action: { type: SectionReducerAction; value: any }): Section => {
  switch (action.type) {
    case SectionReducerAction.NAME:
      return { ...state, name: capitaliseFirstOnly(action.value) };
    case SectionReducerAction.PHONE:
      return { ...state, phone: numbersOnly(`${action.value}`) };
    case SectionReducerAction.INIT:
      const { name, phone } = action.value;
      const obj = {
        name,
        phone,
      };
      return { ...(obj as Section) };
    default:
      throw new Error();
  }
};
