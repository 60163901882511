import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Dashboard from '../../../../layouts/Dashboard';
import styles from './styles';
import CreateEraCareQuestionnaireComponent from '../../../../components/Global/EraCareQuestionnaire/Create';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

const CreateEraCareQuestionnaire = ({ classes, history }: Props): React.ReactElement => {
  const handleFinish = () => {
    history.push(`/trust/care-questionnaires`);
  };

  return (
    <Dashboard breadcrumbs={[{ label: 'ERA Care Questionnaire', link: '/trust/care-questionnaires' }, { label: 'Create' }]}>
      <CreateEraCareQuestionnaireComponent handleFinish={handleFinish} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(CreateEraCareQuestionnaire));
