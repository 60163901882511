import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    list: {
      borderWidth: 0,
      borderRightWidth: 1,
      borderColor: theme.palette.divider,
      borderStyle: 'solid',
      padding: theme.spacing(1),
      height: '100%',
    },
    toggleButton: {
      height: 40,
    },
    drawer: {
      width: 300,
    },
    listItem: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  });

export default styles;
