
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../react-app-env';

const _editSectionInitQuery = (id: string) => ({
  area_sections_by_pk: params(
    {
      id: rawString(id),
    },
    {
      name: types.string,
      phone: types.string,
      area: {
        name: types.string,
      },
    },
  )
});

type X = Unpacked<typeof _editSectionInitQuery>;
export type Station = X['area_sections_by_pk'];

export const editSectionInitQuery = (id: string) => gql`${query(_editSectionInitQuery(id))}`;
