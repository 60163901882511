import { types, query, rawString, params } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _selectOrganisationUsersInitQuery = (id: string, areas: string[]) => ({
  organisations_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
      areas: [
        {
          id: types.string,
          name: types.string,
        },
      ],
    }
  ),
  area_sections: params(
    {
      ...(areas.length
        ? {
            where: {
              _or: areas.map((o) => ({ area_id: { _eq: rawString(o) } })),
            },
          }
        : {}),
      offset: 0,
    },
    [
      {
        id: types.string,
        name: types.string,
      },
    ]
  ),
  emergencies: [
    {
      name: types.string,
    },
  ],
});

type X = Unpacked<typeof _selectOrganisationUsersInitQuery>;
export type Organisation = X['organisations_by_pk'];
export type Section = X['area_sections'][0];
export type Emergency = X['emergencies'][0];

export const selectOrganisationUsersInitQuery = (id: string, areas: string[]) =>
  gql`
    ${query(_selectOrganisationUsersInitQuery(id, areas))}
  `;
