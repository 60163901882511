import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { booleanToYesNo } from '../../../../utils/stringUtils';

import { useQuery } from '@apollo/client';

import {
  Grid,
} from '@mui/material';

import ReadOnlyBlock from '../../../../components/ReadOnlyBlock';

import { selectOrganisationUserInitQuery, OrganisationUser } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
  organisation_user_id: string,
  handleEdit: () => void,
}

const ViewOrganisationUser = ({ classes, history, organisation_user_id, handleEdit }: Props): React.ReactElement => {

  const [user, setUser] = useState<OrganisationUser>({
    full_name: '',
    email: '',
    active: false,
  });

  const { data: organisationUserInitQuery } = useQuery(selectOrganisationUserInitQuery(organisation_user_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && organisationUserInitQuery) {
      setUser(organisationUserInitQuery.users_organisation_by_pk);
    }
    return () => { mounted = false; };
  }, [organisationUserInitQuery]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <>
          <ReadOnlyBlock
            gridSize={3}
            items={user ? [
              { label: 'Name', value: user.full_name },
              { label: 'Email', value: user.email },
              { label: 'Active', value: booleanToYesNo(user.active) },
            ] : []}
            handleEdit={handleEdit} />
        </>
      </Grid>
    </Grid>
  );
};

export default withRouter(withStyles(styles)(ViewOrganisationUser));
