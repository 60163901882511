import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import Dashboard from '../../../../layouts/Dashboard';
import ViewEmergencyRole from '../../../../components/Global/EmergencyRoles/View';

import { selectEmergencyRoleInitQuery, EmergencyRole } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  emergency_role_id: string;
}

const ViewEmergencyRoleOrg = ({ classes, history }: Props): React.ReactElement => {
  const { emergency_role_id } = useParams<UrlParams>();

  const [emergencyRole, setEmergencyRole] = useState<EmergencyRole>({
    name: '',
  });

  const { data: emergencyRoleInitData } = useQuery(selectEmergencyRoleInitQuery(emergency_role_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && emergencyRoleInitData) {
      setEmergencyRole(emergencyRoleInitData.emergency_roles_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [emergencyRoleInitData]);

  const handleEdit = () => {
    history.push(`/trust/emergency_roles/${emergency_role_id}/edit`);
  };

  return (
    <Dashboard breadcrumbs={[{ label: 'Emergency roles', link: `/trust/emergency_roles` }, { label: emergencyRole.name }]}>
      <ViewEmergencyRole emergency_role_id={emergency_role_id} handleEdit={handleEdit} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ViewEmergencyRoleOrg));
