import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../react-app-env';

const _selectOrganisationUserInitQuery = (user_id: string) => ({
  users_organisation_by_pk: params(
    { id: rawString(user_id) },
    {
      full_name: types.string,
      area: {
        name: types.string,
      },
    },
  ),
});

type X = Unpacked<typeof _selectOrganisationUserInitQuery>
export type AreaUser = X['users_organisation_by_pk'];

export const selectOrganisationUserInitQuery = (user_id: string) => gql`${query(_selectOrganisationUserInitQuery(user_id))}`;
