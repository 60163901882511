import { ValidationType } from '../../../../react-app-env';
import { getMonthsPast } from '../../../../utils/dateUtils';
import { validateEmailAddress, validatePhoneNumber } from '../../../../utils/validators';

export enum NotificationStatus {
  ALL = 'ALL',
  PENDING = 'PENDING',
  WAITING_FOR_RESPONSE = 'WAITING_FOR_RESPONSE',
  COMPLETED = 'COMPLETED',
  CLOSED = 'CLOSED',
}

export const getNotificationProgressMessage = (notificationStatus: string) => {
  switch (notificationStatus as NotificationStatus) {
    case NotificationStatus.ALL:
      return 'All';
    case NotificationStatus.PENDING:
      return 'Form has not been sent';
    case NotificationStatus.WAITING_FOR_RESPONSE:
      return 'Waiting for the response';
    case NotificationStatus.COMPLETED:
      return 'Form has been completed';
    case NotificationStatus.CLOSED:
      return 'The event has been closed';

    default:
      throw new Error('Unable to get messages, not implemented notification status');
  }
};

export const getNotificationProgress = (notificationStatus: string) => {
  switch (notificationStatus as NotificationStatus) {
    case NotificationStatus.ALL:
      return 0;
    case NotificationStatus.PENDING:
      return 33;
    case NotificationStatus.WAITING_FOR_RESPONSE:
      return 66;
    case NotificationStatus.COMPLETED:
      return 100;
    case NotificationStatus.CLOSED:
      return 0;

    default:
      throw new Error('Unable to get progress, not implemented notification status');
  }
};

export enum DateRangeFilteringOptions {
  TWO_MONTHS = 'TWO_MONTHS',
  MONTH = 'MONTH',
  CUSTOM = 'CUSTOM',
}

export const getStartDate = (dateRange: DateRangeFilteringOptions, customStartDate: Date = new Date()): Date => {
  let date = new Date();

  if (dateRange === DateRangeFilteringOptions.TWO_MONTHS) {
    date = getMonthsPast(2);
  }

  if (dateRange === DateRangeFilteringOptions.MONTH) {
    date = getMonthsPast(1);
  }

  if (dateRange === DateRangeFilteringOptions.CUSTOM) {
    date = customStartDate;
  }

  date.setHours(0, 0, 0);

  return date;
};

export const getEndDate = (dateRange: DateRangeFilteringOptions, customEndDate: Date = new Date()): Date => {
  let date = new Date();

  if (dateRange === DateRangeFilteringOptions.CUSTOM) {
    date = customEndDate;
  }

  date.setHours(23, 59, 59);

  return date;
};

export const dateRangeOptions = [
  { key: DateRangeFilteringOptions.TWO_MONTHS, label: 'Last 2 months' },
  { key: DateRangeFilteringOptions.MONTH, label: 'This Month' },
  { key: DateRangeFilteringOptions.CUSTOM, label: 'Custom' },
];

export const notificationStatusOptions = [
  NotificationStatus.ALL,
  NotificationStatus.PENDING,
  NotificationStatus.WAITING_FOR_RESPONSE,
  NotificationStatus.COMPLETED,
  //NotificationStatus.CLOSED,
];

export interface EraCareEvent {
  id?: string;
  created_at: string;
  patient_first_name: string;
  patient_last_name: string;
  patient_mobile_number: string | null;
  patient_email: string | null;
  is_linked_to_emergency: boolean;
  is_tracked_by_era: boolean;
  patient_gives_consent_to_contact: boolean;
  notification?: { id: string; created_at: string };
  response?: { id: string };
}

export interface ValidationErrors {
  created_at: ValidationType;
  patient_first_name: ValidationType;
  patient_last_name: ValidationType;
  patient_mobile_number: ValidationType;
  patient_email: ValidationType;
}

export const validationErrors = {
  created_at: 'Please provide date of creation',
  patient_first_name: 'Please provide patient first name',
  patient_last_name: 'Please provide patient last name',
  patient_mobile_number: 'If no patient email, patient mobile number must be provided',
  patient_email: 'If no patient mobile number, patient email must be provided',
  email: 'Please provide a valid email',
  phone_number: 'Please provide a valid phone number (eg. +455555555555)',
};

export interface Validation {
  created_at: (createdAt: string) => ValidationType;
  patient_first_name: (firstName: string) => ValidationType;
  patient_last_name: (lastName: string) => ValidationType;
  patient_mobile_number: (number: string | null, email: string | null) => ValidationType;
  patient_email: (email: string | null, number: string | null) => ValidationType;
}

export const validation: Validation = {
  created_at: (createdAt) => (!createdAt ? validationErrors.created_at : false),
  patient_first_name: (firstName) => (!firstName ? validationErrors.patient_first_name : false),
  patient_last_name: (lastName) => (!lastName ? validationErrors.patient_last_name : false),
  patient_mobile_number: (number, email) => {
    if (!number && !email) {
      return validationErrors.patient_mobile_number;
    }

    if (number) {
      return validatePhoneNumber(number) ? false : validationErrors.phone_number;
    }

    return false;
  },
  patient_email: (email, number) => {
    if (!email && !number) {
      return validationErrors.patient_email;
    }

    if (email) {
      return validateEmailAddress(email) ? false : validationErrors.email;
    }

    return false;
  },
};

export enum EraCareEventReducerAction {
  SET_CREATED_AT,
  SET_PATIENT_FIRST_NAME,
  SET_PATIENT_LAST_NAME,
  SET_PATIENT_MOBILE_NUMBER,
  SET_PATIENT_EMAIL,
  SET_IS_LINKED_TO_EMERGENCY,
  SET_IS_TRACKED_BY_ERA,
  SET_CONSENT_TO_CONTACT,
  INIT,
}

export const eraCareEventReducer = (state: EraCareEvent, action: { type: EraCareEventReducerAction; value: any }): EraCareEvent => {
  switch (action.type) {
    case EraCareEventReducerAction.SET_CREATED_AT:
      return {
        ...state,
        created_at: action.value,
      };
    case EraCareEventReducerAction.SET_PATIENT_FIRST_NAME:
      return {
        ...state,
        patient_first_name: action.value,
      };
    case EraCareEventReducerAction.SET_PATIENT_LAST_NAME:
      return {
        ...state,
        patient_last_name: action.value,
      };
    case EraCareEventReducerAction.SET_PATIENT_MOBILE_NUMBER:
      return {
        ...state,
        patient_mobile_number: action.value,
      };
    case EraCareEventReducerAction.SET_PATIENT_EMAIL:
      return {
        ...state,
        patient_email: action.value,
      };
    case EraCareEventReducerAction.SET_IS_LINKED_TO_EMERGENCY:
      return {
        ...state,
        is_linked_to_emergency: action.value,
      };
    case EraCareEventReducerAction.SET_IS_TRACKED_BY_ERA:
      return {
        ...state,
        is_tracked_by_era: action.value,
        is_linked_to_emergency: action.value === false ? false : state.is_linked_to_emergency,
      };
    case EraCareEventReducerAction.SET_CONSENT_TO_CONTACT:
      return {
        ...state,
        patient_gives_consent_to_contact: action.value,
      };
    case EraCareEventReducerAction.INIT:
      const {
        created_at,
        patient_first_name,
        patient_last_name,
        patient_mobile_number,
        patient_email,
        is_linked_to_emergency,
        is_tracked_by_era,
        patient_gives_consent_to_contact,
      } = action.value;

      const obj = {
        created_at,
        patient_first_name,
        patient_last_name,
        patient_mobile_number,
        patient_email,
        is_linked_to_emergency,
        is_tracked_by_era,
        patient_gives_consent_to_contact,
      };
      return { ...(obj as EraCareEvent) };
    default:
      throw new Error();
  }
};
