import { capitaliseFirstOnly, numbersOnly } from '../../../../utils/stringUtils';
import { ValidationType } from '../../../../react-app-env';

export interface Area {
  name: string;
  phone: string;
  department: string;
  categories: Category[];
}

export interface AreaItem {
  id: string;
  name: string;
  phone: string;
  department: string;
  sections: SectionItem[];
  category_assignments: CategoryAssignment[];
  users_aggregate: {
    aggregate: {
      count: number;
    };
  };
  active: boolean;
}

export interface SectionItem {
  id: string;
  name: string;
  phone: string;
}

export interface Category {
  id: string;
  name: string;
}

export interface CategoryAssignment {
  id: string;
  category: {
    id: string;
    name: string;
    description: string;
  };
}

export interface ValidationErrors {
  name: ValidationType;
}

export const validationErrors: ValidationErrors = {
  name: 'Please provide a name',
};

export interface Validation {
  name: (name: string) => ValidationType;
}

export const validation: Validation = {
  name: (name) => (!name ? validationErrors.name : false),
};

export enum AreaReducerAction {
  NAME,
  PHONE,
  DEPARTMENT,
  UPDATE_CATEGORIES,
  INIT,
}

export const areaReducer = (state: Area, action: { type: AreaReducerAction; value: any }): Area => {
  switch (action.type) {
    case AreaReducerAction.NAME:
      return { ...state, name: capitaliseFirstOnly(action.value) };
    case AreaReducerAction.PHONE:
      return { ...state, phone: numbersOnly(`${action.value}`) };
    case AreaReducerAction.DEPARTMENT:
      return { ...state, department: capitaliseFirstOnly(action.value) };
    case AreaReducerAction.UPDATE_CATEGORIES:
      return { ...state, categories: action.value };
    case AreaReducerAction.INIT:
      const { name, phone, department, category_assignments } = action.value;
      const obj = {
        name,
        phone,
        department,
        categories: category_assignments.map((a: CategoryAssignment) => a.category as Category) as Category[],
      };
      return { ...(obj as Area) };
    default:
      throw new Error();
  }
};
