import React, { useCallback } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import { TextField } from '@mui/material';

import { Organisation, ValidationErrors, OrganisationReducerAction, validation } from '../..';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  organisation: Organisation;
  errors: ValidationErrors;
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>;
  dispatch: React.Dispatch<{ type: OrganisationReducerAction; value: any }>;
}

const StepDetails = ({ classes, organisation, errors, setErrors, dispatch }: Props): React.ReactElement => {
  const validate = useCallback(
    (field: string) => {
      const errorItems: UnknownObject = {};
      if (field === 'name') {
        errorItems.name = validation.name(organisation.name);
      }
      if (field === 'postcode') {
        errorItems.postcode = validation.postcode(organisation.postcode);
      }
      setErrors({ ...errors, ...errorItems });
    },
    [organisation, errors, setErrors]
  );

  return (
    <>
      <div className={classes.root}>
        <TextField
          className={classes.textField}
          data-qa="name-textfield"
          required
          label="Name"
          variant="outlined"
          error={errors.name as boolean}
          helperText={errors.name}
          value={organisation.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: OrganisationReducerAction.NAME, value: event.target.value })}
          onKeyUp={() => validate('name')}
          onBlur={() => validate('name')}
        />

        <TextField
          className={classes.textField}
          data-qa="postcode-textfield"
          required
          label="Postcode"
          variant="outlined"
          error={errors.postcode as boolean}
          helperText={errors.postcode}
          value={organisation.postcode}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: OrganisationReducerAction.POSTCODE, value: event.target.value })}
          onKeyUp={() => validate('postcode')}
          onBlur={() => validate('postcode')}
        />

        <TextField
          className={classes.textField}
          data-qa="first_assistance_label-textfield"
          label="Assistance Label 1"
          variant="outlined"
          value={organisation.first_assistance_label}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: OrganisationReducerAction.FIRST_ASSISTANCE_LABEL, value: event.target.value })}
        />

        <TextField
          className={classes.textField}
          data-qa="second_assistance_label-textfield"
          label="Assistance Label 2"
          variant="outlined"
          value={organisation.second_assistance_label}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: OrganisationReducerAction.SECOND_ASSISTANCE_LABEL, value: event.target.value })}
        />
        <TextField
          className={classes.textField}
          data-qa="trigger_emergency_label-textfield"
          label="Trigger emergency label"
          variant="outlined"
          value={organisation.trigger_emergency_label}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: OrganisationReducerAction.TRIGGER_EMERGENCY_LABEL, value: event.target.value })}
        />
      </div>
    </>
  );
};

export default withStyles(styles)(StepDetails);
