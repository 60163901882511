import { types, query, rawString, params } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../react-app-env';

const _selectAreaUsersInitQuery = (id: string) => ({
  areas_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
      users: [{
        id: types.string,
        full_name: types.string,
        email: types.string,
        active: types.boolean,
        enum_user_type: {
          user_type: types.string,
          description: types.string,
        },
      }],
    },
  ),
});

type X = Unpacked<typeof _selectAreaUsersInitQuery>
export type Area = X['areas_by_pk'];
export type AreaUser = Area['users'][0];

export const selectAreaUsersInitQuery = (id: string) => gql`${query(_selectAreaUsersInitQuery(id))}`;
