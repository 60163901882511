import React, { useState, useEffect } from 'react';
import { WithStyles, ClassNameMap } from '@mui/styles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { Grid } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import Dashboard from '../../../layouts/Dashboard';
import GridCard from '../../../components/Grid/GridCard';

import styles from './styles';
import { homeInitQuery, Counters } from './queries';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

const HomeOrg = ({ classes, history }: Props): React.ReactElement => {
  const [counters, setCounters] = useState<Counters>({
    areas_aggregate: {
      aggregate: { count: 0 },
    },
    users_organisation_aggregate: {
      aggregate: {
        count: 0,
      },
    },
    emergencies_aggregate: {
      aggregate: {
        count: 0,
      },
    },
    emergency_roles_aggregate: {
      aggregate: {
        count: 0,
      },
    },
    categories_aggregate: {
      aggregate: {
        count: 0,
      },
    },
    events_aggregate: {
      aggregate: {
        count: 0,
      },
    },
    questionnaires_aggregate: {
      aggregate: {
        count: 0,
      },
    },
    missing_patients_info: {
      aggregate: {
        count: 0,
      },
    },
  });

  const { data: homeInitData } = useQuery(homeInitQuery(), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && homeInitData) {
      setCounters({
        ...homeInitData,
      });
    }
    return () => {
      mounted = false;
    };
  }, [homeInitData]);

  const handleAction = (link: string) => {
    history.push(link);
  };

  const actions = [
    {
      title: 'Users',
      createLink: '/trust/users/create',
      viewLink: '/trust/users',
      counter: counters.users_organisation_aggregate.aggregate.count,
    },
    {
      title: 'Reports',
      viewLink: '/trust/reports',
      counter: counters.events_aggregate.aggregate.count,
    },
    {
      title: 'ERA Care Questionnaire',
      createLink: counters.questionnaires_aggregate.aggregate.count === 0 ? '/trust/care-questionnaires/create' : undefined,
      viewLink: '/trust/care-questionnaires',
      counter: undefined,
    },
    {
      title: 'Departments',
      createLink: '/trust/departments/create',
      viewLink: '/trust/departments',
      counter: counters.areas_aggregate.aggregate.count,
    },
    {
      title: 'Emergency roles',
      createLink: '/trust/emergency_roles/create',
      viewLink: '/trust/emergency_roles',
      counter: counters.emergency_roles_aggregate.aggregate.count,
    },
    {
      title: 'Emergencies',
      createLink: '/trust/emergencies/create',
      viewLink: '/trust/emergencies',
      counter: counters.emergencies_aggregate.aggregate.count,
    },
    {
      title: 'Grouping',
      createLink: '/trust/grouping/create',
      viewLink: '/trust/grouping',
      counter: counters.categories_aggregate.aggregate.count,
    },
  ];

  return (
    <Dashboard title="Dashboard">
      <Grid container spacing={4}>
        {actions.map((action) => (
          <GridCard
            key={action.title}
            title={action.title}
            counter={action.counter}
            editLabel="Create"
            fill
            handleEdit={action.createLink ? () => handleAction(action.createLink!) : undefined}
            handleView={() => handleAction(action.viewLink)}
          />
        ))}
      </Grid>
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(HomeOrg));
