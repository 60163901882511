import React, { useCallback, useState } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import CustomStepper from '../../../../../../components/CustomStepper';
import { Questionnaire, ValidationErrors, QuestionnaireReducerAction, validation } from '../../../Common';
import StepDetails from '../StepDetails';
import StepReview from '../StepReview';

import styles from './styles';
import StepQuestions from '../StepQuestions';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  completeLabel: string;
  questionnaire: Questionnaire;
  dispatch: React.Dispatch<{ type: QuestionnaireReducerAction; value: any }>;
  handleSave: () => void;
}

const QuestionnaireSteps = ({ classes, completeLabel, questionnaire, dispatch, handleSave }: Props): React.ReactElement => {
  const [step, setStep] = useState<number>(0);

  const [errors, setErrors] = useState<ValidationErrors>({
    name: false,
    categories: false,
    questions: {},
    answers: {},
    categoryName: {},
    lowThresholdMessage: {},
    lowThresholdScore: {},
    lowThresholdEmail: {},
    mediumThresholdMessage: {},
    mediumThresholdScore: {},
    mediumThresholdEmail: {},
    hightThresholdMessage: {},
    hightThresholdScore: {},
    hightThresholdEmail: {},
    questionName: {},
    answerName: {},
    answerScore: {},
  });

  const validate = useCallback(
    (_step: number) => {
      const errorItems: UnknownObject = {};

      if (_step === 0) {
        errorItems.name = validation.name(questionnaire.name);
      }

      if (_step === 1) {
        errorItems.categories = validation.categories(questionnaire.categories);

        questionnaire.categories.forEach((category, categoryIndex) => {
          errorItems.questions = {
            ...errorItems.questions,
            [categoryIndex]: validation.questions(category.questions),
          };

          errorItems.categoryName = {
            ...errorItems.categoryName,
            [categoryIndex]: validation.categoryName(category.name),
          };

          errorItems.lowThresholdMessage = {
            ...errorItems.lowThresholdMessage,
            [categoryIndex]: validation.thresholdMessage(category.lowThreshold.message),
          };

          errorItems.lowThresholdScore = {
            ...errorItems.lowThresholdScore,
            [categoryIndex]: validation.thresholdScore(category.lowThreshold.score),
          };

          errorItems.lowThresholdEmail = {
            ...errorItems.lowThresholdEmail,
            [categoryIndex]: validation.thresholdEmail(category.lowThreshold.referralEmail),
          };

          errorItems.mediumThresholdMessage = {
            ...errorItems.mediumThresholdMessage,
            [categoryIndex]: validation.thresholdMessage(category.mediumThreshold.message),
          };

          errorItems.mediumThresholdScore = {
            ...errorItems.mediumThresholdScore,
            [categoryIndex]: validation.thresholdScore(category.mediumThreshold.score),
          };

          errorItems.mediumThresholdEmail = {
            ...errorItems.mediumThresholdEmail,
            [categoryIndex]: validation.thresholdEmail(category.mediumThreshold.referralEmail),
          };

          errorItems.hightThresholdMessage = {
            ...errorItems.hightThresholdMessage,
            [categoryIndex]: validation.thresholdMessage(category.highThreshold.message),
          };

          errorItems.hightThresholdScore = {
            ...errorItems.hightThresholdScore,
            [categoryIndex]: validation.thresholdScore(category.highThreshold.score),
          };

          errorItems.hightThresholdEmail = {
            ...errorItems.hightThresholdEmail,
            [categoryIndex]: validation.thresholdEmail(category.highThreshold.referralEmail),
          };

          category.questions.forEach((question, questionIndex) => {
            errorItems.answers = {
              ...errorItems.answers,
              [categoryIndex]: {
                ...errorItems.answers?.[categoryIndex],
                [questionIndex]: validation.questions(question.answers),
              },
            };

            errorItems.questionName = {
              ...errorItems.questionName,
              [categoryIndex]: {
                ...errorItems.questionName?.[categoryIndex],
                [questionIndex]: validation.questionName(question.name),
              },
            };

            question.answers.forEach((answer, answerIndex) => {
              errorItems.answerName = {
                ...errorItems.answerName,
                [categoryIndex]: {
                  ...errorItems.answerName?.[categoryIndex],
                  [questionIndex]: {
                    ...errorItems.answerName?.[categoryIndex]?.[questionIndex],
                    [answerIndex]: validation.answerName(answer.name),
                  },
                },
              };

              errorItems.answerScore = {
                ...errorItems.answerScore,
                [categoryIndex]: {
                  ...errorItems.answerScore?.[categoryIndex],
                  [questionIndex]: {
                    ...errorItems.answerScore?.[categoryIndex]?.[questionIndex],
                    [answerIndex]: validation.answerScore(answer.score),
                  },
                },
              };
            });
          });
        });
      }

      setErrors({ ...errors, ...errorItems });

      return Object.values(errorItems).some((c) => {
        if (typeof c === 'object') {
          return Object.values(c).some((q) => {
            if (typeof q === 'object') {
              return Object.values(q!).some((a) => {
                if (typeof a === 'object') {
                  return Object.values(a).some((el) => el);
                }

                return a;
              });
            }

            return q;
          });
        }

        return c;
      });
    },
    [questionnaire, errors]
  );

  return (
    <>
      <CustomStepper step={step} isNextDisabled={false} completeLabel={completeLabel} gridSize={8} setStep={setStep} validate={validate} handleComplete={handleSave}>
        {[
          {
            label: 'Details',
            component: <StepDetails questionnaire={questionnaire} errors={errors} setErrors={setErrors} dispatch={dispatch} />,
          },
          {
            label: 'Questions',
            component: <StepQuestions questionnaire={questionnaire} errors={errors} dispatch={dispatch} />,
          },
          {
            label: 'Summary',
            component: <StepReview questionnaire={questionnaire} setStep={setStep} />,
          },
        ]}
      </CustomStepper>
    </>
  );
};

export default withStyles(styles)(QuestionnaireSteps);
