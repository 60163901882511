import { gql } from '@apollo/client';

export const getEraCareNotification = gql`
  query ($id: uuid!) {
    era_care_notifications_by_pk(id: $id) {
      id
      unsubscribe {
        era_care_notification_id
      }
    }
  }
`;

export interface GetEraCareNotificationResponse {
  era_care_notifications_by_pk: {
    id: string;
    unsubscribe: {
      era_care_notification_id: string;
    };
  };
}
