import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import Dashboard from '../../../../../layouts/Dashboard';
import ListOrganisationUsers from '../../../../../components/Global/OrganisationUsers/List';

import { selectOrganisationUsersInitQuery, Organisation, selectOrganisationUserQuery, SelectOrganisationUserResponse, SelectOrganisationUserRequest } from './queries';
import styles from './styles';
import { DeleteOrganisationUserResponse, InsertOrganisationUserResponse, deleteOrganisationUserMutation, insertOrganisationUserMutation } from './mutations';
import CustomBackdrop from '../../../../../components/CustomBackdrop';
import CustomDialog from '../../../../../components/CustomDialog';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  organisation_id: string;
}

const ListOrganisationUsersAdmin = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id } = useParams<UrlParams>();

  const [saving, setSaving] = useState<boolean>(false);
  const [confirmDialog, setConfirmDialog] = useState<{ visible: boolean; userId: string }>({
    visible: false,
    userId: '',
  });

  const [organisation, setOrganisation] = useState<Organisation>({
    name: '',
    users: [],
  });

  const { data: organisationUserInitQuery, refetch, loading } = useQuery(selectOrganisationUsersInitQuery(organisation_id), { fetchPolicy: 'no-cache' });

  const [deleteOrganisationUser] = useMutation<DeleteOrganisationUserResponse>(deleteOrganisationUserMutation);
  const [insertOrganisationUser] = useMutation<InsertOrganisationUserResponse>(insertOrganisationUserMutation);
  const [getOrganisationUser] = useLazyQuery<SelectOrganisationUserResponse, SelectOrganisationUserRequest>(selectOrganisationUserQuery, {
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      const { users_organisation_by_pk: user } = data;

      deleteOrganisationUser({
        variables: {
          user_id: user.id,
        },
      }).then((deleteResponse) => {
        if (deleteResponse.data?.delete_users_organisation_by_pk.id) {
          new Promise((resolve) => setTimeout(resolve, 3000)).then(() => {
            insertOrganisationUser({
              variables: {
                object: {
                  organisation_id: user.organisation_id,
                  active: user.active,
                  first_name: user.first_name,
                  last_name: user.last_name,
                  email: user.email,
                  user_type: user.user_type,
                  area_id: user.area_id,
                  created_at: user.created_at,
                },
              },
            }).then((_) => {
              setSaving(false);
              refetch();
            });
          });
        }
      });
    },
  });

  useEffect(() => {
    let mounted = true;
    if (mounted && organisationUserInitQuery) {
      setOrganisation(organisationUserInitQuery.organisations_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [organisationUserInitQuery]);

  const handleCreate = () => {
    history.push(`/admin/trusts/${organisation_id}/users/create`);
  };

  const handleView = (id: string) => {
    history.push(`/admin/trusts/${organisation_id}/users/${id}`);
  };

  const handleEdit = (id: string) => {
    history.push(`/admin/trusts/${organisation_id}/users/${id}/edit`);
  };

  const handleReset = (id: string) => {
    setSaving(true);
    setConfirmDialog({ visible: false, userId: '' });
    getOrganisationUser({
      variables: {
        user_id: id,
      },
    });
  };

  return (
    <>
      <Dashboard breadcrumbs={[{ label: 'Trusts', link: '/admin/trusts' }, { label: organisation.name, link: `/admin/trusts/${organisation_id}` }, { label: 'Users' }]}>
        <ListOrganisationUsers
          items={organisation.users}
          handleCreate={handleCreate}
          handleView={handleView}
          handleEdit={handleEdit}
          handleReset={(userId: string) => setConfirmDialog({ visible: true, userId })}
          note={'Note: When setting up iPads and base stations to the area intended for use you will have to use the “Ward Manager” Users'}
        />
      </Dashboard>
      <CustomDialog
        open={confirmDialog.visible}
        handleClose={() => setConfirmDialog((prev) => ({ ...prev, visible: false }))}
        title="Reset password"
        message="Are you sure you want to resent temporary password for this user?"
        actions={[
          { label: 'Cancel', onClick: () => setConfirmDialog((prev) => ({ ...prev, visible: false })) },
          { label: 'Confirm', onClick: () => handleReset(confirmDialog.userId) },
        ]}
      />
      {(saving || loading) && <CustomBackdrop label="Loading" />}
    </>
  );
};

export default withRouter(withStyles(styles)(ListOrganisationUsersAdmin));
