import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import gqlClient from '../../utils/apolloClient';
import { Unpacked } from '../../react-app-env';

const _getUserRoleQuery = (id: string) => ({
  user_roles: params({ where: { sub_id: { _eq: rawString(id) } } }, [
    {
      role: types.string,
    },
  ]),
});

export const getUserRoleQuery = async (id: string) =>
  await gqlClient.query<Unpacked<typeof _getUserRoleQuery>>({
    query: gql`
      ${query(_getUserRoleQuery(id))}
    `!,
  });

const _getAdminUserQuery = (id: string) => ({
  users_admin: params({ where: { sub_id: { _eq: rawString(id) } } }, [
    {
      id: types.string,
      email: types.string,
      first_name: types.string,
      last_name: types.string,
      full_name: types.string,
      sub_id: types.string,
    },
  ]),
});

export const getAdminUserQuery = async (id: string) => {
  const res = await gqlClient.query<Unpacked<typeof _getAdminUserQuery>>({
    query: gql`
      ${query(_getAdminUserQuery(id))}
    `!,
  });
  return res.data?.users_admin[0];
};

const _getOrganisationUserQuery = (id: string) => ({
  users_organisation: params({ where: { sub_id: { _eq: rawString(id) } } }, [
    {
      id: types.string,
      email: types.string,
      first_name: types.string,
      last_name: types.string,
      full_name: types.string,
      sub_id: types.string,
      area_id: types.string,
      organisation_id: types.string,
    },
  ]),
});

export const getOrganisationUserQuery = async (id: string) => {
  const res = await gqlClient.query<Unpacked<typeof _getOrganisationUserQuery>>({
    query: gql`
      ${query(_getOrganisationUserQuery(id))}
    `!,
  });
  return res.data?.users_organisation[0];
};
