import { types, query, rawString, params } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../react-app-env';

const _eventsInitQuery = (areas: string[]) => ({
  areas: [
    {
      id: types.string,
      name: types.string,
    },
  ],
  area_sections: params(
    {
      ...(areas.length
        ? {
            where: {
              _or: areas.map((o) => ({ area_id: { _eq: rawString(o) } })),
            },
          }
        : {}),
      offset: 0,
    },
    [
      {
        id: types.string,
        name: types.string,
      },
    ]
  ),
  emergencies: [
    {
      name: types.string,
    },
  ],
});

type X = Unpacked<typeof _eventsInitQuery>;
export type Area = X['areas'][0];
export type Section = X['area_sections'][0];
export type Emergency = X['emergencies'][0];

export const eventsInitQuery = (areas: string[]) =>
  gql`
    ${query(_eventsInitQuery(areas))}
  `;
