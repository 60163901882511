import React, { useCallback } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import { TextField } from '@mui/material';

import { Area, ValidationErrors, AreaReducerAction, validation } from '../..';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  area: Area;
  errors: ValidationErrors;
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>;
  dispatch: React.Dispatch<{ type: AreaReducerAction; value: any }>;
}

const StepDetails = ({ classes, area, errors, setErrors, dispatch }: Props): React.ReactElement => {
  const validate = useCallback(
    (field: string) => {
      const errorItems: UnknownObject = {};
      if (field === 'name') {
        errorItems.name = validation.name(area.name);
      }
      setErrors({ ...errors, ...errorItems });
    },
    [area, errors, setErrors]
  );

  return (
    <>
      <div className={classes.root}>
        <TextField
          className={classes.textField}
          data-qa="name-textfield"
          required
          label="Name"
          variant="outlined"
          error={errors.name as boolean}
          helperText={errors.name}
          value={area.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: AreaReducerAction.NAME, value: event.target.value })}
          onKeyUp={() => validate('name')}
          onBlur={() => validate('name')}
        />

        <TextField
          className={classes.textField}
          data-qa="phone-textfield"
          label="Phone"
          variant="outlined"
          value={area.phone}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: AreaReducerAction.PHONE, value: event.target.value })}
        />
      </div>
    </>
  );
};

export default withStyles(styles)(StepDetails);
