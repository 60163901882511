import { gql } from '@apollo/client';
import { EraCareEvent } from '../Common';

export const getEraCareEventsQuery = gql`
  query ($where: era_care_events_bool_exp!) {
    era_care_events(where: $where) {
      id
      created_at
      patient_first_name
      patient_last_name
      patient_mobile_number
      patient_email
      is_tracked_by_era
      is_linked_to_emergency
      patient_gives_consent_to_contact
      notification {
        id
        created_at
      }
      response {
        id
      }
    }
  }
`;

export interface GetEraCareEventsQueryResponse {
  era_care_events: EraCareEvent[];
}
