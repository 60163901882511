import { Grid, Typography } from '@mui/material';

export const rememberLowThreshHoldText =
  'Your responses suggest that your physical and mental health are OK and you are not requiring any additional NHS services at this time. If this changes or you or someone you are with has concerns about how you/they are feeling, you can get support from your doctor (GP) or Health Visitor.';
export const rememberMessageText =
  'Remember, if you or someone you are with has concerns about how you/they are feeling, you can get support from your doctor (GP) or Health Visitor.';

export const NeedHelpComponent = () => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Typography>
        <p>
          If you need urgent help with your mental health, please visit{' '}
          <a href="https://www.somersetft.nhs.uk/help-in-a-crisis/mental-health-crisis8/" target="_blank" rel="noreferrer">
            https://www.somersetft.nhs.uk/help-in-a-crisis/mental-health-crisis8/
          </a>
        </p>

        <p>
          Mindline Somerset:
          <br />
          Call: <a href="tel:01823276892">01823 276 892</a> or <a href="tel:08001381692">0800 138 1692</a>.<br />
          Emotional support and mental health helpline open 24 hours a day, 7 days a week.
        </p>

        <p>
          If you want to find out more about Talking Therapies visit{' '}
          <a href="https://www.somersetft.nhs.uk/somerset-talking-therapies" target="_blank" rel="noreferrer">
            How can we help you? (somersetft.nhs.uk)
          </a>
        </p>

        <p>In this section you will find information on guides to self help that can help you to overcome common mental health problems and links to other information</p>

        <p>
          Somerset Maternity and Neonatal Voices Partnership (MNVP) supports us to hear feedback and make changes to our service. Have a look at their website for information
          about:
        </p>

        <ul>
          <li>sharing your feedback</li>
          <li>birth reflections service</li>
          <li>local information and groups</li>
          <li>service improvements</li>
        </ul>

        <p>
          MNVP link -{' '}
          <a href="https://evolvingcommunities.co.uk/somerset-mvp/" target="_blank" rel="noreferrer">
            Somerset Maternity Voices Partnership | Evolving Communities
          </a>
        </p>

        <p>Thank you from the Somerset Foundation Trust Maternity team</p>
      </Typography>
    </Grid>
  </Grid>
);

export interface PatientCategoryScore {
  name: string;
  score: number;
  referralEmail: string | undefined;
  message: string | undefined;
}

interface PatientResponse {
  categories: {
    name: string;
    questions: {
      name: string;
      answer: {
        name: string;
        score: number;
      };
    }[];
  }[];
}

interface CategoryThreshold {
  score: number;
  message: string;
  referralEmail: string;
}

export interface QuestionnaireCategory {
  name: string;
  lowThreshold: CategoryThreshold;
  mediumThreshold: CategoryThreshold;
  highThreshold: CategoryThreshold;
}

export const calculatePatientCategoryScore = (patientResponse: PatientResponse, categories: QuestionnaireCategory[]): PatientCategoryScore[] =>
  categories.map((category, categoryIndex) => {
    const patientCategoryScore = patientResponse.categories[categoryIndex].questions.reduce((n, { answer: { score } }) => n + score, 0);

    if (patientCategoryScore >= category.highThreshold.score) {
      return {
        name: category.name,
        score: patientCategoryScore,
        referralEmail: category.highThreshold.referralEmail,
        message: category.highThreshold.message,
      };
    }

    if (patientCategoryScore >= category.mediumThreshold.score) {
      return {
        name: category.name,
        score: patientCategoryScore,
        referralEmail: category.mediumThreshold.referralEmail,
        message: category.mediumThreshold.message,
      };
    }

    if (patientCategoryScore >= category.lowThreshold.score) {
      return {
        name: category.name,
        score: patientCategoryScore,
        referralEmail: category.lowThreshold.referralEmail,
        message: category.lowThreshold.message,
      };
    }

    return {
      name: category.name,
      score: patientCategoryScore,
      referralEmail: undefined,
      message: undefined,
    };
  });
