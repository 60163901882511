import React, { useCallback, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import { Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, TextField } from '@mui/material';

import { EraCareEvent, ValidationErrors, EraCareEventReducerAction, validation } from '../..';

import styles from './styles';
import { exactDateString } from '../../../../../../utils/dateUtils';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  eraCareEvent: EraCareEvent;
  errors: ValidationErrors;
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>;
  dispatch: React.Dispatch<{ type: EraCareEventReducerAction; value: any }>;
}

const StepDetails = ({ classes, eraCareEvent, errors, setErrors, dispatch }: Props): React.ReactElement => {
  const validate = useCallback(
    (field: string) => {
      const errorItems: UnknownObject = {};
      if (field === 'created_at') {
        errorItems.created_at = validation.created_at(eraCareEvent.created_at);
      }
      if (field === 'patient_first_name') {
        errorItems.patient_first_name = validation.patient_first_name(eraCareEvent.patient_first_name);
      }
      if (field === 'patient_last_name') {
        errorItems.patient_last_name = validation.patient_last_name(eraCareEvent.patient_last_name);
      }
      if (field === 'patient_mobile_number') {
        errorItems.patient_mobile_number = validation.patient_mobile_number(eraCareEvent.patient_mobile_number, eraCareEvent.patient_email);
        errorItems.patient_email = validation.patient_email(eraCareEvent.patient_email, eraCareEvent.patient_mobile_number);
      }
      if (field === 'patient_email') {
        errorItems.patient_email = validation.patient_email(eraCareEvent.patient_email, eraCareEvent.patient_mobile_number);
        errorItems.patient_mobile_number = validation.patient_mobile_number(eraCareEvent.patient_mobile_number, eraCareEvent.patient_email);
      }

      setErrors({ ...errors, ...errorItems });
    },
    [eraCareEvent, errors, setErrors]
  );

  useEffect(() => {
    if (errors.created_at) {
      validate('created_at');
    }
  }, [eraCareEvent.created_at, errors.created_at, validate]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl component="fieldset" sx={{ width: '100%' }} error={errors.created_at as boolean}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label="Event Creation Time"
              value={new Date(eraCareEvent.created_at)}
              inputFormat="dd/MM/yyyy HH:mm"
              onChange={(date) => dispatch({ type: EraCareEventReducerAction.SET_CREATED_AT, value: date ? exactDateString(date) : exactDateString(new Date()) })}
              renderInput={(params) => <TextField {...params} />}
              onClose={() => validate('created_at')}
            />
          </LocalizationProvider>
          <FormHelperText>{errors.created_at as string}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          className={classes.textField}
          data-qa="first-name-textfield"
          required
          label="Patient First Name"
          variant="outlined"
          error={errors.patient_first_name as boolean}
          helperText={errors.patient_first_name}
          value={eraCareEvent.patient_first_name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: EraCareEventReducerAction.SET_PATIENT_FIRST_NAME, value: event.target.value })}
          onKeyUp={() => validate('patient_first_name')}
          onBlur={() => validate('patient_first_name')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          className={classes.textField}
          data-qa="last-name-textfield"
          required
          label="Patient Last Name"
          variant="outlined"
          error={errors.patient_last_name as boolean}
          helperText={errors.patient_last_name}
          value={eraCareEvent.patient_last_name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: EraCareEventReducerAction.SET_PATIENT_LAST_NAME, value: event.target.value })}
          onKeyUp={() => validate('patient_last_name')}
          onBlur={() => validate('patient_last_name')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          className={classes.textField}
          data-qa="mobile-textfield"
          label="Patient Mobile Number"
          variant="outlined"
          error={errors.patient_mobile_number as boolean}
          helperText={errors.patient_mobile_number}
          value={eraCareEvent.patient_mobile_number ?? ''}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: EraCareEventReducerAction.SET_PATIENT_MOBILE_NUMBER, value: event.target.value })}
          onKeyUp={() => validate('patient_mobile_number')}
          onBlur={() => validate('patient_mobile_number')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          className={classes.textField}
          data-qa="email-textfield"
          label="Patient Email"
          variant="outlined"
          error={errors.patient_email as boolean}
          helperText={errors.patient_email}
          value={eraCareEvent.patient_email ?? ''}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: EraCareEventReducerAction.SET_PATIENT_EMAIL, value: event.target.value })}
          onKeyUp={() => validate('patient_email')}
          onBlur={() => validate('patient_email')}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={eraCareEvent.patient_gives_consent_to_contact}
              onChange={() => dispatch({ type: EraCareEventReducerAction.SET_CONSENT_TO_CONTACT, value: !eraCareEvent.patient_gives_consent_to_contact })}
              name="consent-to-contact"
            />
          }
          label="Patient gives consent to contact"
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(StepDetails);
