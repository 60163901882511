import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';

import ReadOnlyBlock from '../../../../../../components/ReadOnlyBlock';
import { Organisation } from '../..';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  organisation: Organisation;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const StepReview = ({ classes, organisation, setStep }: Props): React.ReactElement => {
  return (
    <>
      <ReadOnlyBlock
        items={[
          { label: 'Trust name', value: organisation.name },
          { label: 'Postcode', value: organisation.postcode },
          { label: 'Assistance Label 1', value: organisation.first_assistance_label ?? '---' },
          { label: 'Assistance Label 2', value: organisation.second_assistance_label ?? '---' },
          { label: 'Trigger Emergency', value: organisation.trigger_emergency_label ?? '---' },
        ]}
        handleEdit={() => setStep(0)}
      />
    </>
  );
};

export default withStyles(styles)(StepReview);
