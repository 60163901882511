import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Dashboard from '../../../../../layouts/Dashboard';

import CreateStation from '../../../../../components/Global/Areas/Sections/Stations/Create';

import { stationInitQuery, Section } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  section_id: string;
}

const CreateStationOrg = ({ classes, history }: Props): React.ReactElement => {
  const { section_id } = useParams<UrlParams>();

  const [section, setSection] = useState<Section>({
    name: '',
  });

  const { data: stationInitData } = useQuery(stationInitQuery(section_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && stationInitData) {
      setSection(stationInitData.area_sections_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [stationInitData]);

  const handleFinish = () => {
    history.push(`/department/wards/${section_id}/bed-spaces`);
  };

  return (
    <>
      <Dashboard
        breadcrumbs={[
          { label: 'Wards', link: `/department/wards` },
          { label: section.name, link: `/department/wards/${section_id}` },
          { label: 'Bed spaces', link: `/department/wards/${section_id}/bed-spaces` },
          { label: 'Create' },
        ]}>
        <CreateStation section_id={section_id} handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(withStyles(styles)(CreateStationOrg));
