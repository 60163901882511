import React, { useState, useEffect } from 'react';
import { WithStyles, ClassNameMap } from '@mui/styles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { Grid } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import Dashboard from '../../../layouts/Dashboard';
import GridCard from '../../../components/Grid/GridCard';

import styles from './styles';
import { homeInitQuery } from './queries';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

const HomeAdmin = ({ classes, history }: Props): React.ReactElement => {
  const [organisationsCount, setOrganisationsCount] = useState<number>(0);

  const { data: homeInitData } = useQuery(homeInitQuery, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && homeInitData) {
      setOrganisationsCount(homeInitData.organisations_aggregate.aggregate.count);
    }
    return () => {
      mounted = false;
    };
  }, [homeInitData]);

  const handleAction = (link: string) => {
    history.push(link);
  };

  const actions = [
    {
      title: 'Trusts',
      counter: organisationsCount,
      createLink: '/admin/trusts/create',
      viewLink: '/admin/trusts',
    },
  ];

  return (
    <Dashboard title="Dashboard">
      <Grid container spacing={4}>
        {actions.map((action) => (
          <GridCard
            key={action.title}
            title={action.title}
            counter={action.counter}
            editLabel="Create"
            handleEdit={() => handleAction(action.createLink)}
            handleView={() => handleAction(action.viewLink)}
          />
        ))}
      </Grid>
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(HomeAdmin));
