import React, { useState, useEffect, useReducer } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import {
} from '@mui/material';

import CustomBackdrop from '../../../../components/CustomBackdrop';
import { organisationUserReducer, OrganisationUserReducerAction, OrganisationUserType } from '../Common';
import Steps from '../Common/components/Steps';


import styles from './styles';
import { editOrganisationUserInitQuery } from './queries';
import { updateOrganisationUser } from './mutations';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
  organisation_user_id: string,
  isAreaUser?: boolean,
  handleFinish: () => void,
}

const EditOrganisationUser = ({ classes, history, organisation_user_id, isAreaUser = false, handleFinish }: Props): React.ReactElement => {

  const [userTypes, setUserTypes] = useState<OrganisationUserType[]>([]);

  const [user, dispatchUser] = useReducer(organisationUserReducer, {
    first_name: '',
    last_name: '',
    user_type: 'ORG',
    email: '',
    active: true,
  });

  const [saving, setSaving] = useState<boolean>(false);

  const { data: editOrganisationUserInitQueryData } = useQuery(editOrganisationUserInitQuery(organisation_user_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editOrganisationUserInitQueryData) {
      dispatchUser({ type: OrganisationUserReducerAction.INIT, value: editOrganisationUserInitQueryData.users_organisation_by_pk });
      setUserTypes(editOrganisationUserInitQueryData.enum_organisation_user_types);
    }
    return () => { mounted = false; };
  }, [editOrganisationUserInitQueryData]);

  const handleSave = async () => {
    setSaving(true);

    const updateVariables = {
      pk_columns: {
        id: organisation_user_id,
      },
      set: user,
    };
    await updateOrganisationUser(updateVariables);

    setSaving(false);
    handleFinish();
  };

  return (
    <>
      <Steps
        completeLabel="Save"
        user={user}
        userTypes={userTypes}
        isEdit
        isAreaUser={isAreaUser}
        dispatch={dispatchUser}
        handleSave={handleSave} />
      {saving && <CustomBackdrop label="Saving Changes" />}
    </>
  );
};

export default withRouter(withStyles(styles)(EditOrganisationUser));
