import React, { useState } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Grid, IconButton, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';

import { AddCircle as AddCircleIcon, ArrowUpward as ArrowUpwardIcon, ArrowDownward as ArrowDownwardIcon } from '@mui/icons-material';

import CustomSortableTable, { SortableTableAction, SortableTableFilter, SortableTableHeader, SortableTableRow } from '../../../../components/CustomSortableTable';

import { EmergencyItem, ReorderDirection } from '../Common';

import styles from './styles';
import CustomDialog from '../../../CustomDialog';
import { booleanToYesNo } from '../../../../utils/stringUtils';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  items: EmergencyItem[];
  handleCreate: () => void;
  handleEdit: (id: string, view: string) => void;
  handleActiveStatusChange: (id: string, isActive: boolean) => Promise<void>;
  handlePriorityChange: (id: string, priority: number, direction: ReorderDirection) => Promise<void>;
}

const ListEmergencies = ({ classes, history, items, handleCreate, handleEdit, handleActiveStatusChange, handlePriorityChange }: Props): React.ReactElement => {
  const [activeFiltered, setActiveFiltered] = useState<boolean>(true);
  const [recordStatusState, setRecordStatusState] = useState<{
    dialogOpen: boolean;
    emergencyId: string;
    isActive: boolean;
    isLoading: boolean;
  }>({
    dialogOpen: false,
    emergencyId: '',
    isActive: false,
    isLoading: false,
  });

  const deactivateEmergency = async () => {
    setRecordStatusState((prev) => ({ ...prev, isLoading: true }));
    await handleActiveStatusChange(recordStatusState.emergencyId, recordStatusState.isActive);
    setRecordStatusState((prev) => ({ ...prev, isLoading: false, dialogOpen: false }));
  };

  const changePriority = async (id: string, priority: number, direction: ReorderDirection) => {
    setRecordStatusState((prev) => ({ ...prev, isLoading: true }));
    await handlePriorityChange(id, priority, direction);
    setRecordStatusState((prev) => ({ ...prev, isLoading: false }));
  };

  const headers: SortableTableHeader[] = [
    { key: 'name', label: 'Name' },
    { key: 'description', label: 'Description' },
    { key: 'grouping', label: 'Grouping' },
    { key: 'roles', label: 'Roles' },
    { key: 'active', label: 'Active' },
    { key: 'priority', label: '' },
  ];

  const rows: SortableTableRow[] = items.map((item: EmergencyItem, itemIndex) => ({
    key: item.id,
    actions: [
      {
        label: item.active ? 'Deactivate' : 'Activate',
        destructive: item.active,
        onClick: () => setRecordStatusState((prev) => ({ ...prev, emergencyId: item.id, isActive: !item.active, dialogOpen: true })),
      },
      {
        label: 'Edit',
        onClick: () => handleEdit(item.id, 'emergencies'),
      },
    ],
    columns: [
      {
        key: 'name',
        label: item.name,
      },
      {
        key: 'description',
        label: item.description,
      },
      {
        key: 'grouping',
        label: item.category_assignments.length,
      },
      {
        key: 'roles',
        label: item.role_assignments.length,
      },
      {
        key: 'active',
        label: booleanToYesNo(item.active),
      },
      {
        key: 'priority',
        component: (
          <>
            <Tooltip title="Move Up" placement="top" arrow>
              <IconButton disabled={itemIndex === 0} onClick={() => changePriority(item.id, item.priority_index - 1, ReorderDirection.UP)}>
                <ArrowUpwardIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Move Down" placement="top" arrow>
              <IconButton disabled={itemIndex === items.length - 1} onClick={() => changePriority(item.id, item.priority_index + 1, ReorderDirection.DOWN)}>
                <ArrowDownwardIcon />
              </IconButton>
            </Tooltip>
          </>
        ),
        label: '',
      },
    ],
    collapsible: [
      {
        title: 'Grouping',
        counter: item.category_assignments.length,
        headers: [
          { key: 'name', label: 'Name' },
          { key: 'description', label: 'Description' },
        ],
        rows: item.category_assignments
          .map((i) => i.category)
          .map((i) => ({
            key: i.id,
            actions: [{ label: 'Edit', onClick: () => handleEdit(i.id, 'grouping') }],
            columns: [
              { key: 'name', label: i.name },
              { key: 'description', label: i.description },
            ],
          })),
      },
      {
        title: 'Roles',
        counter: item.role_assignments.length,
        headers: [
          { key: 'name', label: 'Name' },
          { key: 'description', label: 'Description' },
        ],
        rows: item.role_assignments
          .map((i) => i.role)
          .map((i) => ({
            key: i.id,
            actions: [{ label: 'Edit', onClick: () => handleEdit(i.id, 'emergency_roles') }],
            columns: [
              { key: 'name', label: i.name },
              { key: 'description', label: i.description },
            ],
          })),
      },
    ].filter((i) => i.rows.length > 0),
  }));

  const actions: SortableTableAction[] = [
    {
      key: 'create',
      label: 'Create emergency',
      icon: <AddCircleIcon />,
      onClick: () => handleCreate(),
    },
  ];

  const activeFilter: SortableTableFilter = {
    key: 'activeFilter',
    label: 'Active',
    filter: (rows: SortableTableRow[]) =>
      rows.filter((r) => {
        const col = r.columns.find((c) => c.key === 'active');
        if (col) {
          return activeFiltered ? col.label === 'Yes' : col.label === 'No';
        }
        return true;
      }),
    component: (
      <ToggleButtonGroup className={classes.activeFilter} size="medium" value={[activeFiltered]} color="primary" onChange={() => setActiveFiltered(!activeFiltered)}>
        <ToggleButton className={classes.toggleButton} key="active" value={true}>
          Active
        </ToggleButton>
        <ToggleButton className={classes.toggleButton} key="inactive" value={false}>
          Inactive
        </ToggleButton>
      </ToggleButtonGroup>
    ),
  };

  const filters = [activeFilter];

  const filterRows = (): SortableTableRow[] => {
    let filteredRows: SortableTableRow[] = rows;
    filters.forEach((f) => {
      filteredRows = f.filter(filteredRows);
    });
    return filteredRows;
  };

  return (
    <>
      <Grid container spacing={4} alignItems="stretch">
        <Grid item xs={12}>
          <CustomSortableTable title="Emergencies" noSorting={true} actions={actions} headers={headers} counter={rows.length} rows={filterRows()} filters={filters} />
        </Grid>
      </Grid>
      <CustomDialog
        open={recordStatusState.dialogOpen}
        title="Update Emergency"
        message={`Are you sure you want to ${recordStatusState.isActive ? 'activate' : 'deactivate'} this emergency?`}
        handleClose={() => setRecordStatusState((prev) => ({ ...prev, dialogOpen: false }))}
        actions={[
          { label: 'No', onClick: () => setRecordStatusState((prev) => ({ ...prev, dialogOpen: false })) },
          { label: 'Yes', onClick: () => deactivateEmergency() },
        ]}
      />
    </>
  );
};

export default withRouter(withStyles(styles)(ListEmergencies));
