import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import Dashboard from '../../../../../layouts/Dashboard';
import ViewEmergency from '../../../../../components/Global/Emergencies/View';

import { selectEmergencyInitQuery, Emergency } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  organisation_id: string;
  emergency_id: string;
}

const ViewEmergencyAdmin = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id, emergency_id } = useParams<UrlParams>();

  const [emergency, setEmergency] = useState<Emergency>({
    id: '',
    name: '',
    description: '',
    organisation: {
      name: '',
    },
    category_assignments: [],
  });

  const { data: emergencyInitData } = useQuery(selectEmergencyInitQuery(emergency_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && emergencyInitData) {
      setEmergency(emergencyInitData.emergencies_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [emergencyInitData]);

  const handleEdit = () => {
    history.push(`/admin/trusts/${organisation_id}/emergencies/${emergency_id}/edit`);
  };

  return (
    <Dashboard
      breadcrumbs={[
        { label: 'Trusts', link: '/admin/trusts' },
        { label: emergency.organisation.name, link: `/admin/trusts/${organisation_id}` },
        { label: 'Emergencies', link: `/admin/trusts/${organisation_id}/emergencies` },
        { label: emergency.name },
      ]}>
      <ViewEmergency emergency_id={emergency_id} handleEdit={handleEdit} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ViewEmergencyAdmin));
