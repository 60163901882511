
import { query, params, types, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../react-app-env';

const _categoryInitQuery = (id: string) => ({
  organisations_by_pk: params(
    {
      id: rawString(id),
    },
    {
      name: types.string,
      areas: [{
        id: types.string,
        name: types.string,
        department: types.string,
      }],
      emergencies: [{
        id: types.string,
        name: types.string,
        description: types.string,
      }],
    },
  )
});

type X = Unpacked<typeof _categoryInitQuery>;
export type Organisation = X['organisations_by_pk'];

export const categoryIitQuery = (id: string) => gql`${query(_categoryInitQuery(id))}`;
