import React, { useCallback, useState } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import {
} from '@mui/material';

import CustomStepper from '../../../../../../components/CustomStepper';
import { OrganisationUser, validation, validationErrors, ValidationErrors, OrganisationUserReducerAction, OrganisationUserType } from '../..';
import StepDetails from '../StepDetails';
import StepReview from '../StepReview';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  completeLabel: string,
  user: OrganisationUser,
  userTypes: OrganisationUserType[],
  isEdit?: boolean,
  isAreaUser: boolean,
  dispatch: React.Dispatch<{ type: OrganisationUserReducerAction, value: any }>,
  handleSave: () => void,
}

const OrganisationUserSteps = ({ classes, completeLabel, user, userTypes, isEdit = false, isAreaUser, dispatch, handleSave }: Props): React.ReactElement => {
  const [step, setStep] = useState<number>(0);

  const [errors, setErrors] = useState<ValidationErrors>({
    first_name: false,
    last_name: false,
    email: false,
    email_invalid: false,
  });

  const validate = useCallback((_step: number) => {
    const errorItems: UnknownObject = {}
    if (_step === 0) {
      errorItems.first_name = validation.first_name(user.first_name);
      errorItems.last_name = validation.last_name(user.last_name);
      errorItems.email = validation.email(user.email);
    }
    setErrors({...errors, ...errorItems});
    return (Object.keys(errorItems) as Array<keyof typeof validationErrors>).some((i) => errorItems[i]);
  }, [user, errors]);

  return (
    <>
      <CustomStepper
        step={step}
        isNextDisabled={(Object.keys(errors) as Array<keyof typeof errors>).some((i) => errors[i])}
        completeLabel={completeLabel}
        setStep={setStep}
        validate={validate}
        handleComplete={handleSave} >
        {[
          {
            label: 'Details',
            component:
              <StepDetails
                user={user}
                userTypes={userTypes}
                isEdit={isEdit}
                isAreaUser={isAreaUser}
                errors={errors}
                setErrors={setErrors}
                dispatch={dispatch} />
          },
          {
            label: 'Summary',
            component:
              <StepReview
                user={user}
                userTypes={userTypes}
                setStep={setStep} />
            },
          ]}
        </CustomStepper>
    </>
  );
};

export default withStyles(styles)(OrganisationUserSteps);
