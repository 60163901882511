
import { query, types } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _categoryInitQuery = () => ({
  areas: [{
    id: types.string,
    name: types.string,
    department: types.string,
  }],
  emergencies: [{
    id: types.string,
    name: types.string,
    description: types.string,
  }],
});

type X = Unpacked<typeof _categoryInitQuery>;
export type Area = X['areas'][0];
export type Emergency = X['emergencies'][0];

export const categoryIitQuery = () => gql`${query(_categoryInitQuery())}`;
