import { gql } from '@apollo/client';
import gqlClient from '../../../../../../utils/apolloClient';

const insertStationMutation = gql`
  mutation ($object: area_section_stations_insert_input!) {
    insert_area_section_stations_one(object: $object) {
      id
    }
  }
`;

export const createStation = async (variables: object) => {
  const res = await gqlClient.mutate<{
    insert_area_section_stations_one: {
      id: string,
    }
  }>({ mutation: insertStationMutation!, variables });
  return res.data?.insert_area_section_stations_one.id;
}
