import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { Grid } from '@mui/material';

import ReadOnlyBlock from '../../../../../components/ReadOnlyBlock';
import { selectSectionInitQuery, Section } from './queries';
import { GridCard } from '../../../../../components/Grid';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  section_id: string;
  handleEdit: () => void;
  handleCreate: () => void;
  handleView: () => void;
}

const ViewSection = ({ classes, history, section_id, handleEdit, handleCreate, handleView }: Props): React.ReactElement => {
  const [section, setSection] = useState<Section>({
    id: '',
    name: '',
    phone: '',
    stations_aggregate: { aggregate: { count: 0 } },
  });

  const { data: sectionInitData } = useQuery(selectSectionInitQuery(section_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && sectionInitData) {
      setSection(sectionInitData.area_sections_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [sectionInitData]);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <>
            <ReadOnlyBlock title={section.name} gridSize={3} items={[{ label: 'Phone', value: section.phone || 'No phone number' }]} handleEdit={handleEdit} />
          </>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <GridCard
              fill
              gridSize={4}
              counter={section?.stations_aggregate?.aggregate.count}
              key="Stations"
              title="Bed spaces"
              subTitle="Bedside, entrance, bay, desk"
              editLabel="Create"
              handleEdit={() => handleCreate()}
              handleView={() => handleView()}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(withStyles(styles)(ViewSection));
