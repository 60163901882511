import { query, params, types } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _emergencyRolesInitQuery = () => ({
  emergency_roles: params({ order_by: { name: 'asc' } }, [
    {
      id: types.string,
      name: types.string,
      description: types.string,
      emergency_assignments_aggregate: {
        aggregate: {
          count: types.number,
        },
      },
      active: types.boolean,
    },
  ]),
});

type X = Unpacked<typeof _emergencyRolesInitQuery>;
export type EmergencyRole = X['emergency_roles'][0];

export const emergencyRolesInitQuery = () =>
  gql`
    ${query(_emergencyRolesInitQuery())}
  `;
