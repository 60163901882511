import React, { useState, useEffect, useReducer } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { UnknownObject } from '../../../../react-app-env';

import {
} from '@mui/material';

import CustomBackdrop from '../../../../components/CustomBackdrop';

import { emergencyReducer, EmergencyReducerAction, Emergency, EmergencyAssignment } from '../Common';
import Steps from '../Common/components/Steps';

import { editEmergencyRoleInitQuery } from './queries';
import { updateEmergencyAssignments, updateEmergencyRole } from './mutations';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
  organisation_id?: string,
  emergency_role_id: string,
  handleFinish: () => void,
}

const EditEmergencyRole = ({ classes, history, organisation_id, emergency_role_id, handleFinish }: Props): React.ReactElement => {

  const [emergencyRole, dispatchEmergency] = useReducer(emergencyReducer, {
    name: '',
    description: '',
    emergencies: [],
  });

  const [emergencies, setEmergencies] = useState<Emergency[]>([]);

  const [assignedEmergencies, setAssignedEmergencies] = useState<EmergencyAssignment[]>([]);

  const [saving, setSaving] = useState<boolean>(false);

  const { data: editEmergencyRoleInitData } = useQuery(editEmergencyRoleInitQuery(emergency_role_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editEmergencyRoleInitData) {
      setEmergencies(editEmergencyRoleInitData.emergency_roles_by_pk.organisation.emergencies);
      setAssignedEmergencies(editEmergencyRoleInitData.emergency_roles_by_pk.emergency_assignments);
      dispatchEmergency({ type: EmergencyReducerAction.INIT, value: editEmergencyRoleInitData.emergency_roles_by_pk });
    }
    return () => { mounted = false; };
  }, [editEmergencyRoleInitData]);

  const handleSave = async () => {
    setSaving(true);

    const assignments = emergencyRole.emergencies
      .filter((a: Emergency) => !assignedEmergencies.some((i: EmergencyAssignment) => a.id === i.emergency.id))
      .map((a: Emergency) => ({ emergency_id: a.id, organisation_id, role_id: emergency_role_id }));
    
    const assignmentsRemove = assignedEmergencies
      .filter((i: EmergencyAssignment) => !emergencyRole.emergencies.some((a: Emergency) => a.id === i.emergency.id))
      .map((i: EmergencyAssignment) => i.id);

    const item: UnknownObject = {
      ...emergencyRole,
    };

    delete item.emergencies;

    const mData = {
      ...item,
      organisation_id,
      description: emergencyRole.description || undefined,
    };

    const updateVariables = {
      pk_columns: {
        id: emergency_role_id,
      },
      set: mData,
    };
    await updateEmergencyRole(updateVariables);
    await updateEmergencyAssignments(assignments, assignmentsRemove);

    setSaving(false);
    handleFinish();
  };

  return (
    <>
      <Steps
        completeLabel="Save"
        emergency={emergencyRole}
        emergencies={emergencies}
        dispatch={dispatchEmergency}
        handleSave={handleSave} />
      {saving && <CustomBackdrop label="Saving Changes" />}
    </>
  );
};

export default withRouter(withStyles(styles)(EditEmergencyRole));
