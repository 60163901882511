import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import Dashboard from '../../../../layouts/Dashboard';
import ListOrganisationUsers from '../../../../components/Global/OrganisationUsers/List';

import { selectOrganisationUsersInitQuery, OrganisationUser } from './queries';
import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

const ListOrganisationUsersOrg = ({ classes, history }: Props): React.ReactElement => {
  const [users, setUsers] = useState<OrganisationUser[]>([]);

  const { data: organisationUserInitQuery } = useQuery(selectOrganisationUsersInitQuery(), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && organisationUserInitQuery) {
      setUsers(organisationUserInitQuery.users_organisation);
    }
    return () => {
      mounted = false;
    };
  }, [organisationUserInitQuery]);

  const handleCreate = () => {
    history.push(`/trust/users/create`);
  };

  const handleView = (id: string) => {
    history.push(`/trust/users/${id}`);
  };

  const handleEdit = (id: string) => {
    history.push(`/trust/users/${id}/edit`);
  };

  return (
    <Dashboard breadcrumbs={[{ label: 'Users' }]}>
      <ListOrganisationUsers items={users} handleCreate={handleCreate} handleView={handleView} handleEdit={handleEdit} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ListOrganisationUsersOrg));
