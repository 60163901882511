import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import {} from '@mui/material';

import Dashboard from '../../../../layouts/Dashboard';
import EditArea from '../../../../components/Global/Categories/Edit';

import { editCategoryInitQuery, Category } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  category_id: string;
}

const EditAreaOrg = ({ classes, history }: Props): React.ReactElement => {
  const { category_id } = useParams<UrlParams>();

  const [category, setCategory] = useState<Category>({
    name: '',
  });

  const { data: editCategoryInitData } = useQuery(editCategoryInitQuery(category_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editCategoryInitData) {
      setCategory(editCategoryInitData.categories_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [editCategoryInitData]);

  const handleFinish = () => {
    history.push(`/trust/grouping`);
  };

  return (
    <>
      <Dashboard breadcrumbs={[{ label: 'Categoies', link: `/trust/grouping` }, { label: category.name, link: `/trust/grouping` }, { label: 'Edit' }]}>
        <EditArea category_id={category_id} handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(withStyles(styles)(EditAreaOrg));
