import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    toolbarRoot: {
      backgroundColor: theme.palette.background.paper,
      borderWidth: 0,
      borderBottomWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.primary.main,
    },
    main: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      overflow: 'hidden',
      margin: theme.spacing(2),
      marginLeft: 0,
      backgroundColor: theme.palette.background.paper,
    },
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      overflow: 'auto',
    },
    title: {
      fontSize: 24,
      fontWeight: 'bold',
      color: theme.palette.text.secondary,
    },
  });

export default styles;
