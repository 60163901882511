import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Dashboard from '../../../../layouts/Dashboard';
import EditArea from '../../../../components/Global/Areas/Edit';

import { editAreaInitQuery, Area } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  area_id: string;
}

const EditAreaOrg = ({ classes, history }: Props): React.ReactElement => {
  const { area_id } = useParams<UrlParams>();

  const [area, setArea] = useState<Area>({
    name: '',
    organisation: {
      categories: [],
    },
  });

  const { data: editAreaInitData } = useQuery(editAreaInitQuery(area_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editAreaInitData) {
      setArea(editAreaInitData.areas_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [editAreaInitData]);

  const handleFinish = () => {
    history.push(`/trust/departments/${area_id}`);
  };

  return (
    <>
      <Dashboard breadcrumbs={[{ label: 'Departments', link: `/trust/departments` }, { label: area.name, link: `/trust/departments/${area_id}` }, { label: 'Edit' }]}>
        <EditArea area_id={area_id} categories={area.organisation.categories} handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(withStyles(styles)(EditAreaOrg));
