import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const updateEmergencyMutation = gql`
  mutation ($pk_columns: emergencies_pk_columns_input!, $set: emergencies_set_input!) {
    update_emergencies_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

export const updateEmergency = async (variables: object) => {
  return await gqlClient.mutate<{
    update_emergencies_by_pk: {
      id: string,
    }
  }>({ mutation: updateEmergencyMutation!, variables });
}

const updateCategoryAssignmentsMutation = gql`
  mutation ($categoryAssignments: [category_emergency_assignments_insert_input!]!, $roleAssignments: [emergency_role_assignments_insert_input!]!, $categoryRemove: [uuid!]!, $roleRemove: [uuid!]!) {
    insert_category_emergency_assignments(objects: $categoryAssignments) {
      returning {
        id
      }
    }
    delete_category_emergency_assignments(where: { id: { _in: $categoryRemove }}) {
      affected_rows
    }
    insert_emergency_role_assignments(
      objects: $roleAssignments, 
      on_conflict: { 
        constraint: emergency_role_assignments_pkey, 
        update_columns: [order_index] 
      }
    ) {
      returning {
        id
      }
    }
    delete_emergency_role_assignments(where: { id: { _in: $roleRemove }}) {
      affected_rows
    }
  }
`;

export const updateAssignments = async (categoryAssignments: object[], roleAssignments: object[], categoryRemove: string[], roleRemove: string[]) => {
  return await gqlClient.mutate<{
    insert_category_emergency_assignments: {
      returning: [{
        id: string,
      }],
    }
    delete_category_emergency_assignments: {
      affected_rows: number
    }
  }>({ mutation: updateCategoryAssignmentsMutation!, variables: { categoryAssignments, roleAssignments, categoryRemove, roleRemove } });
}