import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const updateCategoryMutation = gql`
  mutation ($pk_columns: categories_pk_columns_input!, $set: categories_set_input!) {
    update_categories_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

export const updateCategory = async (variables: object) => {
  return await gqlClient.mutate<{
    update_categories_by_pk: {
      id: string,
    }
  }>({ mutation: updateCategoryMutation!, variables });
}

const updateAreaAssignmentsMutation = gql`
  mutation (
    $areaAssignments: [category_area_assignments_insert_input!]!,
    $areaRemove: [uuid!]!
    $emAssignments: [category_emergency_assignments_insert_input!]!,
    $emRemove: [uuid!]!
  ) {
    insert_category_area_assignments(objects: $areaAssignments) {
      returning {
        id
      }
    }
    delete_category_area_assignments(where: { id: { _in: $areaRemove }}) {
      affected_rows
    }
    insert_category_emergency_assignments(objects: $emAssignments) {
      returning {
        id
      }
    }
    delete_category_emergency_assignments(where: { id: { _in: $emRemove }}) {
      affected_rows
    }
  }
`;

export const updateAreaAssignments = async (areaAssignments: object[], emAssignments: object[], areaRemove: string[], emRemove: string[]) => {
  return await gqlClient.mutate<{
    insert_category_area_assignments: {
      returning: [{
        id: string,
      }],
    }
    delete_category_area_assignments: {
      affected_rows: number
    }
    insert_category_emergency_assignments: {
      returning: [{
        id: string,
      }],
    }
    delete_category_emergency_assignments: {
      affected_rows: number
    }
  }>({ mutation: updateAreaAssignmentsMutation!, variables: { areaAssignments, emAssignments, areaRemove, emRemove } });
}