import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Dashboard from '../../../../../layouts/Dashboard';

import CreateEmergency from '../../../../../components/Global/Emergencies/Create';

import { emergencyInitQuery, Organisation } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  organisation_id: string;
}

const CreateEmergencyAdmin = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id } = useParams<UrlParams>();

  const [organisation, setOrganisation] = useState<Organisation>({
    name: '',
    categories: [],
    emergency_roles: [],
  });

  const { data: emergencyInitData } = useQuery(emergencyInitQuery(organisation_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && emergencyInitData) {
      setOrganisation(emergencyInitData.organisations_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [emergencyInitData]);

  const handleFinish = (id: string) => {
    history.push(`/admin/trusts/${organisation_id}/emergencies`);
  };

  return (
    <>
      <Dashboard
        breadcrumbs={[
          { label: 'Trusts', link: '/admin/trusts' },
          { label: organisation.name, link: `/admin/trusts/${organisation_id}` },
          { label: 'Emergencies', link: `/admin/trusts/${organisation_id}/emergencies` },
          { label: 'Create' },
        ]}>
        <CreateEmergency organisation_id={organisation_id} categories={organisation.categories} emergencyRoles={organisation.emergency_roles} handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(withStyles(styles)(CreateEmergencyAdmin));
