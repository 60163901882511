import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import Dashboard from '../../../../layouts/Dashboard';
import CreateSection from '../../../../components/Global/Areas/Sections/Create';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

const CreateSectionArea = ({ classes, history }: Props): React.ReactElement => {
  const handleFinish = (id: string) => {
    history.push(`/department/wards/${id}`);
  };

  return (
    <>
      <Dashboard breadcrumbs={[{ label: 'Wards', link: `/department/wards` }, { label: 'Create' }]}>
        <CreateSection handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(withStyles(styles)(CreateSectionArea));
