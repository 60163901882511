
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../../react-app-env';

const _editStationInitQuery = (id: string) => ({
  area_section_stations_by_pk: params(
    {
      id: rawString(id),
    },
    {
      name: types.string,
      section: {
        name: types.string,
      },
      area: {
        name: types.string,
      },
    },
  )
});

type X = Unpacked<typeof _editStationInitQuery>;
export type Station = X['area_section_stations_by_pk'];

export const editStationInitQuery = (id: string) => gql`${query(_editStationInitQuery(id))}`;
