import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import Dashboard from '../../../../../layouts/Dashboard';
import ViewArea from '../../../../../components/Global/Areas/View';
import { selectAreaInitQuery, Area } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  organisation_id: string;
  area_id: string;
}

const ViewAreaAdmin = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id, area_id } = useParams<UrlParams>();

  const [area, setArea] = useState<Area>({
    name: '',
    organisation: {
      name: '',
    },
  });

  const { data: areaInitData } = useQuery(selectAreaInitQuery(area_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && areaInitData) {
      setArea(areaInitData.areas_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [areaInitData]);

  const handleEdit = () => {
    history.push(`/admin/trusts/${organisation_id}/departments/${area_id}/edit`);
  };

  const handleCreate = (view: string) => {
    history.push(`/admin/trusts/${organisation_id}/departments/${area_id}/${view}/create`);
  };

  const handleView = (view: string) => {
    history.push(`/admin/trusts/${organisation_id}/departments/${area_id}/${view}`);
  };

  return (
    <Dashboard
      breadcrumbs={[
        { label: 'Trusts', link: '/admin/trusts' },
        { label: area.organisation.name, link: `/admin/trusts/${organisation_id}` },
        { label: 'Departments', link: `/admin/trusts/${organisation_id}/departments` },
        { label: area.name },
      ]}>
      <ViewArea area_id={area_id} handleEdit={handleEdit} handleCreate={handleCreate} handleView={handleView} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ViewAreaAdmin));
