import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Grid } from '@mui/material';

import UsersList from '../../../../components/Global/Users/List';

import { OrganisationUserItem } from '../Common';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  items: OrganisationUserItem[];
  note?: string;
  handleCreate: () => void;
  handleView: (id: string) => void;
  handleEdit: (id: string) => void;
  handleReset?: (id: string) => void;
}

const ListOrganisationUsers = ({ classes, history, items, note, handleCreate, handleView, handleEdit, handleReset }: Props): React.ReactElement => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <UsersList
          users={items.map((i) => ({ id: i.id, full_name: i.full_name, email: i.email, active: i.active }))}
          gridSize={4}
          handleCreate={handleCreate}
          handleEdit={handleEdit}
          handleView={handleView}
          handleReset={handleReset}
          note={note}
        />
      </Grid>
    </Grid>
  );
};

export default withRouter(withStyles(styles)(ListOrganisationUsers));
