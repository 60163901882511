import React from 'react';
import { ClassNameMap } from '@mui/styles/withStyles';

import { WithStyles, withStyles } from '@mui/styles';

import { Paper, Grid, Typography } from '@mui/material';


import { DndContext, closestCenter, DragEndEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';

import SortableItem from './SortableItem';
import { EmergencyReducerAction, EmergencyRole } from '../..';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  items: EmergencyRole[],
  dispatch: React.Dispatch<{ type: EmergencyReducerAction, value: any }>,
}

const StepRoles = ({ classes, items, dispatch }: Props): React.ReactElement => {

  const handleMove = (e: DragEndEvent) => {

    const { active, over } = e;

    if (over !== null && active.id !== over.id) {
      const activeIndex = items.findIndex((i) => i.id === active.id);
      const overIndex = items.findIndex((i) => i.id === over.id);

      dispatch({
        type: EmergencyReducerAction.UPDATE_ROLES_ORDER,
        value: arrayMove(items, activeIndex, overIndex),
      });
    }
  };
  return (
    <>
        <Grid container spacing={4}>
          <DndContext
            collisionDetection={closestCenter}
            onDragEnd={handleMove}>
            <SortableContext items={items.map((i: EmergencyRole) => i.id)} strategy={rectSortingStrategy}>
              {items.map((i: EmergencyRole, index) => (
                <Grid item xs={4}>
                  <div className={classes.imgWraps}>
                    <SortableItem id={i.id} key={`role-${index}`}>
                      {/* <Box className={classes.box} /> */}
                      <Paper variant='outlined' className={classes.box} >
                        <Typography>{i.name}</Typography>
                     </Paper> 
                    </SortableItem>
                  </div>
                </Grid>
              ))}
            </SortableContext>
            {/* <DragOverlay>{currentDragged && <Box component="img" className={classes.image} src={currentDragged} />}</DragOverlay> */}
          </DndContext>
        </Grid>
    </>
  );
};

export default withStyles(styles)(StepRoles);
