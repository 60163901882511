import { params, query, types } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _categoriesInitQuery = () => ({
  categories: [
    {
      id: types.string,
      name: types.string,
      description: types.string,
      emergency_assignments: params(
        {
          where: { emergency: { active: { _eq: true } } },
        },
        [
          {
            id: types.string,
            emergency: {
              id: types.string,
              name: types.string,
              description: types.string,
            },
          },
        ]
      ),
      area_assignments: params(
        {
          where: { area: { active: { _eq: true } } },
        },
        [
          {
            id: types.string,
            area: {
              id: types.string,
              name: types.string,
              phone: types.string,
              department: types.string,
            },
          },
        ]
      ),
      active: types.boolean,
    },
  ],
});

type X = Unpacked<typeof _categoriesInitQuery>;
export type Category = X['categories'][0];

export const categoriesInitQuery = () =>
  gql`
    ${query(_categoriesInitQuery())}
  `;
