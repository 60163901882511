
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _selectSectionInitQuery = (id: string) => ({
  area_sections_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
    }
  ),
});

type X = Unpacked<typeof _selectSectionInitQuery>
export type Section = X['area_sections_by_pk'];

export const selectSectionInitQuery = (id: string) => gql`${query(_selectSectionInitQuery(id))}`;
