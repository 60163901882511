
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _editAreaInitQuery = (id: string) => ({
  areas_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
      organisation: {
        categories: [{
          id: types.string,
          name: types.string,
        }],
      },
    },
  ),
});

type X = Unpacked<typeof _editAreaInitQuery>;
export type Area = X['areas_by_pk'];

export const editAreaInitQuery = (id: string) => gql`${query(_editAreaInitQuery(id))}`;
