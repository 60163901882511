import React, { useState } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';

import { AddCircle as AddCircleIcon } from '@mui/icons-material';

import CustomSortableTable, { SortableTableAction, SortableTableFilter, SortableTableHeader, SortableTableRow } from '../../../../components/CustomSortableTable';

import { EmergencyRoleItem } from '../Common';

import styles from './styles';
import CustomDialog from '../../../CustomDialog';
import { booleanToYesNo } from '../../../../utils/stringUtils';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  items: EmergencyRoleItem[];
  handleCreate: () => void;
  handleEdit: (id: string) => void;
  handleActiveStatusChange: (id: string, isActive: boolean) => Promise<void>;
}
const ListEmergencyRoles = ({ classes, history, items, handleCreate, handleEdit, handleActiveStatusChange }: Props): React.ReactElement => {
  const [activeFiltered, setActiveFiltered] = useState<boolean>(true);
  const [recordStatusState, setRecordStatusState] = useState<{
    dialogOpen: boolean;
    emergencyRoleId: string;
    isActive: boolean;
    isLoading: boolean;
  }>({
    dialogOpen: false,
    emergencyRoleId: '',
    isActive: false,
    isLoading: false,
  });

  const deactivateEmergencyRole = async () => {
    setRecordStatusState((prev) => ({ ...prev, isLoading: true }));
    await handleActiveStatusChange(recordStatusState.emergencyRoleId, recordStatusState.isActive);
    setRecordStatusState((prev) => ({ ...prev, isLoading: false, dialogOpen: false }));
  };

  const headers: SortableTableHeader[] = [
    { key: 'name', label: 'Name' },
    { key: 'description', label: 'Description' },
    { key: 'emergencies', label: 'Emergencies', align: 'center' },
    { key: 'active', label: 'Active' },
  ];

  const rows: SortableTableRow[] = items.map((emergencyRole: EmergencyRoleItem) => ({
    key: emergencyRole.id,
    actions: [
      {
        label: emergencyRole.active ? 'Deactivate' : 'Activate',
        destructive: emergencyRole.active,
        onClick: () => setRecordStatusState((prev) => ({ ...prev, emergencyRoleId: emergencyRole.id, isActive: !emergencyRole.active, dialogOpen: true })),
      },
      {
        label: 'Edit',
        onClick: () => handleEdit(emergencyRole.id),
      },
    ],
    columns: [
      {
        key: 'name',
        label: emergencyRole.name,
      },
      {
        key: 'description',
        label: emergencyRole.description,
      },
      {
        key: 'emergencies',
        label: emergencyRole.emergency_assignments_aggregate.aggregate.count,
      },
      {
        key: 'active',
        label: booleanToYesNo(emergencyRole.active),
      },
    ],
  }));

  const actions: SortableTableAction[] = [
    {
      key: 'create',
      label: 'Create emergency role',
      icon: <AddCircleIcon />,
      onClick: () => handleCreate(),
    },
  ];

  const activeFilter: SortableTableFilter = {
    key: 'activeFilter',
    label: 'Active',
    filter: (rows: SortableTableRow[]) =>
      rows.filter((r) => {
        const col = r.columns.find((c) => c.key === 'active');
        if (col) {
          return activeFiltered ? col.label === 'Yes' : col.label === 'No';
        }
        return true;
      }),
    component: (
      <ToggleButtonGroup className={classes.activeFilter} size="medium" value={[activeFiltered]} color="primary" onChange={() => setActiveFiltered(!activeFiltered)}>
        <ToggleButton className={classes.toggleButton} key="active" value={true}>
          Active
        </ToggleButton>
        <ToggleButton className={classes.toggleButton} key="inactive" value={false}>
          Inactive
        </ToggleButton>
      </ToggleButtonGroup>
    ),
  };

  const filters = [activeFilter];

  const filterRows = (): SortableTableRow[] => {
    let filteredRows: SortableTableRow[] = rows;
    filters.forEach((f) => {
      filteredRows = f.filter(filteredRows);
    });
    return filteredRows;
  };

  return (
    <>
      <Grid container spacing={4} alignItems="stretch">
        <Grid item xs={12}>
          <CustomSortableTable
            title="Emergency roles"
            orderdBy="name"
            ordered="asc"
            actions={actions}
            headers={headers}
            counter={rows.length}
            rows={filterRows()}
            filters={filters}
          />
        </Grid>
      </Grid>
      <CustomDialog
        open={recordStatusState.dialogOpen}
        title="Update Emergency Role"
        message={`Are you sure you want to ${recordStatusState.isActive ? 'activate' : 'deactivate'} this emergency role?`}
        handleClose={() => setRecordStatusState((prev) => ({ ...prev, dialogOpen: false }))}
        actions={[
          { label: 'No', onClick: () => setRecordStatusState((prev) => ({ ...prev, dialogOpen: false })) },
          { label: 'Yes', onClick: () => deactivateEmergencyRole() },
        ]}
      />
    </>
  );
};

export default withRouter(withStyles(styles)(ListEmergencyRoles));
