import { gql } from '@apollo/client';
import { Questionnaire } from '../../../../../components/Global/EraCareQuestionnaire/Common';

export const getOrganisation = gql`
  query ($id: uuid!) {
    organisations_by_pk(id: $id) {
      name
    }
  }
`;

export interface GetOrganisationResponse {
  organisations_by_pk: {
    name: string;
  };
}

export const getQuestionnaire = gql`
  query ($id: uuid!) {
    questionnaires_by_pk(id: $id) {
      id
      name
      categories
    }
  }
`;

export interface GetQuestionnaireResponse {
  questionnaires_by_pk: Questionnaire;
}
