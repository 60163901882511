import { types, query, params, rawString, alias } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _selectOrganisationInitQuery = (id: string) => ({
  organisations_by_pk: params(
    { id: rawString(id) },
    {
      id: types.string,
      name: types.string,
      postcode: types.string,
      users_aggregate: params(
        {
          where: { user_type: { _eq: 'ORG' } },
        },
        {
          aggregate: {
            count: types.number,
          },
        }
      ),
      areas_aggregate: {
        aggregate: {
          count: types.number,
        },
      },
      categories_aggregate: {
        aggregate: {
          count: types.number,
        },
      },
      emergencies_aggregate: {
        aggregate: {
          count: types.number,
        },
      },
      emergency_roles_aggregate: {
        aggregate: {
          count: types.number,
        },
      },
      events_aggregate: params(
        {
          where: { organisation_id: { _eq: rawString(id) } },
        },
        {
          aggregate: {
            count: types.number,
          },
        }
      ),
      questionnaires_aggregate: params(
        {
          where: { organisation_id: { _eq: rawString(id) } },
        },
        {
          aggregate: {
            count: types.number,
          },
        }
      ),
      [alias('missing_patients_info', 'events_aggregate')]: params(
        {
          where: {
            _and: [
              {
                organisation_id: { _eq: rawString(id) },
              },
              {
                _not: { patient: {} },
              },
              {
                notification_closed: { _eq: false },
              },
            ],
          },
        },
        {
          aggregate: {
            count: types.number,
          },
        }
      ),
    }
  ),
});

type X = Unpacked<typeof _selectOrganisationInitQuery>;
export type Organisation = X['organisations_by_pk'];

export const selectOrganisationInitQuery = (id: string) =>
  gql`
    ${query(_selectOrganisationInitQuery(id))}
  `;
