import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import Dashboard from '../../../../../layouts/Dashboard';
import ViewSection from '../../../../../components/Global/Areas/Sections/View';
import { selectSectionInitQuery, Section } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  area_id: string;
  section_id: string;
}

const ViewSectionOrg = ({ classes, history }: Props): React.ReactElement => {
  const { area_id, section_id } = useParams<UrlParams>();

  const [section, setSection] = useState<Section>({
    name: '',
    area: {
      name: '',
    },
  });

  const { data: sectionInitData } = useQuery(selectSectionInitQuery(section_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && sectionInitData) {
      setSection(sectionInitData.area_sections_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [sectionInitData]);

  const handleEdit = () => {
    history.push(`/trust/departments/${area_id}/wards/${section_id}/edit`);
  };

  const handleCreate = () => {
    history.push(`/trust/departments/${area_id}/wards/${section_id}/bed-spaces/create`);
  };

  const handleView = () => {
    history.push(`/trust/departments/${area_id}/wards/${section_id}/bed-spaces`);
  };

  return (
    <Dashboard
      breadcrumbs={[
        { label: 'Departments', link: `/trust/departments` },
        { label: section.area.name, link: `/trust/departments/${area_id}` },
        { label: 'Wards', link: `/trust/departments/${area_id}/wards` },
        { label: section.name },
      ]}>
      <ViewSection section_id={section_id} handleEdit={handleEdit} handleCreate={handleCreate} handleView={handleView} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ViewSectionOrg));
