import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Admin
import {
  Home as AdminHome,

  // Users
  ListAdminUsers as AdminListUsers,
  CreateAdminUser as AdminCreateUser,
  ViewAdminUser as AdminViewUser,
  EditAdminUser as AdminEditUser,

  // Organisations
  ListOrganisations as AdminOrganisations,
  CreateOrganisation as AdminCreateOrganisation,
  ViewOrganisation as AdminViewOrganisation,
  EditOrganisation as AdminEditOrganisation,

  // Org Users
  ListOrganisationUsers as AdminListOrganisationUsers,
  CreateOrganisationUser as AdminCreateOrganisationUser,
  ViewOrganisationUser as AdminViewOrganisationUser,
  EditOrganisationUser as AdminEditOrganisationUser,

  // Areas
  ListAreas as AdminListAreas,
  CreateArea as AdminCreateArea,
  ViewArea as AdminViewArea,
  EditArea as AdminEditArea,

  // Area Users
  ListAreaUsers as AdminListAreaUsers,
  CreateAreaUser as AdminCreateAreaUser,
  ViewAreaUser as AdminViewAreaUser,
  EditAreaUser as AdminEditAreaUser,

  // Sections
  ListSections as AdminListSections,
  CreateSection as AdminCreateSection,
  ViewSection as AdminViewSection,
  EditSection as AdminEditSection,

  // Stations
  ListStations as AdminListStations,
  CreateStation as AdminCreateStation,
  ViewStation as AdminViewStation,
  EditStation as AdminEditStation,

  // Categories
  ListCategories as AdminListCategories,
  CreateCategory as AdminCreateCategory,
  ViewCategory as AdminViewCategory,
  EditCategory as AdminEditCategory,

  // Emergencies
  ListEmergencies as AdminListEmergencies,
  CreateEmergency as AdminCreateEmergency,
  ViewEmergency as AdminViewEmergency,
  EditEmergency as AdminEditEmergency,

  // Emergency Roles
  ListEmergencyRoles as AdminListEmergencyRoles,
  CreateEmergencyRole as AdminCreateEmergencyRole,
  ViewEmergencyRole as AdminViewEmergencyRole,
  EditEmergencyRole as AdminEditEmergencyRole,

  // Events
  ListOrganisationEvents as AdminListOrganisationEvents,

  // ERA Care
  ListEraCareQuestionnaire as AdminListEraCareQuestionnaire,
  CreateEraCareQuestionnaire as AdminCreateEraCareQuestionnaire,
  EditEraCareQuestionnaire as AdminEditEraCareQuestionnaire,
} from './views/Admin';

// Org
import {
  Home as OrgHome,

  // Users
  ListOrganisationUsers as OrgListUsers,
  CreateOrganisationUser as OrgCreateUser,
  ViewOrganisationUser as OrgViewUser,
  EditOrganisationUser as OrgEditUser,

  // Areas
  ListAreas as OrgListAreas,
  CreateArea as OrgCreateArea,
  ViewArea as OrgViewArea,
  EditArea as OrgEditArea,

  // Area Users
  ListAreaUsers as OrgListAreaUsers,
  CreateAreaUser as OrgCreateAreaUser,
  ViewAreaUser as OrgViewAreaUser,
  EditAreaUser as OrgEditAreaUser,

  // Sections
  ListSections as OrgListSections,
  CreateSection as OrgCreateSection,
  ViewSection as OrgViewSection,
  EditSection as OrgEditSection,

  // Stations
  ListStations as OrgListStations,
  CreateStation as OrgCreateStation,
  ViewStation as OrgViewStation,
  EditStation as OrgEditStation,

  // Categories
  ListCategories as OrgListCategories,
  CreateCategory as OrgCreateCategory,
  ViewCategory as OrgViewCategory,
  EditCategory as OrgEditCategory,

  // Emergencies
  ListEmergencies as OrgListEmergencies,
  CreateEmergency as OrgCreateEmergency,
  ViewEmergency as OrgViewEmergency,
  EditEmergency as OrgEditEmergency,

  // Emergency Roles
  ListEmergencyRoles as OrgListEmergencyRoles,
  CreateEmergencyRole as OrgCreateEmergencyRole,
  ViewEmergencyRole as OrgViewEmergencyRole,
  EditEmergencyRole as OrgEditEmergencyRole,

  // Events
  ListEvents as OrgListEvents,

  // ERA Care
  ListEraCareQuestionnaire as OrgListEraCareQuestionnaire,
  CreateEraCareQuestionnaire as OrgCreateEraCareQuestionnaire,
  EditEraCareQuestionnaire as OrgEditEraCareQuestionnaire,
} from './views/Organisation';

// Area
import {
  Home as AreaHome,

  // Sections
  ListSections as AreaListSections,
  CreateSection as AreaCreateSection,
  ViewSection as AreaViewSection,
  EditSection as AreaEditSection,

  // Stations
  ListStations as AreaListStations,
  CreateStation as AreaCreateStation,
  ViewStation as AreaViewStation,
  EditStation as AreaEditStation,

  // Events
  ListEventsArea as AreaListEvents,

  // ERA Care
  ListEraCareEvents as AreaListEraCareEvents,
  CreateEraCareEvents as AreaCreateEraCareEvents,
  EditEraCareEvents as AreaEditEraCareEvents,
} from './views/Area';

// Public
import { SignIn, InitialSignIn, ResetPassword, Splash, EraCareUnsubscribe, EraCareQuestionnaireComplete, EraCareResults } from './views/Public';

import { NotAuthorised, NotFound, ProtectedRoleRoute } from './components/Routing';
import { adminRoles, orgRoles, areaRoles } from './components/AuthProvider';

const Routes = () => (
  <Switch>
    {/* ---------- public ---------- */}
    <Route exact path="/sign-in" component={SignIn} />

    <Route exact path="/initial-sign-in" component={InitialSignIn} />
    <Route exact path="/reset-password" component={ResetPassword} />

    <Route exact path="/" component={Splash} />

    {/* -------- era care -------- */}
    <Route exact path="/era-care/unsubscribe/:notification_id" component={EraCareUnsubscribe} />
    <Route
      exact
      path="/trusts/:organisation_id/departments/:area_id/era-care/:era_care_event_id/questionnaire/:era_care_notification_id"
      component={EraCareQuestionnaireComplete}
    />
    <Route exact path="/era-care/:organisation_id/view/:era_care_event_id" component={EraCareResults} />

    {/* ---------- admin ---------- */}
    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/home" component={AdminHome} />

    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/users" component={AdminListUsers} />
    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/users/create" component={AdminCreateUser} />
    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/users/:admin_user_id" component={AdminViewUser} />
    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/users/:admin_user_id/edit" component={AdminEditUser} />

    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts" component={AdminOrganisations} />
    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/create" component={AdminCreateOrganisation} />
    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id" component={AdminViewOrganisation} />
    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id/edit" component={AdminEditOrganisation} />

    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id/users" component={AdminListOrganisationUsers} />
    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id/users/create" component={AdminCreateOrganisationUser} />
    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id/users/:organisation_user_id" component={AdminViewOrganisationUser} />
    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id/users/:organisation_user_id/edit" component={AdminEditOrganisationUser} />

    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id/departments" component={AdminListAreas} />
    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id/departments/create" component={AdminCreateArea} />
    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id/departments/:area_id" component={AdminViewArea} />
    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id/departments/:area_id/edit" component={AdminEditArea} />

    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id/departments/:area_id/users" component={AdminListAreaUsers} />
    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id/departments/:area_id/users/create" component={AdminCreateAreaUser} />
    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id/departments/:area_id/users/:area_user_id" component={AdminViewAreaUser} />
    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id/departments/:area_id/users/:area_user_id/edit" component={AdminEditAreaUser} />

    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id/departments/:area_id/wards" component={AdminListSections} />
    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id/departments/:area_id/wards/create" component={AdminCreateSection} />
    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id/departments/:area_id/wards/:section_id" component={AdminViewSection} />
    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id/departments/:area_id/wards/:section_id/edit" component={AdminEditSection} />

    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id/departments/:area_id/wards/:section_id/bed-spaces" component={AdminListStations} />
    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id/departments/:area_id/wards/:section_id/bed-spaces/create" component={AdminCreateStation} />
    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id/departments/:area_id/wards/:section_id/bed-spaces/:station_id" component={AdminViewStation} />
    <ProtectedRoleRoute
      roles={adminRoles}
      exact
      path="/admin/trusts/:organisation_id/departments/:area_id/wards/:section_id/bed-spaces/:station_id/edit"
      component={AdminEditStation}
    />

    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id/grouping" component={AdminListCategories} />
    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id/grouping/create" component={AdminCreateCategory} />
    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id/grouping/:category_id" component={AdminViewCategory} />
    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id/grouping/:category_id/edit" component={AdminEditCategory} />

    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id/emergencies" component={AdminListEmergencies} />
    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id/emergencies/create" component={AdminCreateEmergency} />
    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id/emergencies/:emergency_id" component={AdminViewEmergency} />
    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id/emergencies/:emergency_id/edit" component={AdminEditEmergency} />

    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id/emergency_roles" component={AdminListEmergencyRoles} />
    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id/emergency_roles/create" component={AdminCreateEmergencyRole} />
    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id/emergency_roles/:emergency_role_id" component={AdminViewEmergencyRole} />
    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id/emergency_roles/:emergency_role_id/edit" component={AdminEditEmergencyRole} />

    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id/reports" component={AdminListOrganisationEvents} />

    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id/care-questionnaires" component={AdminListEraCareQuestionnaire} />
    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id/care-questionnaires/create" component={AdminCreateEraCareQuestionnaire} />
    <ProtectedRoleRoute roles={adminRoles} exact path="/admin/trusts/:organisation_id/care-questionnaires/:questionnaire_id/edit" component={AdminEditEraCareQuestionnaire} />

    {/* ---------- organisation ---------- */}
    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/home" component={OrgHome} />

    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/users" component={OrgListUsers} />
    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/users/create" component={OrgCreateUser} />
    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/users/:organisation_user_id" component={OrgViewUser} />
    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/users/:organisation_user_id/edit" component={OrgEditUser} />

    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/departments" component={OrgListAreas} />
    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/departments/create" component={OrgCreateArea} />
    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/departments/:area_id" component={OrgViewArea} />
    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/departments/:area_id/edit" component={OrgEditArea} />

    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/departments/:area_id/wards" component={OrgListSections} />
    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/departments/:area_id/wards/create" component={OrgCreateSection} />
    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/departments/:area_id/wards/:section_id" component={OrgViewSection} />
    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/departments/:area_id/wards/:section_id/edit" component={OrgEditSection} />

    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/departments/:area_id/users" component={OrgListAreaUsers} />
    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/departments/:area_id/users/create" component={OrgCreateAreaUser} />
    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/departments/:area_id/users/:area_user_id" component={OrgViewAreaUser} />
    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/departments/:area_id/users/:area_user_id/edit" component={OrgEditAreaUser} />

    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/departments/:area_id/wards/:section_id/bed-spaces" component={OrgListStations} />
    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/departments/:area_id/wards/:section_id/bed-spaces/create" component={OrgCreateStation} />
    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/departments/:area_id/wards/:section_id/bed-spaces/:station_id" component={OrgViewStation} />
    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/departments/:area_id/wards/:section_id/bed-spaces/:station_id/edit" component={OrgEditStation} />

    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/grouping" component={OrgListCategories} />
    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/grouping/create" component={OrgCreateCategory} />
    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/grouping/:category_id" component={OrgViewCategory} />
    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/grouping/:category_id/edit" component={OrgEditCategory} />

    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/emergencies" component={OrgListEmergencies} />
    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/emergencies/create" component={OrgCreateEmergency} />
    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/emergencies/:emergency_id" component={OrgViewEmergency} />
    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/emergencies/:emergency_id/edit" component={OrgEditEmergency} />

    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/emergency_roles" component={OrgListEmergencyRoles} />
    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/emergency_roles/create" component={OrgCreateEmergencyRole} />
    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/emergency_roles/:emergency_role_id" component={OrgViewEmergencyRole} />
    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/emergency_roles/:emergency_role_id/edit" component={OrgEditEmergencyRole} />

    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/reports" component={OrgListEvents} />

    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/care-questionnaires" component={OrgListEraCareQuestionnaire} />
    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/care-questionnaires/create" component={OrgCreateEraCareQuestionnaire} />
    <ProtectedRoleRoute roles={orgRoles} exact path="/trust/care-questionnaires/:questionnaire_id/edit" component={OrgEditEraCareQuestionnaire} />

    {/* ---------- area ---------- */}
    <ProtectedRoleRoute roles={areaRoles} exact path="/department/home" component={AreaHome} />

    <ProtectedRoleRoute roles={areaRoles} exact path="/department/wards" component={AreaListSections} />
    <ProtectedRoleRoute roles={areaRoles} exact path="/department/wards/create" component={AreaCreateSection} />
    <ProtectedRoleRoute roles={areaRoles} exact path="/department/wards/:section_id" component={AreaViewSection} />
    <ProtectedRoleRoute roles={areaRoles} exact path="/department/wards/:section_id/edit" component={AreaEditSection} />

    <ProtectedRoleRoute roles={areaRoles} exact path="/department/wards/:section_id/bed-spaces" component={AreaListStations} />
    <ProtectedRoleRoute roles={areaRoles} exact path="/department/wards/:section_id/bed-spaces/create" component={AreaCreateStation} />
    <ProtectedRoleRoute roles={areaRoles} exact path="/department/wards/:section_id/bed-spaces/:station_id" component={AreaViewStation} />
    <ProtectedRoleRoute roles={areaRoles} exact path="/department/wards/:section_id/bed-spaces/:station_id/edit" component={AreaEditStation} />

    <ProtectedRoleRoute roles={areaRoles} exact path="/department/reports" component={AreaListEvents} />

    <ProtectedRoleRoute roles={areaRoles} exact path="/department/care-events" component={AreaListEraCareEvents} />
    <ProtectedRoleRoute roles={areaRoles} exact path="/department/care-events/create" component={AreaCreateEraCareEvents} />
    <ProtectedRoleRoute roles={areaRoles} exact path="/department/care-events/:era_care_event_id/edit" component={AreaEditEraCareEvents} />

    <Route exact path="/not-authorised" component={NotAuthorised} />
    <Route exact component={NotFound} />
  </Switch>
);

export default Routes;
