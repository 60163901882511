import { query, params, types } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _emergenciesInitQuery = () => ({
  emergencies: params({ order_by: { priority_index: 'asc' } }, [
    {
      id: types.string,
      name: types.string,
      description: types.string,
      category_assignments: params(
        {
          where: { category: { active: { _eq: true } } },
        },
        [
          {
            id: types.string,
            category: {
              id: types.string,
              name: types.string,
              description: types.string,
            },
          },
        ]
      ),
      role_assignments: params(
        {
          where: { role: { active: { _eq: true } } },
        },
        [
          {
            id: types.string,
            order_index: types.number,
            role: {
              id: types.string,
              name: types.string,
              description: types.string,
            },
          },
        ]
      ),
      active: types.boolean,
      priority_index: types.number,
    },
  ]),
});

type X = Unpacked<typeof _emergenciesInitQuery>;
export type Emergency = X['emergencies'][0];

export const emergenciesInitQuery = () =>
  gql`
    ${query(_emergenciesInitQuery())}
  `;
