import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const updateEmergencyMutation = gql`
  mutation ($pk_columns: emergencies_pk_columns_input!, $set: emergencies_set_input!) {
    update_emergencies_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

export const updateEmergency = async (variables: object) => {
  return await gqlClient.mutate<{
    update_emergencies_by_pk: {
      id: string;
    };
  }>({ mutation: updateEmergencyMutation!, variables });
};

const updateEmergenciesMutation = gql`
  mutation ($emergencies: [emergencies_insert_input!]!) {
    insert_emergencies(objects: $emergencies, on_conflict: { constraint: emergencies_pkey, update_columns: [priority_index] }) {
      returning {
        id
      }
    }
  }
`;

export const updateEmergencies = async (emergencies: object[]) => {
  return await gqlClient.mutate<{
    insert_emergencies: {
      returning: [
        {
          id: string;
        }
      ];
    };
  }>({ mutation: updateEmergenciesMutation!, variables: { emergencies } });
};
