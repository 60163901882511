import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const updateEmergencyRoleMutation = gql`
  mutation ($pk_columns: emergency_roles_pk_columns_input!, $set: emergency_roles_set_input!) {
    update_emergency_roles_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

export const updateEmergencyRole = async (variables: object) => {
  return await gqlClient.mutate<{
    update_emergency_roles_by_pk: {
      id: string;
    };
  }>({ mutation: updateEmergencyRoleMutation!, variables });
};
