import { query, params, types, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../react-app-env';

const _emergencyRolesInitQuery = (id: string) => ({
  organisations_by_pk: params(
    {
      id: rawString(id),
    },
    {
      name: types.string,
      emergency_roles: params({ order_by: { name: 'asc' } }, [
        {
          id: types.string,
          name: types.string,
          description: types.string,
          emergency_assignments_aggregate: {
            aggregate: {
              count: types.number,
            },
          },
          active: types.boolean,
        },
      ]),
    }
  ),
});

type X = Unpacked<typeof _emergencyRolesInitQuery>;
export type Organisation = X['organisations_by_pk'];
export type EmergencyRole = Organisation['emergency_roles'][0];

export const emergencyRolesInitQuery = (id: string) =>
  gql`
    ${query(_emergencyRolesInitQuery(id))}
  `;
