import React, { useState } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { pluralise } from '../../../../utils/stringUtils';

import { Avatar, Grid, GridSize, IconButton, InputAdornment, InputBase, Paper, Tooltip, Typography } from '@mui/material';

import { Search as SearchIcon, Clear as ClearSearchIcon } from '@mui/icons-material';

import { ToggleButton, ToggleButtonGroup } from '@mui/lab';

import { GridCard, GridActionCard } from '../../../Grid';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  users: User[];
  userTypes?: UserType[];
  gridSize?: GridSize;
  note?: string;
  showActiveFilter?: boolean;
  handleCreate: () => void;
  handleEdit: (id: string) => void;
  handleView: (id: string) => void;
  handleReset?: (id: string) => void;
}

interface User {
  id: string;
  full_name: string;
  user_type?: UserType;
  email: string;
  active?: boolean;
}

interface UserType {
  user_type: string;
  description: string;
}

const UsersList = ({
  classes,
  users,
  userTypes = [],
  gridSize = 4,
  note,
  showActiveFilter = true,
  handleCreate,
  handleEdit,
  handleView,
  handleReset,
}: Props): React.ReactElement => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [usersFilter, setUsersFilter] = useState<string[]>([]);
  const [activeFilter, setActiveFilter] = useState<boolean>(true);

  const filterUsers = () => {
    return users
      .filter((i) => i.full_name.toLowerCase().includes(searchValue.toLowerCase()))
      .filter((i) => (showActiveFilter ? i.active === activeFilter : true))
      .filter((i) => (usersFilter.length > 0 ? usersFilter.includes(i.user_type?.user_type || '') : true));
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.header}>
            <div className={classes.headerTitle}>
              <Avatar className={classes.counter} variant="rounded">
                {users.length}
              </Avatar>
              <Typography className={classes.title} variant="h6" id="sortableTableTitle" component="div">
                {pluralise('User', users.length)}
              </Typography>
            </div>
            {/* <Typography className={classes.title} variant="h5" component="h2">{users.length} {pluralise('User', users.length)}</Typography> */}
            <div className={classes.searchContainer}>
              {userTypes.length > 0 && (
                <ToggleButtonGroup size="medium" value={usersFilter} color="primary" onChange={(_: unknown, values: string[]) => setUsersFilter(values)}>
                  {userTypes.map((i) => (
                    <ToggleButton
                      className={classes.toggleButton}
                      key={i.user_type}
                      value={i.user_type}
                      disabled={users.filter((u) => u.user_type?.user_type === i.user_type).length === 0}>
                      {`${users.filter((u) => u.user_type?.user_type === i.user_type).length} ${i.description}`}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              )}
              {showActiveFilter && (
                <ToggleButtonGroup className={classes.activeFilter} size="medium" value={[activeFilter]} color="primary" onChange={() => setActiveFilter(!activeFilter)}>
                  <ToggleButton data-qa="active-filter-button" className={classes.toggleButton} key="active" value={true}>
                    Active
                  </ToggleButton>

                  <ToggleButton data-qa="inactive-filter-button" className={classes.toggleButton} key="inactive" value={false}>
                    Inactive
                  </ToggleButton>
                </ToggleButtonGroup>
              )}

              <Paper variant="outlined" className={classes.search}>
                <>
                  <InputBase
                    className={classes.searchInput}
                    placeholder="Search"
                    value={searchValue}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value || '')}
                    startAdornment={<SearchIcon className={classes.searchButton} />}
                    endAdornment={
                      searchValue ? (
                        <Tooltip title="Clear" arrow>
                          <InputAdornment position="end" className={classes.searchButton}>
                            <IconButton size="small" onClick={() => setSearchValue('')}>
                              <ClearSearchIcon />
                            </IconButton>
                          </InputAdornment>
                        </Tooltip>
                      ) : (
                        <div className={classes.searchButton}></div>
                      )
                    }
                    inputProps={{ 'aria-label': 'search list' }}
                  />
                </>
              </Paper>
            </div>
          </div>
          <Typography mb={2} variant="body2">
            {note}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <>
          <GridActionCard gridSize={gridSize} fill onClick={handleCreate} />
          {filterUsers().map((user) => (
            <GridCard
              fill
              gridSize={gridSize}
              key={user.id}
              title={user.full_name}
              subTitle={user.user_type?.description}
              description={user.email}
              handleEdit={() => handleEdit(user.id)}
              handleView={() => handleView(user.id)}
              handleReset={handleReset ? () => handleReset(user.id) : undefined}
            />
          ))}
        </>
      </Grid>
    </>
  );
};

export default withStyles(styles)(UsersList);
