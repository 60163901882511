import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import Dashboard from '../../../../../layouts/Dashboard';
import ViewOrganisationUser from '../../../../../components/Global/OrganisationUsers/View';

import { selectOrganisationUserInitQuery, AreaUser } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  area_id: string;
  area_user_id: string;
}

const ViewOrganisationUserAdmin = ({ classes, history }: Props): React.ReactElement => {
  const { area_id, area_user_id } = useParams<UrlParams>();

  const [user, setUser] = useState<AreaUser>({
    full_name: '',
    area: {
      name: '',
    },
  });

  const { data: organisationUserInitQuery } = useQuery(selectOrganisationUserInitQuery(area_user_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && organisationUserInitQuery) {
      setUser(organisationUserInitQuery.users_organisation_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [organisationUserInitQuery]);

  const handleEdit = () => {
    history.push(`/trust/departments/${area_id}/users/${area_user_id}/edit`);
  };

  return (
    <Dashboard
      breadcrumbs={[
        { label: 'Departments', link: `/trust/departments` },
        { label: user.area.name, link: `/trust/departments/${area_id}` },
        { label: 'Users', link: `/trust/departments/${area_id}/users` },
        { label: user ? user.full_name : '' },
      ]}>
      <ViewOrganisationUser organisation_user_id={area_user_id} handleEdit={handleEdit} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ViewOrganisationUserAdmin));
