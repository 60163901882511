import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import Dashboard from '../../../../layouts/Dashboard';
import ViewOrganisationUser from '../../../../components/Global/OrganisationUsers/View';

import { selectOrganisationUserInitQuery, OrganisationUser } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  organisation_user_id: string;
}

const ViewOrganisationUserOrg = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_user_id } = useParams<UrlParams>();

  const [user, setUser] = useState<OrganisationUser>({
    full_name: '',
  });

  const { data: organisationUserInitQuery } = useQuery(selectOrganisationUserInitQuery(organisation_user_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && organisationUserInitQuery) {
      setUser(organisationUserInitQuery.users_organisation_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [organisationUserInitQuery]);

  const handleEdit = () => {
    history.push(`/trust/users/${organisation_user_id}/edit`);
  };

  return (
    <Dashboard breadcrumbs={[{ label: 'Users', link: `/trust/users` }, { label: user ? user.full_name : '' }]}>
      <ViewOrganisationUser organisation_user_id={organisation_user_id} handleEdit={handleEdit} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ViewOrganisationUserOrg));
