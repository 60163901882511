import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';

import ReadOnlyBlock from '../../../../../../components/ReadOnlyBlock';
import { Area } from '../..';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  area: Area;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const StepReview = ({ classes, area, setStep }: Props): React.ReactElement => {
  return (
    <>
      <ReadOnlyBlock
        items={[
          { label: 'Department name', value: area.name },
          { label: 'Phone', value: area.phone || 'No phone number' },
        ]}
        handleEdit={() => setStep(0)}
      />
    </>
  );
};

export default withStyles(styles)(StepReview);
