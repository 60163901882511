import React, { useState, useReducer } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { UnknownObject } from '../../../../react-app-env';

import {
} from '@mui/material';

import CustomBackdrop from '../../../../components/CustomBackdrop';
import { Area, Emergency, categoryReducer } from '../Common';
import Steps from '../Common/components/Steps';

import { createCategory } from './mutations';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
  organisation_id?: string,
  areas: Area[],
  emergencies: Emergency[],
  handleFinish: (id: string) => void,
}
const CreateArea = ({ classes, history, organisation_id, areas, emergencies, handleFinish }: Props): React.ReactElement => {
  
  const [category, dispatchCategory] = useReducer(categoryReducer, {
    name: '',
    description: '',
    areas: [],
    emergencies: [],
  });

  const [saving, setSaving] = useState<boolean>(false);

  const handleSave = async () => {
    setSaving(true);

    const item: UnknownObject = {
      ...category,
    };

    delete item.areas;
    delete item.emergencies;

    const insertVariables = {
      object: {
        ...item,
        organisation_id,
        area_assignments: {
          data: category.areas.map((i: Area) => ({
            organisation_id,
            area_id: i.id,
          })),
        },
        emergency_assignments: {
          data: category.emergencies.map((i: Emergency) => ({
            organisation_id,
            emergency_id: i.id,
          })),
        },
      },
    };
    const category_id: string | undefined = await createCategory(insertVariables);
    if (category_id) {
      setSaving(false);
      handleFinish(category_id);
    }
  };

  return (
    <>
      <Steps
        completeLabel="Create"
        category={category}
        areas={areas}
        emergencies={emergencies}
        dispatch={dispatchCategory}
        handleSave={handleSave} />
      {saving && <CustomBackdrop label="Creating Category" />}
    </>
  );
};

export default withRouter(withStyles(styles)(CreateArea));
