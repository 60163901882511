import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import Dashboard from '../../../../../layouts/Dashboard';
import ListOrganisationUsers from '../../../../../components/Global/OrganisationUsers/List';

import { selectAreaUsersInitQuery, Area } from './queries';
import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  area_id: string;
}

const ListAreaUsersAdmin = ({ classes, history }: Props): React.ReactElement => {
  const { area_id } = useParams<UrlParams>();

  const [area, setArea] = useState<Area>({
    name: '',
    users: [],
  });

  const { data: organisationUserInitQuery } = useQuery(selectAreaUsersInitQuery(area_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && organisationUserInitQuery) {
      setArea(organisationUserInitQuery.areas_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [organisationUserInitQuery]);

  const handleCreate = () => {
    history.push(`/trust/departments/${area_id}/users/create`);
  };

  const handleView = (id: string) => {
    history.push(`/trust/departments/${area_id}/users/${id}`);
  };

  const handleEdit = (id: string) => {
    history.push(`/trust/departments/${area_id}/users/${id}/edit`);
  };

  return (
    <Dashboard breadcrumbs={[{ label: 'Departments', link: `/trust/departments` }, { label: area.name, link: `/trust/departments/${area_id}` }, { label: 'Users' }]}>
      <ListOrganisationUsers items={area.users} handleCreate={handleCreate} handleView={handleView} handleEdit={handleEdit} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ListAreaUsersAdmin));
