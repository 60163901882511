
import { query, types } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _emergencyInitQuery = () => ({
  categories: [{
    id: types.string,
    name: types.string,
  }],
  emergency_roles: [{
    id: types.string,
    name: types.string,
    order_index: types.number,
  }],
});

type X = Unpacked<typeof _emergencyInitQuery>;
export type Category = X['categories'][0];
export type EmergencyRole = X['emergency_roles'][0];

export const emergencyInitQuery = () => gql`${query(_emergencyInitQuery())}`;
