import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import Dashboard from '../../../../../layouts/Dashboard';
import ViewEmergencyRole from '../../../../../components/Global/EmergencyRoles/View';

import { selectEmergencyRoleInitQuery, Emergency } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  organisation_id: string;
  emergency_role_id: string;
}

const ViewEmergencyRoleAdmin = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id, emergency_role_id } = useParams<UrlParams>();

  const [emergencyRole, setEmergency] = useState<Emergency>({
    name: '',
    organisation: {
      name: '',
    },
  });

  const { data: emergencyRoleInitData } = useQuery(selectEmergencyRoleInitQuery(emergency_role_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && emergencyRoleInitData) {
      setEmergency(emergencyRoleInitData.emergencies_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [emergencyRoleInitData]);

  const handleEdit = () => {
    history.push(`/admin/trusts/${organisation_id}/emergency_roles/${emergency_role_id}/edit`);
  };

  return (
    <Dashboard
      breadcrumbs={[
        { label: 'Trusts', link: '/admin/trusts' },
        { label: emergencyRole.organisation.name, link: `/admin/trusts/${organisation_id}` },
        { label: 'Emergency roles', link: `/admin/trusts/${organisation_id}/emergency_roles` },
        { label: emergencyRole.name },
      ]}>
      <ViewEmergencyRole emergency_role_id={emergency_role_id} handleEdit={handleEdit} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ViewEmergencyRoleAdmin));
