import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import Dashboard from '../../../../layouts/Dashboard';

import ListAreas from '../../../../components/Global/Areas/List';
import { AreaItem } from '../../../../components/Global/Areas/Common/';

import { areasInitQuery } from './queries';

import styles from './styles';
import { updateArea } from './mutations';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}
const ListAreasOrg = ({ classes, history }: Props): React.ReactElement => {
  const [areas, setAreas] = useState<AreaItem[]>([]);

  const { data: areasInitData, refetch } = useQuery(areasInitQuery(), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && areasInitData) {
      setAreas(areasInitData.areas);
    }
    return () => {
      mounted = false;
    };
  }, [areasInitData]);

  const handleCreate = () => {
    history.push(`/trust/departments/create`);
  };

  const handleView = (id: string) => {
    history.push(`/trust/departments/${id}`);
  };

  const handleEdit = (id: string, view: string) => {
    history.push(`/trust/${view}/${id}/edit`);
  };

  const handleActiveStatusChange = async (id: string, isActive: boolean) => {
    const updateVariables = {
      pk_columns: {
        id: id,
      },
      set: {
        active: isActive,
      },
    };
    await updateArea(updateVariables);

    await refetch();
  };

  return (
    <Dashboard breadcrumbs={[{ label: 'Departments' }]}>
      <ListAreas items={areas} handleCreate={handleCreate} handleView={handleView} handleEdit={handleEdit} handleActiveStatusChange={handleActiveStatusChange} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ListAreasOrg));
