import React, { useCallback, useReducer, useState } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { Auth } from 'aws-amplify';
import { useLocation, withRouter, RouteComponentProps } from 'react-router-dom';
import { ClassNameMap } from '@mui/styles/withStyles';

import { UnknownObject } from '../../../react-app-env';

import {
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';

import { CenteredLayout } from '../../../layouts';


import Logo from '../../../components/Logos/Logo';
import CustomButtonContainer from '../../../components/CustomButtonContainer';

import { passwordResetReducer, PasswordResetReducerAction, validation, validationErrors, ValidationErrors } from './Common';


import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
}

const ResetPassword = ({ classes, history }: Props): React.ReactElement => {
  const query = new URLSearchParams(useLocation().search);
  const queryEmail = atob(query.get('e') || '');

  const [passwordReset, dispatch] = useReducer(passwordResetReducer, {
    email: queryEmail,
    password: '',
    passwordConfirm: '',
    verificationCode: '',
  });

  const [errors, setErrors] = useState<ValidationErrors>({
    email: false,
    validEmail: false,
    password: false,
    passwordConfirm: false,
    passwordMatch: false,
    verificationCode: false,
    verificationCodeLength: false,
    reset: false,
  });

  const [isReset, setIsReset] = useState(false);
  const [reseting, setReseting] = useState(false);

  const validate = useCallback(() => {
    const errorItems: UnknownObject = {}
    errorItems.email = validation.email(passwordReset.email);
    errorItems.password = validation.password(passwordReset.password, passwordReset.passwordConfirm);
    errorItems.passwordConfirm = validation.passwordConfirm(passwordReset.passwordConfirm, passwordReset.password);
    errorItems.verificationCode = validation.verificationCode(passwordReset.verificationCode);
    setErrors({...errors, ...errorItems});
    return (Object.keys(errorItems) as Array<keyof typeof validationErrors>).some((i) => errorItems[i]);
  }, [passwordReset, errors]);

  const handleSubmit = () => {
    if (!validate()) {
      handleContinue();
    }
  };

  const handleContinue = async () => {
    setReseting(true);
    try {
      await Auth.forgotPasswordSubmit(passwordReset.email, passwordReset.verificationCode, passwordReset.password);
      setIsReset(true);
      setTimeout(() => {
        history.push('/sign-in');
      }, 2500);
    } catch (err: any) {
      setReseting(false);
      validation.reset(err.message)
    }
  };

  const getContent = () => (
    <>
      <div className={classes.form}>
        <TextField
          className={classes.textField}
          data-qa="email-textField"
          required
          label="Email address"
          type="email"
          error={errors.email as boolean}
          helperText={errors.email}
          value={passwordReset.email}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: PasswordResetReducerAction.EMAIL, value: event.target.value })}
        />
        <TextField
          className={classes.textField}
          data-qa="verification-code-textField"
          required
          label="Verification code"
          type="number"
          error={errors.verificationCode as boolean}
          helperText={errors.verificationCode}
          value={passwordReset.verificationCode}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: PasswordResetReducerAction.VERIFICATION_CODE, value: event.target.value })}
        />
        <TextField
          className={classes.textField}
          data-qa="new-password-textField"
          required
          label="New password"
          type="password"
          error={errors.password as boolean}
          helperText={errors.password}
          value={passwordReset.password}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: PasswordResetReducerAction.PASSWORD, value: event.target.value })}
        />
        <TextField
          className={classes.textField}
          data-qa="confirm-password-textField"
          required
          label="Confirm new password"
          type="password"
          error={errors.passwordConfirm as boolean}
          helperText={errors.passwordConfirm}
          value={passwordReset.passwordConfirm}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: PasswordResetReducerAction.PASSWORD_CONFIRM, value: event.target.value })}
        />
      </div>
      {isReset && (
        <Typography align="center">Password has been reset. Please login again</Typography>
      )}
      <CustomButtonContainer center>
        {!reseting && <Button data-qa="reset-password-button" variant="contained" color="primary" onClick={handleSubmit}>RESET PASSWORD</Button>}
        {reseting && <CircularProgress />}
      </CustomButtonContainer>
    </>
  );

  return (
    <CenteredLayout>
      <div className={classes.root}>
        <div className={classes.logo}>
          <Logo />
        </div>

        <div className={classes.info}>
          <Typography align="center" variant="caption" component="div">Please check your email for an AccentPOS ID verification code</Typography>
          <Typography className={classes.passwordDetail} align="center" variant="caption" component="div">Passwords must be at least 8 characters long and contain at least 1 lower case letter, 1 upper case letter, and a number</Typography>
        </div>

        <div className={classes.content}>
          {getContent()}
        </div>
      </div>
    </CenteredLayout>
  );
};

export default withRouter(withStyles(styles)(ResetPassword));
