import { query, params, types } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _sectionsInitQuery = () => ({
  area_sections: params({ order_by: { name: 'asc' } }, [
    {
      id: types.string,
      name: types.string,
      phone: types.string,
      stations_aggregate: params(
        { where: { base_station: { _eq: false } } },
        {
          aggregate: {
            count: types.number,
          },
        }
      ),
      active: types.boolean,
    },
  ]),
});

type X = Unpacked<typeof _sectionsInitQuery>;
export type Section = X['area_sections'][0];

export const sectionsInitQuery = () =>
  gql`
    ${query(_sectionsInitQuery())}
  `;
