import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import Dashboard from '../../../../layouts/Dashboard';
import ViewCategory from '../../../../components/Global/Categories/View';

import { selectSectionInitQuery, Category } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  category_id: string;
}

const ViewSectionOrg = ({ classes, history }: Props): React.ReactElement => {
  const { category_id } = useParams<UrlParams>();

  const [category, setCategory] = useState<Category>({
    name: '',
  });

  const { data: categoryInitData } = useQuery(selectSectionInitQuery(category_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && categoryInitData) {
      setCategory(categoryInitData.categories_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [categoryInitData]);

  const handleEdit = () => {
    history.push(`/trust/grouping/${category_id}/edit`);
  };

  return (
    <Dashboard breadcrumbs={[{ label: 'Grouping', link: `/trust/grouping` }, { label: category.name }]}>
      <ViewCategory category_id={category_id} handleEdit={handleEdit} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ViewSectionOrg));
