import { query, params, types, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../react-app-env';

const _areasInitQuery = (id: string) => ({
  organisations_by_pk: params(
    {
      id: rawString(id),
    },
    {
      name: types.string,
      areas: params({ order_by: { name: 'asc' } }, [
        {
          id: types.string,
          name: types.string,
          phone: types.string,
          department: types.string,
          category_assignments: params(
            {
              where: { category: { active: { _eq: true } } },
            },
            [
              {
                id: types.string,
                category: {
                  id: types.string,
                  name: types.string,
                  description: types.string,
                },
              },
            ]
          ),
          sections: params(
            {
              where: {
                _and: [{ stations: { base_station: { _eq: false } } }, { active: { _eq: true } }],
              },
            },
            [
              {
                id: types.string,
                name: types.string,
                phone: types.string,
              },
            ]
          ),
          users_aggregate: {
            aggregate: {
              count: types.number,
            },
          },
          active: types.boolean,
        },
      ]),
    }
  ),
});

type X = Unpacked<typeof _areasInitQuery>;
export type Organisation = X['organisations_by_pk'];
export type Area = Organisation['areas'][0];

export const areasInitQuery = (id: string) =>
  gql`
    ${query(_areasInitQuery(id))}
  `;
