import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { booleanToYesNo, stringFromArray } from '../../../../../../utils/stringUtils';

import {
} from '@mui/material';

import ReadOnlyBlock from '../../../../../../components/ReadOnlyBlock';
import { OrganisationUser, OrganisationUserType } from '../..';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  user: OrganisationUser,
  userTypes: OrganisationUserType[],
  setStep: React.Dispatch<React.SetStateAction<number>>,
}

const StepReview = ({ classes, user, userTypes, setStep }: Props): React.ReactElement => {
  return (
    <>
      <ReadOnlyBlock
        items={[
          { label: 'Name', value: stringFromArray([user.first_name, user.last_name]) },
          { label: 'Email', value: user.email },
          { label: 'Active', value: booleanToYesNo(user.active) },
        ]}
        handleEdit={() => setStep(0)} />
    </>
  );
};

export default withStyles(styles)(StepReview);
