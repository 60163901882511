import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../react-app-env';

const _createOrganisationUserInitQuery = (id: string) => ({
  organisations_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
    }
  ),
});

type X = Unpacked<typeof _createOrganisationUserInitQuery>;
export type Organisation = X['organisations_by_pk'];

export const createOrganisationUserInitQuery = (id: string) =>
  gql`
    ${query(_createOrganisationUserInitQuery(id))}
  `;
