import { createStyles, Theme } from '@mui/material';

const styles = (theme: Theme) => createStyles({
  root: {},
  header: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 60,
    marginBottom: theme.spacing(1),
  },
  caption: {
    marginLeft: theme.spacing(2),
  },
  chips: {
  },
  chip: {
    marginLeft: theme.spacing(1),
  },
});

export default styles;
