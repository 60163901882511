/**
 * Ensures that an email address is valid.
 *
 * @param emailAddress The email to be validated
 */
export function validateEmailAddress(emailAddress: string): boolean {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !emailAddress || re.test(emailAddress.toLowerCase());
}

/**
 * Ensures that the phone number is valid.
 *
 * @param phoneNumber The phone number to be validated
 */
export function validatePhoneNumber(phoneNumber: string): boolean {
  const re = /^[+][0-9]{3}[0-9]{3}[0-9]{4,6}$/;

  return !phoneNumber || re.test(phoneNumber);
}

/**
 * Checks to see if a password is secure enough.
 *
 * @param {string} password  The password the be validated
 *
 * @returns {Boolean}
 */
export function validatePassword(password: string): boolean {
  const re = /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})/;
  return re.test(password);
}
