import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/lab';

import { FormControl, FormHelperText, TextField } from '@mui/material';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  label: string;
  date?: Date | null;
  error?: boolean;
  helperText?: string;
  fullWidth?: boolean;
  handleDateChange: (newDate: Date | null | undefined) => void;
  onValidate?: [fieldName: string, validate: (field: string, value: Date) => void];
  onClose?: () => void;
}

const CustomDatePicker = ({ classes, label, date, error, helperText, fullWidth, handleDateChange, onValidate, onClose }: Props): React.ReactElement => {
  return (
    <FormControl component="fieldset" sx={fullWidth ? { width: '100%' } : undefined} error={error}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label={label}
          value={date}
          inputFormat="dd/MM/yyyy"
          onChange={(newValue) => {
            handleDateChange(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
          onClose={onClose}
        />
      </LocalizationProvider>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default withStyles(styles)(CustomDatePicker);
