import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import styles from './styles';
import { useQuery } from '@apollo/client';
import { GetQuestionnairesResponse, getQuestionnaires } from './queries';
import CustomSortableTable, { SortableTableAction, SortableTableHeader, SortableTableRow } from '../../../../components/CustomSortableTable';
import { AddCircle as AddCircleIcon } from '@mui/icons-material';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  organisationId: string;
  handleCreate: () => void;
  handleEdit: (id: string) => void;
}

const ListEraCareQuestionnaireComponent = ({ organisationId, handleCreate, handleEdit, classes, history }: Props): React.ReactElement => {
  const { data: questionnairesResponse, loading: questionnairesLoading } = useQuery<GetQuestionnairesResponse>(getQuestionnaires, {
    variables: { organisation_id: organisationId },
    fetchPolicy: 'no-cache',
  });

  const headers: SortableTableHeader[] = [{ key: 'name', label: 'Name' }];

  const rows: SortableTableRow[] =
    questionnairesResponse?.questionnaires.map((q) => ({
      key: q.id!,
      actions: [
        {
          label: 'Edit',
          onClick: () => handleEdit(q.id!),
        },
      ],
      columns: [
        {
          key: 'name',
          label: q.name,
        },
      ],
    })) ?? [];

  const actions: SortableTableAction[] = questionnairesResponse?.questionnaires.length
    ? []
    : [
        {
          key: 'create',
          label: 'Create',
          icon: <AddCircleIcon />,
          onClick: () => handleCreate(),
        },
      ];

  return (
    <CustomSortableTable
      title="Questionnaires"
      orderdBy="name"
      ordered="asc"
      actions={actions}
      headers={headers}
      counter={rows.length}
      rows={rows}
      loading={questionnairesLoading}
    />
  );
};

export default withRouter(withStyles(styles)(ListEraCareQuestionnaireComponent));
