import { query, params, types, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../react-app-env';

const _categoriesInitQuery = (id: string) => ({
  organisations_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
      categories: [
        {
          id: types.string,
          name: types.string,
          description: types.string,
          emergency_assignments: params(
            {
              where: { emergency: { active: { _eq: true } } },
            },
            [
              {
                id: types.string,
                emergency: {
                  id: types.string,
                  name: types.string,
                  description: types.string,
                },
              },
            ]
          ),
          area_assignments: params(
            {
              where: { area: { active: { _eq: true } } },
            },
            [
              {
                id: types.string,
                area: {
                  id: types.string,
                  name: types.string,
                  phone: types.string,
                  department: types.string,
                },
              },
            ]
          ),
          active: types.boolean,
        },
      ],
    }
  ),
});

type X = Unpacked<typeof _categoriesInitQuery>;
export type Organisation = X['organisations_by_pk'];

export const categoriesInitQuery = (id: string) =>
  gql`
    ${query(_categoriesInitQuery(id))}
  `;
