
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _editEmergencyInitQuery = (id: string) => ({
  emergencies_by_pk: params(
    {
      id: rawString(id),
    },
    {
      name: types.string,
      description: types.string,
      category_assignments: [{
        id: types.string,
        category: {
          id: types.string,
          name: types.string,
        },
      }],
      role_assignments: [{
        id: types.string,
        order_index: types.number,
        role: {
          id: types.string,
          name: types.string,
        },
      }],
      organisation: {
        categories: [{
          id: types.string,
          name: types.string,
        }],
        emergency_roles: [{
          id: types.string,
          name: types.string,
          order_index: types.number,
        }],
      },
    },
  )
});

type X = Unpacked<typeof _editEmergencyInitQuery>;
export type Organisation = X['emergencies_by_pk']['organisation'];

export const editEmergencyInitQuery = (id: string) => gql`${query(_editEmergencyInitQuery(id))}`;
