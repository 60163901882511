import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Dashboard from '../../../../../layouts/Dashboard';
import CreateSection from '../../../../../components/Global/Areas/Sections/Create';

import { sectionIitQuery, Area } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  area_id: string;
}

const CreateSectionOrg = ({ classes, history }: Props): React.ReactElement => {
  const { area_id } = useParams<UrlParams>();

  const [area, setArea] = useState<Area>({
    name: '',
  });

  const { data: sectionInitData } = useQuery(sectionIitQuery(area_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && sectionInitData) {
      setArea(sectionInitData.areas_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [sectionInitData]);

  const handleFinish = (id: string) => {
    history.push(`/trust/departments/${area_id}/wards/${id}`);
  };

  return (
    <>
      <Dashboard
        breadcrumbs={[
          { label: 'Departments', link: `/trust/departments` },
          { label: area.name, link: `/trust/departments/${area_id}` },
          { label: 'Wards', link: `/trust/departments/${area_id}/wards` },
          { label: 'Create' },
        ]}>
        <CreateSection area_id={area_id} handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(withStyles(styles)(CreateSectionOrg));
