import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import Dashboard from '../../../../layouts/Dashboard';
import ListSections from '../../../../components/Global/Categories/List';

import { categoriesInitQuery, Category } from './queries';

import styles from './styles';
import { updateCategory } from './mutations';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

const ListSectionsOrg = ({ classes, history }: Props): React.ReactElement => {
  const [categories, setCategories] = useState<Category[]>([]);

  const { data: categoriesInitData, refetch } = useQuery(categoriesInitQuery(), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && categoriesInitData) {
      setCategories(categoriesInitData.categories);
    }
    return () => {
      mounted = false;
    };
  }, [categoriesInitData]);

  const handleCreate = () => {
    history.push(`/trust/grouping/create`);
  };

  const handleEdit = (id: string, view: string) => {
    history.push(`/trust/${view}/${id}/edit`);
  };

  const handleActiveStatusChange = async (id: string, isActive: boolean) => {
    const updateVariables = {
      pk_columns: {
        id: id,
      },
      set: {
        active: isActive,
      },
    };
    await updateCategory(updateVariables);

    await refetch();
  };

  return (
    <Dashboard breadcrumbs={[{ label: 'Grouping' }]}>
      <ListSections items={categories} handleCreate={handleCreate} handleEdit={handleEdit} handleActiveStatusChange={handleActiveStatusChange} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ListSectionsOrg));
