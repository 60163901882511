import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { Grid } from '@mui/material';

import ReadOnlyBlock from '../../../ReadOnlyBlock';
import { selectEmergencyInitQuery, Emergency } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  emergency_id: string;
  handleEdit: () => void;
}

const ViewEmergency = ({ classes, history, emergency_id, handleEdit }: Props): React.ReactElement => {
  const [emergency, setEmergency] = useState<Emergency>({
    id: '',
    name: '',
    description: '',
    category_assignments: [],
  });

  const { data: emergencyInitData } = useQuery(selectEmergencyInitQuery(emergency_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && emergencyInitData) {
      setEmergency(emergencyInitData.emergencies_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [emergencyInitData]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <>
          <ReadOnlyBlock
            title={emergency.name}
            gridSize={3}
            items={[
              { label: 'Description', value: emergency.description },
              { label: 'Grouping assignments', list: emergency.category_assignments.map((i) => i.category.name) },
            ]}
            handleEdit={handleEdit}
          />
        </>
      </Grid>
    </Grid>
  );
};

export default withRouter(withStyles(styles)(ViewEmergency));
