import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { Grid } from '@mui/material';

import Dashboard from '../../../../layouts/Dashboard';
import { GridCard, GridActionCard } from '../../../../components/Grid';

import { organisationsInitQuery, Organisation } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

const ListOrganisations = ({ classes, history }: Props): React.ReactElement => {
  const [organisations, setOrganisations] = useState<Organisation[]>([]);

  const { data: organisationsInitData } = useQuery(organisationsInitQuery, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && organisationsInitData) {
      setOrganisations(organisationsInitData.organisations);
    }
    return () => {
      mounted = false;
    };
  }, [organisationsInitData]);

  const handleCreate = () => {
    history.push('/admin/trusts/create');
  };

  const handleEdit = (id: string) => {
    history.push(`/admin/trusts/${id}/edit`);
  };

  const handleView = (id: string) => {
    history.push(`/admin/trusts/${id}`);
  };

  return (
    <Dashboard title="Trusts">
      <Grid container spacing={4} alignItems="stretch">
        <GridActionCard onClick={handleCreate} fill />
        {organisations.map((organisation: Organisation) => (
          <GridCard fill key={organisation.id} title={organisation.name} handleEdit={() => handleEdit(organisation.id)} handleView={() => handleView(organisation.id)} />
        ))}
      </Grid>
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ListOrganisations));
