import { gql } from '@apollo/client';
import { QuestionnaireCategory } from '../../../../components/Global/EraCareQuestionnaire/Common';

export const getEraCareResponseQuery = gql`
  query ($era_care_event_id: uuid!) {
    era_care_responses(where: { era_care_event_id: { _eq: $era_care_event_id } }) {
      response
    }
  }
`;

export interface GetEraCareResponseQueryResponse {
  era_care_responses: {
    response: {
      categories: {
        name: string;
        questions: {
          name: string;
          answer: {
            name: string;
            score: number;
          };
        }[];
      }[];
    };
  }[];
}

export const getQuestionnaireQuery = gql`
  query ($organisation_id: uuid!) {
    questionnaires(where: { organisation_id: { _eq: $organisation_id } }) {
      name
      categories
    }
  }
`;

export interface GetQuestionnaireQueryResponse {
  questionnaires: { name: string; categories: QuestionnaireCategory[] }[];
}
