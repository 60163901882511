import { ApolloClient, createHttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { rawString } from 'typed-graphqlify';
import { UnknownObject } from '../react-app-env';

const { REACT_APP_API_URL: API_URL } = process.env;

const httpLink = createHttpLink({
  uri: API_URL,
});

const WS_API_URL = API_URL!.replace('https', 'wss').replace('http', 'ws');

const wsLink = new WebSocketLink({
  uri: WS_API_URL,
  options: {
    reconnect: true,
    connectionParams: {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    },
  }
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    }
  }
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  authLink.concat(httpLink),
);

const client = new ApolloClient({
  link: splitLink,
  
  cache: new InMemoryCache({
    addTypename: false,
  })
});

export default client;



export const getWhereKeyValue = (field: string, value: string): UnknownObject => {
  const nesting: string[] = field.split('.');
  if (nesting.length > 1) {
    return {
      [nesting[0]]: getWhereKeyValue(nesting.slice(1).join('.'), value)
    };
  }
  return {
    [field]: { _eq: rawString(value) },
  };
};
