import { Button, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';

export interface FilterOptions {
  areas: { id: string; name: string }[];
  sections: { id: string; name: string }[];
  emergencies: { name: string }[];
}

export interface Filters {
  areas: string[];
  sections: string[];
  emergencies: string[];
}

interface Props {
  filters: Filters;
  filterOptions: FilterOptions;
  setFilters: (value: React.SetStateAction<Filters>) => void;
  onClose: () => void;
}

const FiltersPane = ({ filterOptions, filters, setFilters, onClose }: Props) => {
  const handleAreasChange = (event: SelectChangeEvent<typeof filters.areas>) => {
    const {
      target: { value },
    } = event;

    setFilters((prev) => ({
      ...prev,
      areas: typeof value === 'string' ? value.split(',') : value,
    }));
  };

  const handleSectionsChange = (event: SelectChangeEvent<typeof filters.sections>) => {
    const {
      target: { value },
    } = event;

    setFilters((prev) => ({
      ...prev,
      sections: typeof value === 'string' ? value.split(',') : value,
    }));
  };

  const handleEmergenciesChange = (event: SelectChangeEvent<typeof filters.emergencies>) => {
    const {
      target: { value },
    } = event;

    setFilters((prev) => ({
      ...prev,
      emergencies: typeof value === 'string' ? value.split(',') : value,
    }));
  };

  return (
    <Grid p={2} container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h5">Filters</Typography>
      </Grid>
      {!!filterOptions.areas.length && (
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="multiple-areas-label">Departments</InputLabel>
            <Select id="multiple-areas" labelId="multiple-areas-label" multiple label="Departments" value={filters.areas} onChange={handleAreasChange} variant="outlined">
              {filterOptions.areas.map((area) => (
                <MenuItem key={area.id} value={area.id}>
                  {area.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      {!!filterOptions.sections.length && (
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="multiple-sections-label">Wards</InputLabel>
            <Select id="multiple-sections" labelId="multiple-sections-label" multiple label="Wards" value={filters.sections} onChange={handleSectionsChange} variant="outlined">
              {filterOptions.sections.map((section) => (
                <MenuItem key={section.id} value={section.id}>
                  {section.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      {!!filterOptions.emergencies.length && (
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="multiple-emergencies-label">Events</InputLabel>
            <Select
              id="multiple-emergencies"
              labelId="multiple-emergencies-label"
              multiple
              label="emergencies"
              value={filters.emergencies}
              onChange={handleEmergenciesChange}
              variant="outlined">
              {filterOptions.emergencies.map((emergency) => (
                <MenuItem key={emergency.name} value={emergency.name}>
                  {emergency.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12} textAlign={'end'}>
        <Button onClick={onClose} variant="outlined">
          Close
        </Button>
      </Grid>
    </Grid>
  );
};
export default FiltersPane;
