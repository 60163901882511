import React, { useState, useReducer } from 'react';
import { WithStyles, ClassNameMap } from '@mui/styles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import withStyles from '@mui/styles/withStyles';

import Dashboard from '../../../../layouts/Dashboard';

import CustomBackdrop from '../../../../components/CustomBackdrop';
import { adminUserReducer } from '../Common';
import Steps from '../Common/components/Steps';

import styles from './styles';
import { createAdminUser } from './mutations';
import { GetUsersCountRequest, GetUsersCountResponse, getUsersCountQuery } from './queries';
import { useLazyQuery } from '@apollo/client';
import CustomDialog from '../../../../components/CustomDialog';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

const CreateLabUser = ({ classes, history }: Props): React.ReactElement => {
  const [user, dispatchUser] = useReducer(adminUserReducer, {
    first_name: '',
    last_name: '',
    email: '',
  });

  const [saving, setSaving] = useState<boolean>(false);
  const [validationDialog, setValidationDialog] = useState<boolean>(false);

  const [getUsers] = useLazyQuery<GetUsersCountResponse, GetUsersCountRequest>(getUsersCountQuery, {
    onCompleted(usersResponse) {
      if (usersResponse) {
        const {
          users_admin_aggregate: {
            aggregate: { count: adminsCount },
          },
          users_organisation_aggregate: {
            aggregate: { count: orgAdminsCount },
          },
        } = usersResponse;

        if (adminsCount === 0 && orgAdminsCount === 0) {
          const createVariables = {
            object: user,
          };
          createAdminUser(createVariables).then((user_id: string | undefined) => {
            if (user_id) {
              history.push(`/admin/users/${user_id}`);
            }
          });
        } else {
          setValidationDialog(true);
        }
      }
    },
    fetchPolicy: 'no-cache',
  });

  const handleSave = () => {
    setSaving(true);
    getUsers({ variables: { email: user.email } });
    setSaving(false);
  };

  return (
    <>
      <Dashboard breadcrumbs={[{ label: 'Admin' }, { label: 'Users', link: '/admin/users' }, { label: 'Create' }]}>
        <Steps completeLabel="Create" user={user} dispatch={dispatchUser} handleSave={handleSave} />
      </Dashboard>
      {saving && <CustomBackdrop label="Creating User" />}
      <CustomDialog
        open={validationDialog}
        title="Unable to create a user"
        message="User with the same email already exists"
        handleClose={() => setValidationDialog(false)}
        actions={[
          {
            label: 'OK',
            onClick: () => setValidationDialog(false),
          },
        ]}
      />
    </>
  );
};

export default withRouter(withStyles(styles)(CreateLabUser));
