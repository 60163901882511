import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';
import Dashboard from '../../../../layouts/Dashboard';
import styles from './styles';
import { useQuery } from '@apollo/client';
import { GetQuestionnaireResponse, getQuestionnaire } from './queries';
import CustomBackdrop from '../../../../components/CustomBackdrop';
import EditEraCareQuestionnaireComponent from '../../../../components/Global/EraCareQuestionnaire/Edit';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  questionnaire_id: string;
}

const EditEraCareQuestionnaireAdmin = ({ classes, history }: Props): React.ReactElement => {
  const { questionnaire_id } = useParams<UrlParams>();

  const handleFinish = () => {
    history.push(`/trust/care-questionnaires`);
  };

  const { data: questionnaireResponse, loading: questionnaireLoading } = useQuery<GetQuestionnaireResponse>(getQuestionnaire, {
    variables: { id: questionnaire_id },
    fetchPolicy: 'no-cache',
  });

  return (
    <Dashboard
      breadcrumbs={[{ label: 'ERA Care Questionnaire', link: '/trust/care-questionnaires' }, { label: questionnaireResponse?.questionnaires_by_pk.name ?? '' }, { label: 'Edit' }]}>
      <EditEraCareQuestionnaireComponent questionnaireId={questionnaire_id} handleFinish={handleFinish} />
      {questionnaireLoading && <CustomBackdrop label="Loading" />}
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(EditEraCareQuestionnaireAdmin));
