import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { Grid } from '@mui/material';

import Dashboard from '../../../../layouts/Dashboard';
import ReadOnlyBlock from '../../../../components/ReadOnlyBlock';
import { selectOrganisationInitQuery, Organisation } from './queries';
import { GridCard } from '../../../../components/Grid';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  organisation_id: string;
}

const ViewOrganisation = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id } = useParams<UrlParams>();

  const [organisation, setOrganisation] = useState<Organisation>({
    id: '',
    name: '',
    postcode: '',
    users_aggregate: { aggregate: { count: 0 } },
    areas_aggregate: { aggregate: { count: 0 } },
    categories_aggregate: { aggregate: { count: 0 } },
    emergencies_aggregate: { aggregate: { count: 0 } },
    emergency_roles_aggregate: { aggregate: { count: 0 } },
    events_aggregate: { aggregate: { count: 0 } },
    missing_patients_info: { aggregate: { count: 0 } },
    questionnaires_aggregate: { aggregate: { count: 0 } },
  });

  const { data: organisationsInitData } = useQuery(selectOrganisationInitQuery(organisation_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && organisationsInitData) {
      const org = organisationsInitData.organisations_by_pk;
      setOrganisation(org);
    }
    return () => {
      mounted = false;
    };
  }, [organisationsInitData]);

  const handleEdit = () => {
    history.push(`/admin/trusts/${organisation_id}/edit`);
  };

  const handleCreate = (view: String) => {
    history.push(`/admin/trusts/${organisation_id}/${view}/create`);
  };

  const handleView = (view: String) => {
    history.push(`/admin/trusts/${organisation_id}/${view}`);
  };

  return (
    <Dashboard breadcrumbs={[{ label: 'Trusts', link: '/admin/trusts' }, { label: organisation.name }]}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <>
            <ReadOnlyBlock title={organisation.name} gridSize={12} items={[{ label: 'Postcode', value: organisation.postcode || 'No postcode found' }]} handleEdit={handleEdit} />
          </>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <GridCard
              fill
              gridSize={4}
              counter={organisation?.users_aggregate?.aggregate.count}
              key="Lead users"
              title="Lead users"
              subTitle="These users administer the system for their trust. They can set up departments, wards and bed spaces. They are able to manage the list of available emergencies and roles as well as see event history and metrics to run reports."
              editLabel="Create"
              handleEdit={() => handleCreate('users')}
              handleView={() => handleView('users')}
            />
            <GridCard
              fill
              gridSize={4}
              counter={organisation?.events_aggregate.aggregate.count}
              key="Reports"
              title="Reports"
              subTitle="This enables you to look at metrics and run reports."
              handleView={() => handleView('reports')}
            />
            <GridCard
              fill
              gridSize={4}
              key="EraCareQuestionnaires"
              title="ERA Care Questionnaire"
              subTitle="This is an add-on to ERA Systems and enables follow-up wellbeing checks."
              editLabel="Create"
              handleEdit={organisation?.questionnaires_aggregate.aggregate.count === 0 ? () => handleCreate('care-questionnaires') : undefined}
              handleView={() => handleView('care-questionnaires')}
            />
            <GridCard
              fill
              gridSize={4}
              counter={organisation?.areas_aggregate?.aggregate.count}
              key="Departments"
              title="Departments"
              subTitle="These include the departments where ERA Systems run within your trust.
              Within this you can set up users to specifically see their own ward."
              editLabel="Create"
              handleEdit={() => handleCreate('departments')}
              handleView={() => handleView('departments')}
            />
            <GridCard
              fill
              gridSize={4}
              counter={organisation?.emergency_roles_aggregate?.aggregate.count}
              key="EmergencyRoles"
              title="Emergency roles"
              subTitle="This is for creating particular roles that staff can assign themselves to in an emergency.
              You would create a role here first before then assigning it to a particular emergency in ‘Emergencies’."
              editLabel="Create"
              handleEdit={() => handleCreate('emergency_roles')}
              handleView={() => handleView('emergency_roles')}
            />
            <GridCard
              fill
              gridSize={4}
              counter={organisation?.emergencies_aggregate?.aggregate.count}
              key="Emergencies"
              title="Emergencies"
              editLabel="Create"
              subTitle="This is where you can create your specific emergency and then allocate what specific roles are needed within that emergency."
              handleEdit={() => handleCreate('emergencies')}
              handleView={() => handleView('emergencies')}
            />
            <GridCard
              fill
              gridSize={4}
              counter={organisation?.categories_aggregate?.aggregate.count}
              key="Grouping"
              title="Grouping"
              subTitle="Once you have created emergencies and have allocated roles, you can group them together for emergencies common to your area e.g. Maternity Emergencies."
              editLabel="Create"
              handleEdit={() => handleCreate('grouping')}
              handleView={() => handleView('grouping')}
            />
          </Grid>
        </Grid>
      </Grid>
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ViewOrganisation));
