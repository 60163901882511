import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 142,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: theme.palette.primary.light,
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.main,
      // '& > $icon svg': {
      //   color: `${theme.palette.background.default} !important`,
      //   fill: `${theme.palette.background.default} !important`,
      // },
      // '& > $label': {
      //   color: theme.palette.background.default,
      // },
    },
  },
  fill: {
    height: '100%',
  },
  small: {
    minHeight: 60,
    flexDirection: 'row',
    '& > $label': {
      marginTop: 0,
      marginLeft: theme.spacing(1),
    },
  },
  icon: {
    color: theme.palette.primary.main,
    width: theme.spacing(4),
    height: theme.spacing(4),
    // transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '& > svg': {
      width: 'inherit',
      height: 'inherit',
    }
  },
  label: {
    color: theme.palette.text.primary,
    // transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    marginTop: theme.spacing(2),
  },
});

export default styles;
