import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';

import ReadOnlyBlock from '../../../../../../components/ReadOnlyBlock';
import { Questionnaire } from '../..';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  questionnaire: Questionnaire;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const StepReview = ({ classes, questionnaire, setStep }: Props): React.ReactElement => {
  return (
    <>
      <ReadOnlyBlock items={[{ label: 'Questionnaire Name', value: questionnaire.name }]} handleEdit={() => setStep(0)} />
    </>
  );
};

export default withStyles(styles)(StepReview);
