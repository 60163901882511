import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderWidth: 1,
    borderStyle: 'dashed',
    borderSpacing: '10px 10px',
    borderColor: theme.palette.primary.main,
    // backgroundImage: `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg"><rect width="100%" height="100%" fill="none" rx="8" ry="8" stroke="%23E39190" stroke-width="1" stroke-dasharray="6, 14" stroke-dashoffset="10" stroke-linecap="square" /></svg>')`,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(4),
    '&:only-child': {
      marginBottom: 0,
    }
  },
  fill: {
    height: '100%',
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
    '& $content': {
      flexGrow: 0,
    },
  },
  content: {
    flexGrow: 1,
  },
  item: {
    marginBottom: theme.spacing(2),
  },
  label: {
    marginBottom: theme.spacing(1),
  },
  image: {
    height: theme.spacing(16),
  },
  icon: {
    width: theme.spacing(16),
    height: theme.spacing(16),
    filter: theme.palette.mode === 'dark' ? 'invert(1)' : '',
    opacity: theme.palette.mode === 'dark' ? 1 : 0.87,
  },
  list: {
    paddingLeft: theme.spacing(1),
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    justifySelf: 'flex-end',
    '& > button:first-of-type:not(:only-child)': {
      marginRight: theme.spacing(2),
    },
  },
});

export default styles;
