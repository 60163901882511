import React from 'react';
import { ClassNameMap } from '@mui/styles/withStyles';

import { WithStyles, withStyles } from '@mui/styles';

import CustomTransferList, { CustomTransferListItem } from '../../../../../CustomTransferList';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  items: CustomTransferListItem[],
  assigned: CustomTransferListItem[],
  handleAssignment: (left: CustomTransferListItem[], right: CustomTransferListItem[]) => void,
}

const StepRoles = ({ classes, items, assigned, handleAssignment }: Props): React.ReactElement => {
  return (
    <>
      <CustomTransferList
        labelLeft="Unassigned role"
        labelRight="Assigned role"
        allItems={items}
        currentItems={assigned}
        handleTransfer={handleAssignment} />
    </>
  );
};

export default withStyles(styles)(StepRoles);
