import { gql } from '@apollo/client';
import { Questionnaire } from '../Common';

export const getQuestionnaire = gql`
  query ($id: uuid!) {
    questionnaires_by_pk(id: $id) {
      id
      name
      categories
    }
  }
`;

export interface GetQuestionnaireResponse {
  questionnaires_by_pk: Questionnaire;
}
