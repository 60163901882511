import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import {} from '@mui/material';

import Dashboard from '../../../../layouts/Dashboard';

import CreateEmergencyRole from '../../../../components/Global/EmergencyRoles/Create';

import { emergencyRoleInitQuery, Emergency } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

const CreateEmergencyRoleOrg = ({ classes, history }: Props): React.ReactElement => {
  const [emergencies, setOrganisation] = useState<Emergency[]>([]);

  const { data: emergencyRoleInitData } = useQuery(emergencyRoleInitQuery(), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && emergencyRoleInitData) {
      setOrganisation(emergencyRoleInitData.emergencies);
    }
    return () => {
      mounted = false;
    };
  }, [emergencyRoleInitData]);

  const handleFinish = (id: string) => {
    history.push(`/trust/emergency_roles`);
  };

  return (
    <>
      <Dashboard breadcrumbs={[{ label: 'Emergency roles', link: `/trust/emergency_roles` }, { label: 'Create' }]}>
        <CreateEmergencyRole emergencies={emergencies} handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(withStyles(styles)(CreateEmergencyRoleOrg));
