import React, { useState, useEffect } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import {} from '@mui/material';

import Dashboard from '../../../../layouts/Dashboard';

import CreateEmergency from '../../../../components/Global/Emergencies/Create';

import { emergencyInitQuery, Category, EmergencyRole } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

const CreateEmergencyOrg = ({ classes, history }: Props): React.ReactElement => {
  const [items, setItems] = useState<{ categories: Category[]; emergency_roles: EmergencyRole[] }>({
    categories: [],
    emergency_roles: [],
  });

  const { data: emergencyInitData } = useQuery(emergencyInitQuery(), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && emergencyInitData) {
      setItems({
        categories: emergencyInitData.categories,
        emergency_roles: emergencyInitData.emergency_roles,
      });
    }
    return () => {
      mounted = false;
    };
  }, [emergencyInitData]);

  const handleFinish = (id: string) => {
    history.push(`/trust/emergencies`);
  };

  return (
    <>
      <Dashboard breadcrumbs={[{ label: 'Emergencies', link: `/trust/emergencies` }, { label: 'Create' }]}>
        <CreateEmergency categories={items.categories} emergencyRoles={items.emergency_roles} handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(withStyles(styles)(CreateEmergencyOrg));
