import React, { useReducer } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';
import Dashboard from '../../../../layouts/Dashboard';
import styles from './styles';
import { EraCareEventReducerAction, eraCareEventReducer } from '../Common';
import Steps from '../Common/components/Steps';
import CustomBackdrop from '../../../../components/CustomBackdrop';
import { useMutation, useQuery } from '@apollo/client';
import { GetEraCareEventQueryResponse, getEraCareEventQuery } from './queries';
import { updateEraCareEventMutation } from './mutations';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  era_care_event_id: string;
}

const EditEraCareEvents = ({ classes, history }: Props): React.ReactElement => {
  const { era_care_event_id } = useParams<UrlParams>();

  const [eraCareEvent, dispatchEraCareEvent] = useReducer(eraCareEventReducer, {
    created_at: '',
    patient_first_name: '',
    patient_last_name: '',
    patient_mobile_number: null,
    patient_email: null,
    is_linked_to_emergency: false,
    is_tracked_by_era: false,
    patient_gives_consent_to_contact: false,
  });

  const { loading: eraCareEventLoading } = useQuery<GetEraCareEventQueryResponse>(getEraCareEventQuery, {
    variables: { id: era_care_event_id },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      dispatchEraCareEvent({ type: EraCareEventReducerAction.INIT, value: data.era_care_events_by_pk });
    },
  });

  const handleFinish = () => {
    history.push('/department/care-events');
  };

  const [updateEraCareEvent, { loading: savingChanges }] = useMutation(updateEraCareEventMutation, { onCompleted: handleFinish });

  const handleSave = async () => {
    await updateEraCareEvent({
      variables: {
        id: era_care_event_id,
        set: {
          ...eraCareEvent,
        },
      },
    });
  };

  return (
    <Dashboard
      breadcrumbs={[
        { label: 'ERA Care Events', link: '/department/care-events' },
        { label: `${eraCareEvent.patient_first_name} ${eraCareEvent.patient_last_name}` },
        { label: 'Edit' },
      ]}>
      <Steps completeLabel="Save Changes" eraCareEvent={eraCareEvent} dispatch={dispatchEraCareEvent} handleSave={handleSave} />
      {(eraCareEventLoading || savingChanges) && <CustomBackdrop label="Loading" />}
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(EditEraCareEvents));
