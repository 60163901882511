import React, { useState, useEffect, useReducer } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import {
} from '@mui/material';

import CustomBackdrop from '../../../../../../components/CustomBackdrop';

import { stationReducer, StationReducerAction } from '../Common';
import Steps from '../Common/components/Steps';

import { editStationInitQuery } from './queries';
import { updateSection } from './mutations';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
  station_id: string,
  handleFinish: () => void,
}

const EditStation = ({ classes, history, station_id, handleFinish }: Props): React.ReactElement => {

  const [station, dispatchStation] = useReducer(stationReducer, {
    name: '',
    phone: '',
  });

  const [saving, setSaving] = useState<boolean>(false);

  const { data: editAreaInitData } = useQuery(editStationInitQuery(station_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editAreaInitData) {
      dispatchStation({ type: StationReducerAction.INIT, value: editAreaInitData.area_section_stations_by_pk });
    }
    return () => { mounted = false; };
  }, [editAreaInitData]);

  const handleSave = async () => {
    setSaving(true);

    const updateVariables = {
      pk_columns: {
        id: station_id,
      },
      set: station,
    };
    await updateSection(updateVariables);

    setSaving(false);
    handleFinish();
  };

  return (
    <>
      <Steps
        completeLabel="Save"
        station={station}
        dispatch={dispatchStation}
        handleSave={handleSave} />
      {saving && <CustomBackdrop label="Saving Changes" />}
    </>
  );
};

export default withRouter(withStyles(styles)(EditStation));
