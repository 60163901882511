import { query, params, types, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../../react-app-env';

const _stationsInitQuery = (id: string) => ({
  area_sections_by_pk: params(
    {
      id: rawString(id),
    },
    {
      name: types.string,
      area: {
        name: types.string,
      },
      stations: params(
        {
          where: { base_station: { _eq: false } },
          order_by: { name: 'asc' },
        },
        [
          {
            id: types.string,
            name: types.string,
            phone: types.string,
            active: types.boolean,
          },
        ]
      ),
    }
  ),
});

type X = Unpacked<typeof _stationsInitQuery>;
export type Section = X['area_sections_by_pk'];
export type Station = Section['stations'][0];

export const stationsInitQuery = (id: string) =>
  gql`
    ${query(_stationsInitQuery(id))}
  `;
