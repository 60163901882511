import { capitaliseFirstOnly } from '../../../../utils/stringUtils';
import { ValidationType } from '../../../../react-app-env';

export enum ReorderDirection {
  UP = 0,
  DOWN = 1,
}

export interface Emergency {
  name: string;
  description: string;
  categories: Category[];
  roles: EmergencyRole[];
}

export interface EmergencyItem {
  id: string;
  name: string;
  description: string;
  category_assignments: CategoryAssignment[];
  role_assignments: EmergencyRoleAssignment[];
  active: boolean;
  priority_index: number;
}

export interface Category {
  id: string;
  name: string;
}

export interface CategoryAssignment {
  id: string;
  category: {
    id: string;
    name: string;
    description: string;
  };
}

export interface EmergencyRole {
  id: string;
  name: string;
  order_index: number;
}

export interface EmergencyRoleAssignment {
  id: string;
  order_index: number;
  role: {
    id: string;
    name: string;
    description: string;
  };
}

export interface ValidationErrors {
  name: ValidationType;
}

export const validationErrors: ValidationErrors = {
  name: 'Please provide a name',
};

export interface Validation {
  name: (value: string) => ValidationType;
}

export const validation: Validation = {
  name: (value) => (!value ? validationErrors.name : false),
};

export enum EmergencyReducerAction {
  NAME,
  DESCRIPTION,
  UPDATE_CATEGORIES,
  UPDATE_ROLES,
  UPDATE_ROLES_ORDER,
  INIT,
}

export const emergencyReducer = (state: Emergency, action: { type: EmergencyReducerAction; value: any }): Emergency => {
  switch (action.type) {
    case EmergencyReducerAction.NAME:
      return { ...state, name: capitaliseFirstOnly(action.value) };
    case EmergencyReducerAction.DESCRIPTION:
      return { ...state, description: action.value };
    case EmergencyReducerAction.UPDATE_CATEGORIES:
      return { ...state, categories: action.value };
    case EmergencyReducerAction.UPDATE_ROLES:
      return { ...state, roles: action.value };
    case EmergencyReducerAction.UPDATE_ROLES_ORDER:
      let roles = action.value || [];
      roles = roles.map((i: EmergencyRole, order_index: number) => ({ ...i, order_index }));
      return { ...state, roles };
    case EmergencyReducerAction.INIT:
      const { name, description, category_assignments, role_assignments } = action.value;
      const obj = {
        name,
        description: description || '',
        categories: category_assignments.map((a: CategoryAssignment) => a.category as Category) as Category[],
        roles: role_assignments.map((a: EmergencyRoleAssignment) => ({ ...a.role, order_index: a.order_index } as EmergencyRole)) as EmergencyRole[],
      };
      return { ...(obj as Emergency) };
    default:
      throw new Error();
  }
};
